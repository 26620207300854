import React, { useState, useEffect, useRef } from 'react'
import './TabelaPermissaoUsuario.css'

export default function TabelaPermissaoUsuario(
    { bodyDataRows, headerTableTitles, bodyTableFields, bodyDataRowsChecked, registerForm }
) {
    const [dataRow, setDataRow] = useState([])
    const [headerTitles, setHeaderTitles] = useState(headerTableTitles)
    const [tableFields, setTableFields] = useState(bodyTableFields)
    const [dataRowChecked, setDataRowChecked] = useState([])
    const [checkedState, setCheckedState] = useState([]);
    let checkArray = []

    useEffect(() => {
        if (bodyDataRows.length > 0) {
            setDataRow(value => value = bodyDataRows)
            setDataRowChecked(value => value = bodyDataRowsChecked)      
                 
        }
    }, [bodyDataRows])

    useEffect( () => {
        if(bodyDataRowsChecked?.length > 0){
            initialArrayState() 
        }
    }, [bodyDataRowsChecked])


    //Array inicial contendo true ou false pre-definido para o checkbox
    const initialArrayState = () => {
        let arrayState = []
        for(const value of bodyDataRows){
            if(bodyDataRowsChecked.includes(value?.Codigo)){
                arrayState.push(true)
            } else {
                arrayState.push(false)
            }
        }
        setCheckedState(value => value = arrayState)
    }

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(value => value = updatedCheckedState);
    }

    function checkFields(dataRowChecked, val, key) {
        checkArray = []

        if (dataRowChecked?.includes(val?.Codigo)) {
            checkArray.push(
                <input
                    type="checkbox"
                    onClick={() => handleOnChange(key)}
                    checked={checkedState[key]}
                    name={val.Codigo}
                    id={`Checkbox${val.Codigo}`}
                    key={`Check${val.Codigo}`}
                    ref={registerForm}
                />
            )
        } else {
            checkArray.push(
                <input
                    type="checkbox"
                    onClick={() => handleOnChange(key)}
                    checked={checkedState[key]}
                    name={val.Codigo}
                    id={`Checkbox${val.Codigo}`}
                    key={`Check${val.Codigo}`}
                    ref={registerForm}
                />
            )
        }

        return checkArray.map((item) => {
            return item
        })
    }

    return (
        <div className='TabelaGeralContainerPermissaoUsuario'>
            <table>
                <thead>
                    <tr>
                        {headerTitles?.map(item => (
                            <th>{item}</th>
                        )
                        )}
                    </tr>
                </thead>
            </table>
            <div id='linhaTabelaPermissaoUsuario'>
                {dataRow?.map((val, key) => {
                    return (
                        <div id='itemTabelaPermissaoUsuario'>
                            {
                                checkFields(dataRowChecked, val, key)
                            }
                            <div key={key} value={val.Curso} id="TabelaGeralDashRow" >
                                {tableFields?.map(item => (
                                    isNaN(val[item]) === false ?
                                        <div key={val.Curso} ref={registerForm} >{val[item]}</div>
                                        : <div key={val.Curso} ref={registerForm} >{val[item].toUpperCase()}</div>
                                )
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}