import './Extras.css'

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PostCalcularAuxiliares from '../../../services/PostCalcularAuxiliares'

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import { Backdrop, CircularProgress } from '@mui/material'
import Modal from 'react-modal'

import Close from '@material-ui/icons/Close'
import Iso from '@mui/icons-material/Iso';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import ButtonConfirm from '../../../components/ButtonConfirm/ButtonConfirm'

export default function ExtrasAdmin() {

    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [open, setOpen] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [valorAuxiliar, setValorAuxiliar] = useState('')

    const dados = [
        { aux: "auxiliar_01" },
        { aux: "auxiliar_02" },
        { aux: "auxiliar_03" },
        { aux: "auxiliar_04" },
        { aux: "auxiliar_05" },
        { aux: "auxiliar_06" },
        { aux: "auxiliar_07" },
        { aux: "auxiliar_08" },
        { aux: "auxiliar_09" },
        { aux: "auxiliar_10" },
    ]

    const { ApiFilter } = require('../../../Functions/FilterApiDomain')
    const baseUrl = ApiFilter(sub)

    async function CalcularAuxiliares(baseUrl, auxiliar) {
        const resultado = await PostCalcularAuxiliares(baseUrl, auxiliar)
        return resultado
    }

    const onClickCalcAuxiliares = async (baseUrl, valorAuxiliar) => {
        setOpen(true)
        const auxCalculado = await CalcularAuxiliares(baseUrl, valorAuxiliar)
        window.alert(auxCalculado.data.Msg)
        setOpen(false)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const changeAuxSelect = (event) => {
        const aux = event.target.value
        setValorAuxiliar(aux)
    }

    const onClickSelectAux = async () => {
        if (valorAuxiliar !== '') {
            const result = await onClickCalcAuxiliares(baseUrl, valorAuxiliar)
            console.log('result: ', result)
            setModalIsOpen(false)
        } else {
            window.alert('Favor selecionar um campo auxiliar para prosseguir.')
        }
    }



    return (
        <>
            <div id='HomePageGeneralContainer'>
                <SideBar />
                <div id='HomePageContainer'>
                    <Header />

                    <div id="ExtrasTitlePage">
                        Ferramentas Complementares
                    </div>

                    <div className="ExtrasPainelContainer">
                        <Link
                            to="/Admin/Extras/ArredondarNotas"
                            className="CardRelatorios">
                            <Iso className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                            <div>
                                ARREDONDAR NOTAS
                            </div>
                        </Link>

                        <Link
                            to="/Admin/Extras/LiberarBloquearNotas"
                            className="CardRelatorios">
                            <LockOpenIcon className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />

                            <div id="textDescriptionCardExtras">
                                Liberar/Bloquear Notas
                            </div>
                        </Link>

                        <Link
                            // to="/Admin/Extras/CalcularAuxiliares" 
                            onClick={() => setModalIsOpen(!modalIsOpen)}
                            className="CardRelatorios">
                            <LowPriorityIcon className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                            <div>
                                CALCULAR AUXILIARES
                            </div>
                        </Link>

                        <Link
                            to="/Admin/Extras/TransferirFaltas"
                            onClick={() => setModalIsOpen(!modalIsOpen)}
                            className="CardRelatorios">
                            <MoveDownIcon className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                            <div>
                                Transferir Faltas
                            </div>
                        </Link>

                        <Modal
                            closeTimeoutMS={200}
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel='Selecionar Auxiliar para Calcular'
                            overlayClassName='modal-overlay'
                            className='modal-content-block-button'
                        >
                            <div id="containerExtrasModalCalcularAux">
                                <h3 id="TitleTextExtrasModalCalcularAux">
                                    Selecione a nota auxiliar obrigatória:
                                </h3>

                                <select placeholder='Selecionar' id="ExtrasModalCalcularAux" value={valorAuxiliar} onChange={changeAuxSelect}>
                                    <option id="ExtrasModalFirstOptionSelecionar" value={''} key={'selecionar'}>Selecionar</option>
                                    {
                                        dados.map((val, key) => {
                                            return (
                                                <option value={val.aux} key={key}>{val.aux}</option>
                                            )
                                        })
                                    }
                                </select>

                                <div id='ButtonModalStudentContainer'>
                                    <button id='ButtonModalStudent' onClick={closeModal}>
                                        <Close id="CloseIconModalStudent" />
                                        FECHAR
                                    </button>

                                    <div onClick={onClickSelectAux}>
                                        <ButtonConfirm Title="CONFIRMAR" />
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </div>
                </div>
            </div>
        </>
    )
}