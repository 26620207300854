//RETURN BASICS INFO LIKE IF THE COMPANY IS ACTIVE AT THE CURRENTY TIME
import Axios from 'axios'

export default async function GetNotasAuxLegendaByKey(baseUrl, chave){
    let result = []
    
    if(baseUrl !== ''){
        await Axios.post(`${baseUrl}/notas/notasAuxLegByKey`, {
            chave: chave
        })
        .then( (response) => {
            result = response.data
            console.log('GetAuxByKey', result)
        })
        .catch( (response) => {
            result = response.data
        })

    }

    return result
}