import Axios from 'axios'

export default async function AuthProfessor(baseUrl,codigo, senha, cliente){
    let result = []

    await Axios.post(`${baseUrl}/authProf?escola=${cliente}`, {
        codigo: codigo,
        senha: senha
    })
    .then( (response) => {
        result = response.data
    })
    .catch( (response) => {
        result = response.data
    })

    return result
}