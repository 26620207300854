import React, { useEffect, useState } from 'react'
import SearchIconAnimated from '../../images/Search-1s-200px.svg'
import Visibility from '@material-ui/icons/Visibility'

import './ProfessoresTabela.css'

export default function ProfessoresTabela(
    { bodyDataRows, searchBarTitle, searchBarInputPlaceHolder, headerTableTitles, bodyTableFields, searchBarShow = false, filterBy }
) {

    const [searchStatus, setSearchStatus] = useState(true)
    const [bodyDataRow, setBodyDataRows] = useState(bodyDataRows)
    const [pesquisa, setPesquisa] = useState('')
    const [pessoasFiltro, setPessoasFiltro] = useState(bodyDataRows)
    const searchTitle = searchBarTitle
    const inputPlaceHolder = searchBarInputPlaceHolder
    const tableTitles = headerTableTitles
    const tableFields = bodyTableFields
    const searchBarIsActive = searchBarShow
    const filterByProp = filterBy

    useEffect(() => {
        if (bodyDataRows.length > 0) {
            setSearchStatus(false)
        } else {
            setSearchStatus(true)
        }
        setBodyDataRows(bodyDataRows)
        setPessoasFiltro(bodyDataRows)
    }, [bodyDataRows])

    const OnSearchName = (event) => {
        setPesquisa(event.target.value)
        let inputPesquisa = event.target.value
        //Sem texto mostra todas as pessoas
        if (event.target.value === '') {
            setPessoasFiltro(bodyDataRow)
            //Com texto filtra as pessoas
        } else {
            let listarFiltro = bodyDataRow.filter((pessoa) =>
                pessoa[filterByProp].toUpperCase().includes(inputPesquisa.toUpperCase())
            )
            setPessoasFiltro(listarFiltro)
        }
    }

    const onClickPessoa = async (dado) => {
        sessionStorage.setItem('p', JSON.stringify(dado))
        window.location.replace('/Admin/Professores/EditarProfessor')
    }

    useEffect(() => {
        console.log('Professores Render')
    }, [pessoasFiltro])

    return (
        <div className='ProfessoresTabelaDashContainer'>
            <div className='ProfessoresTabelaDashBarraPesquisa'>
                <div>
                    {searchTitle}
                </div>
                {searchBarIsActive === true ?
                    <input type="text" onChange={OnSearchName} value={pesquisa} placeholder={inputPlaceHolder} />
                    : <></>
                }

            </div>

            <div className='ProfessoresTabelaDashTable'>
                {
                    searchStatus === false ?
                        <table >
                            <thead>
                                <tr>
                                    {tableTitles?.map(item => (
                                        <th>{item}</th>
                                    )
                                    )}

                                </tr>
                            </thead>

                            <tbody >
                                {pessoasFiltro?.map((val, key) => {
                                    return (
                                        <tr id={"Row" + key} name={val[filterByProp]} >
                                            {tableFields?.map((item, key) => (
                                                <td name={item} >
                                                    {val[item]?.toString()?.toUpperCase()}
                                                </td>
                                            )
                                            )}
                                            <Visibility id="IconEditProfessor" onClick={() => onClickPessoa(val)} />
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                        :
                        <div id='ContainerIconSearchLoadingStatus'>
                            <p>
                                Procurando...
                            </p>
                            <img src={SearchIconAnimated} alt='Search Icon' />
                        </div>
                }
            </div>
                <div id='QtdProfessoresTabelaProfessores'>
                    Professores: {pessoasFiltro?.length}
                </div>
        </div>
    )
}