import React, { useEffect, useState } from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import NotasDados from '../../../components/NotasDados/NotasDados'
import SelectAPI from '../../../Functions/SelectApi'
import './DetalhesNotas.css'

let baseApi = ''

export default function DetalhesNotas() {
    const [valorEscola, setValorEscola] = useState(localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola')
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'

    useEffect(() => {
        baseApi = SelectAPI(valorEscola)
    }, [valorEscola])

    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <body className='DetalhesNotas'>
                <div id='DetalhesNotasGeneralContainer'>
                    <SideBar />
                    <div className='DetalhesNotasContainer'>
                        <Header />
                        <div id="DetalhesNotasTitlePage">
                            DADOS NOTAS
                        </div>
                        <NotasDados title={"DADOS NOTAS"} baseApi={baseApi} />
                    </div>
                </div>
            </body>

        )
    }
}