import './Lancar.css'
import '../../../Types/TypesServices'
import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import Header from '../../../components/header'
import Axios from 'axios';
import { Usuario } from '../../../context/userForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import PesquisarDados from '../../../Functions/PesquisarDados'
import FiltrarCurso from '../../../Functions/FiltrarCurso';
import OrdemItem from '../../../Functions/OrdenarItens';
import GetAlunosDesligados from '../../../services/GetDesligados';
import FiltrarDesligados from '../../../Functions/FiltrarAlunosDesligados';
import GetAlunosOptativas from '../../../services/GetAlunosOpByDisciplina';
const { ApiFilter } = require('../../../Functions/FilterApiDomain')

toast.configure()

const UseFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
    return [htmlElRef, setFocus]
}

let valorDisciplinaAtual = ''
let valorTurmaAtual = ''
let valorCursoAtual = ''
let valorDataAtual = ''
let conteudoPush = []
let alunosOpArr = []
let contadorAlunosOpFilter = 0

export default function Lancar() {
    const [inputConteudoRef, setInputConteudoFocus] = UseFocus()
    const [inputAtividadeAulaRef, setInputAtividadeAulaFocus] = UseFocus()
    const [inputAtividadeCasaRef, setInputAtividadeCasaFocus] = UseFocus()

    const { form, setForm } = Usuario()
    const { isLoged, setIsLoged } = Usuario()
    const [focus, setFocus] = useState(false)
    //VALORES GERAIS
    const [anoPermissao, setAnoPermissao] = useState('')
    const [polivalenteStatus, setPolivalenteStatus] = useState(sessionStorage.getItem('Poli'))
    const [escola, setEscola] = useState(localStorage.getItem('escola'))
    const [permissaoExcluirDados, setPermissaoExcluirDados] = useState(localStorage.getItem('removerDados'))
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [aluno, setAluno] = useState([]);
    const [curso, setCurso] = useState([]);
    const [digitacao, setDigitacao] = useState([]);
    const [disciplina, setDisciplina] = useState([]);
    const [faltasAlunos, setFaltasAlunos] = useState([]);
    const [consultaConteudo, setConsultaConteudo] = useState([])
    const [DataCompleta, setDataCompleta] = useState(new Date().toLocaleDateString().split('/'))
    const [DataAno, setDataAno] = useState('')
    const [DataMes, setDataMes] = useState('')
    const [DataDia, setDataDia] = useState('')
    const [conteudoBloqueado, setConteudoBloqueado] = useState(false)
    //VALORES FILTRADOS
    const [permissao, setPermissao] = useState([]);
    const [disciplinaFilter, setDisciplinaFilter] = useState([])
    const [cursoFilter, setCursoFilter] = useState([])
    const [turmaFiltro, setTurmaFiltro] = useState([])

    /**
     * @type {[[GetAlunosReturn], Function]}
     */
    const [alunosPermitidos, setAlunosPermitidos] = useState([])

    const [alunosOpcional, setAlunosOpcional] = useState([])
    const [alunosFiltroGetFaltas, setAlunosFiltroGetFaltas] = useState(null)
    const [prevAlunosPermitido, setPrevAlunosPermitido] = useState([])

    const [valorData, setValorData] = useState('')
    const [valorConteudo, setValorConteudo] = useState('')
    const [valorAtividadeAula, setValorAtividadeAula] = useState('')
    const [valorAtividadeCasa, setValorAtividadeCasa] = useState('')
    const [valorCurso, setValorCurso] = useState([]);
    const [valorDisciplina, setValorDisciplina] = useState([]);
    const [valorTurma, setValorTurma] = useState([])
    const [valorAula, setValorAula] = useState('')
    const [data, setData] = useState('')
    const [lancado, setLancado] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [alunosDesligados, setAlunosDesligados] = useState([])

    //COMPONENTES
    const [buttonDisable, setButtonDisable] = useState(false)
    const [deleteIsInvisible, setDeleteIsInvisible] = useState(true)
    const [detailsIsOpen, setDetailsIsOpen] = useState(false)
    const [lancarButtonText, setLancarButtonText] = useState('CONFIRMAR')
    const [lancarButtonLoadingClass, setLancarButtonLoadingClass] = useState('')
    const [excluirButtonText, setExcluirButtonText] = useState('EXCLUIR AULA')
    const [excluirButtonLoadingClass, setExcluirButtonLoadingClass] = useState('')
    const [anoAtual, setAnoAtual] = useState(new Date().getFullYear())
    //FORMULARIO
    const { register, handleSubmit, errors } = useForm()
    const [session, setSession] = useState(sessionStorage)
    //VARIÁVEIS AUXILIARES
    let logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'
    let idProf = form.length > 0 ? form.professores : session.professores //Index dos ids retornado inicia no valor 0. Colocar +1 pra nivelar com valor real do id do banco
    let profId = parseInt(form.length > 0 ? form.professores : session.professores)
    let permissaoFiltro = []
    let disciplinaDistinct = []
    let disciplinaPermissao = []
    let disciplinaFiltro = []
    let turmaPermissao = []
    let turmaDistinct = []
    let PrimeiroTurma = []
    let PrimeiroCurso = []
    let PrimeiraDisciplina = []
    let alunosPermissao = []
    let checkArray = []
    let alunosPermitidosNomeOrdem = []
    let alunosPermitidosPush = []
    let alunoFiltroToGetFaltas = []
    let faltasAlunosPush = []
    let prevAlunosPermitidos = prevAlunosPermitido.length !== 0 ? prevAlunosPermitido : []
    prevAlunosPermitidos = prevAlunosPermitido.length !== 0 ? prevAlunosPermitido : []
    const anoMaximo = anoAtual > anoPermissao ? `${anoPermissao}-12-31` : `${anoPermissao}-${DataMes}-${DataDia}`

    const options = {
        header: { 'X-Custom-Header': 'value' }
    }

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState('#19648C');
    const override = css`
      background-color: rgba(0,0,0,0.2);
      position: absolute;
      margin-top: -14em;
      padding: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      cursor: progress;
    `;

    sessionStorage.getItem('isLoged') ? setIsLoged(true) : setIsLoged(false)

    //NOTIFICAÇÕES
    function notifySucessLancando() {
        toast.success('Concluindo lançamento', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }
    function notifySucessAtualizando() {
        toast.success('Concluindo atualização', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }
    function notifySucessLancando() {
        toast.success('Concluindo lançamento', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }
    function notifyInfoDeleteSucess() {
        toast.info('Concluindo exclusão!', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }

    //CONSULTAR URL
    let baseUrl = ApiFilter(sub)

    //CONSULTAR DADOS
    let cursosDadosFunc = async () => {
        const [{ cursosDado, disciplinasDado, alunosDado, digitacaoDado }] = await Promise.all([PesquisarDados(baseUrl)])
        setCurso(cursosDado)
        setDisciplina(disciplinasDado)
        setAluno(alunosDado)
        setDigitacao(digitacaoDado)

        setAnoPermissao(localStorage.getItem('Year'))

        return cursosDado
    }

    async function GetDesligados() {
        let desligados = await GetAlunosDesligados(baseUrl)
        setAlunosDesligados(desligados)
    }

    useEffect(() => {
        setDataAno(DataCompleta[2])
        setDataMes(DataCompleta[1])
        setDataDia(DataCompleta[0])
    }, [DataCompleta])

    //GETCURSOS && GETDIGITACAO
    useEffect(() => {
        //CURSOS GET
        cursosDadosFunc()
        FiltrarCursos()
        GetDesligados()

    }, [escola]);

    useEffect(() => {
        setDigitacao(digitacao)
        setCurso(curso)
        setDisciplina(disciplina)
        setAluno(aluno)
        FiltrarCursos()
    }, [permissao])


    //FILTRAR CURSOS PERMITIDOS
    function FiltrarCursos() {
        let filtrarCursosImport = FiltrarCurso(permissao, curso)
        setCursoFilter(filtrarCursosImport)
        FiltrarDisciplina(valorCurso)
    }

    useEffect(() => {
        for (let valor of digitacao) {
            if (valor.CodFuncionario === idProf) {
                permissaoFiltro.push(valor)
            }
        }
        setPermissao(permissaoFiltro)
    }, [digitacao])

    useEffect(() => {
        if (valorCurso !== Number) {
            PrimeiroCurso = cursoFilter.map(item => item.codigo)[0]
        }

        if (valorDisciplina !== Number) {
            PrimeiraDisciplina = disciplinaFilter.map(item => item.CodDisciplina)[0]
        }
    }, [permissao])


    useEffect(async () => {
        setDetailsIsOpen(false)
        FiltrarTurma(valorCurso, valorDisciplina)
        if (valorDisciplina !== 666 && valorDisciplina.length > 0) {
            const alunosOp = await GetAlunosOptativas(baseUrl, valorDisciplina)
            setAlunosOpcional(alunosOp)

            if (alunosOp.length > 0) {
                alunosOpArr = []
                alunosOpArr.push(alunosOp)
            }
        }
    }, [valorDisciplina])

    useEffect(() => {
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)
    }, [valorCurso])

    useEffect(() => {
        if (cursoFilter.length === 0) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [cursoFilter])

    const FiltrarAlunosPermitidos_E_Optativas = () => {
        if (alunosPermitidos.length > 0) {
            if (alunosOpcional?.length > 0 && alunosOpcional?.length !== undefined) {
                const resultado = alunosPermitidos.filter(aluno => alunosOpcional.filter(alunoArr => alunoArr.Matricula === aluno.Matricula).length)

                if (resultado.length > 0) {
                    const ordenarAlunos = OrdemItem(resultado, "Nome")
                    setAlunosPermitidos(ordenarAlunos)
                    contadorAlunosOpFilter = 1
                    return ordenarAlunos //Retorno opcional
                }
            }
        } else {
            return alunosPermitidos
        }
    }

    useEffect(() => {
        FiltrarAluno(valorCurso, valorTurma)
        const permitidosOpFilter = FiltrarAlunosPermitidos_E_Optativas()
        const permitidos = permitidosOpFilter !== undefined ? permitidosOpFilter : alunosPermitidos
        setAlunosPermitidos(permitidos)
    }, [valorTurma])

    useEffect(() => {
        FiltrarAlunosPermitidos_E_Optativas()
    }, [valorDisciplina])

    useEffect(() => {
        setValorAula(0)
        setDetailsIsOpen(false)

        if (alunosPermitidos.length > 0 && contadorAlunosOpFilter === 0) {
            if (alunosOpcional?.length > 0 && alunosOpcional?.length !== undefined) {
                const resultado = alunosPermitidos.filter(aluno => alunosOpcional.filter(alunoArr => alunoArr.Matricula === aluno.Matricula).length)

                if (resultado.length > 0) {
                    const ordenarAlunos = OrdemItem(resultado, "Nome")
                    setAlunosPermitidos(ordenarAlunos)
                    contadorAlunosOpFilter = 1
                }
            }
        }
    }, [alunosPermitidos])

    useEffect(() => {
        if (alunosPermitidos.length > 0 && contadorAlunosOpFilter === 0) {
            if (alunosOpArr[0]?.length > 0 && alunosOpArr[0]?.length !== undefined) {
                const resultado = alunosPermitidos.filter(aluno => alunosOpArr[0].filter(alunoArr => alunoArr.Matricula === aluno.Matricula).length)

                if (resultado.length > 0) {
                    setAlunosPermitidos(resultado)
                    contadorAlunosOpFilter = 1
                }
            }
        }
    }, [])


    useEffect(() => {
        if (alunosPermitidos.length > 0) {
            const alunosPush = FiltrarAluno(valorCurso, valorTurma)
            const resultado = alunosPush.filter(aluno => alunosOpcional.filter(alunoArr => alunoArr.Matricula === aluno.Matricula).length)
            if (resultado.length > 0) {
                setAlunosPermitidos(resultado)
            }
        }
    }, [alunosOpcional])

    useEffect(() => {
        let count = 1
        while (count < 2 && valorAula !== 0 && faltasAlunos.length > 0) {
            setDetailsIsOpen(false)
            count = count + 1
        }

        if (detailsIsOpen !== true) {
            setDetailsIsOpen(true)
        }
    }, [faltasAlunos])

    function camposFaltas(aulas, val, key) {
        let aulasNumero = parseInt(aulas)
        let valor = 0
        checkArray = []

        while (valor < aulasNumero) {
            valor = valor + 1
            checkArray.push(<input type="checkbox" value={`check${valor}`} name={val.Matricula} ref={register} />)
        }

        return checkArray.map((item) => {
            return item
        })
    }

    const onChangeAulas = (event) => {
        if (event.target.value > 6) {
            event.target.value = 6
        }

        let valor = parseInt(event.target.value)
        const aulaLançada = consultaConteudo[0]?.aulas

        if (faltasAlunos[0]?.length === 0) {
            setValorAula(valor)
            onClickAlunos()
        }

        if (isChecked === true || valorAula === 0) {
            setValorAula(event.target.value)
        } else if (faltasAlunos[0]?.length > 0 || isChecked) {
            setValorAula(aulaLançada)
        }
    }

    const onChangeEditarAula = (event) => {
        setIsChecked(!isChecked)
    }

    //FILTRAR PERMISSAO DE ACORDO COM O CURSO SELECIONADO
    function PermisssaoSelecionado(curso_selecionado) {
        disciplinaPermissao = []
        //Varrer Permissao
        for (let valor of permissao) {
            if (valor.CodCurso === curso_selecionado) {
                disciplinaPermissao.push(valor)
            }
        }

    }

    //FILTRAR DISCIPLINA DE ACORDO COM O CURSO SELECIONADO
    function FiltrarDisciplina(curso_selecionado) {
        disciplinaFiltro = []
        setDisciplinaFilter([])
        PermisssaoSelecionado(curso_selecionado)
        disciplinaDistinct = [...new Set(disciplinaPermissao.map(item => item.CodDisciplina))]

        for (let idDisciplina of disciplinaDistinct) {
            //Varrer Disciplina
            for (let valor of disciplina) {

                if (valor.Codigo === idDisciplina) {
                    disciplinaFiltro.push(valor)
                }
            }
        }
        setDisciplinaFilter(disciplinaFiltro)
    }

    //disciplinaPermissao Where codigo_disciplina === disciplinaSelecionada
    async function FiltrarTurma(curso_selecionado, disciplina_selecionada) {
        turmaPermissao = []
        setTurmaFiltro([])
        //Varrer permissao por curso e turma selecionados
        if (anoPermissao === 'null') {
            for (let valor of permissao) {
                if (valor.CodCurso === curso_selecionado && valor.CodDisciplina === disciplina_selecionada) {
                    turmaPermissao.push(valor);

                }
            }
            //Salvar Array de Turmas evitando duplicidade
            turmaDistinct = [...new Set(turmaPermissao.map(item => item.Turma))]
            setTurmaFiltro(turmaDistinct)
        } else {
            let digitacaoPermitido = []
            if (polivalenteStatus !== 'null') {
                digitacaoPermitido = digitacao.filter(item => item.CodFuncionario === idProf && item.CodCurso === valorCurso)
            } else {
                digitacaoPermitido = digitacao.filter(item => item.CodFuncionario === idProf && item.CodCurso === valorCurso && item.CodDisciplina === valorDisciplinaAtual)
            }
            let turmasProf = digitacaoPermitido.map(item => item.Turma)
            let turmasPermitido = [... new Set(turmasProf)]
            setTurmaFiltro(turmasPermitido)
        }
    }

    function FiltrarAluno(curso_selecionado, turma_selecionada) {
        //Reinicia variavel alunosPermitidosPush
        alunosPermitidosPush = []

        //Pega alunos permitidos
        alunosPermissao = aluno.filter(item => item.Curso === curso_selecionado && item.Turma === turma_selecionada)

        //Pega apenas os nomes em ordem alfabetica
        alunosPermitidosNomeOrdem = alunosPermissao.map((item) => item.Nome).sort()

        //Compara cada nome da ordem alfabetica com o alunosPermissao. E adiciona em um array
        for (let valor of alunosPermitidosNomeOrdem) {
            alunosPermitidosPush.push(alunosPermissao.find((item) => item.Nome === valor))
        }

        const filtrarAlunosDesligados = FiltrarDesligados(alunosPermitidosPush, alunosDesligados, valorDataAtual)
        const alunosOrdenados = OrdemItem(filtrarAlunosDesligados, 'Nome')
        setAlunosPermitidos(alunosOrdenados)
        return alunosPermitidosPush
    }

    //FILTRAR DIGITACAO PERMITIDA
    useEffect(() => {
        FiltrarCursos()
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)

        FiltrarTurma(valorCurso, valorDisciplina)
    }, [digitacao])

    useEffect(() => {
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)
    }, [valorCurso])

    useEffect(() => {
        if (cursoFilter.length === 0) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [cursoFilter])

    const onChangeCurso = async (event) => {
        valorCursoAtual = event.target.value
        valorTurmaAtual = ''
        const isDiferent = valorCursoAtual !== valorCurso ? true : false
        setValorCurso(event.target.value)
        setConsultaConteudo([])
        setFaltasAlunos([])
        FiltrarTurma(valorCursoAtual)

        if (polivalenteStatus === 'null') {
            FiltrarDisciplina(valorCursoAtual)

            if (isDiferent === true && alunosPermitidos.length > 0) {
                setValorDisciplina('')
                setValorTurma('')
                setValorConteudo('')
                setValorAtividadeAula('')
                setValorAtividadeCasa('')
                setValorAula(0)
                setData(0)
            }

            FiltrarTurma(valorCursoAtual)
        } else {
            setValorDisciplina('')
            setValorTurma('')
            setValorConteudo('')
            setValorAtividadeAula('')
            setValorAtividadeCasa('')
            setValorAula(0)
            setData(0)
            FiltrarTurma(valorCursoAtual)
        }

        if (valorTurmaAtual !== '' && valorTurmaAtual !== 4444) {

            if (alunosPermitidosPush.length !== 0 && data !== '' && isDiferent === true) {
                setTurmaFiltro([])
                setValorTurma('')
                setValorDisciplina('')
                setValorConteudo('')
                setValorAtividadeAula('')
                setValorAtividadeCasa('')
            }
        }
    }

    const onChangeDisciplina = (event) => {
        valorDisciplinaAtual = event.target.value

        const isDiferent = valorDisciplinaAtual !== valorDisciplina ? true : false
        setValorDisciplina(event.target.value)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplinaAtual)


        if (alunosPermitidos.length !== 0 && isDiferent === true && data !== '' && valorCurso !== '' && valorTurma !== '') {
            handleGetFaltas(alunosPermitidos, data)
            filtrarAlunosGetFaltas()
        }

    }

    const onChangeTurma = async (event) => {
        valorTurmaAtual = event.target.value
        const isDiferent = valorTurmaAtual !== valorTurma ? true : false

        if (valorTurmaAtual !== valorTurma) {
            setValorTurma(event.target.value)
        } else if (isDiferent === false && consultaConteudo.length > 0) {
            let aulasNumber = consultaConteudo[0].aulas
            setValorAula(aulasNumber)
        }

        const alunosFiltro = FiltrarAluno(valorCurso, valorTurmaAtual)

        if (polivalenteStatus !== 'null') {
            if (alunosFiltro.length !== 0 && isDiferent === true && data !== '' && valorCurso !== '' && valorDisciplina !== '') {
                handleGetFaltas(alunosFiltro, data)
                filtrarAlunosGetFaltas()
            }
        } else {
            if (alunosFiltro.length !== 0 && isDiferent === true && data !== '' && valorCurso !== '') {
                handleGetFaltas(alunosFiltro, data)
                filtrarAlunosGetFaltas()
            }
        }
    }

    const onChangeConteudo = async (event) => {
        setValorConteudo(event.target.value)
    }

    const onChangeAtividadeAula = async (event) => {
        setValorAtividadeAula(event.target.value)
    }

    const onChangeAtividadeCasa = async (event) => {
        setValorAtividadeCasa(event.target.value)
    }

    const onClickAlunos = () => {
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)

        let a = []
        let count = 1

        filtrarAlunosGetFaltas()

        if (faltasAlunos.length > 0) {
            let maiorNumeroFaltas = faltasAlunos.map((item) => item.faltas).sort().reverse()
            let aulasNumero = maiorNumeroFaltas[0]
        }
    }

    //Ativar Botão DELETE 
    function ativarBotaoDelete() {
        if (valorAula === 0 || valorAula === '') {
            setDeleteIsInvisible(true)
            if (faltasAlunos[0]?.length === 0) {
                setDeleteIsInvisible(true)
            }
        } else {
            setDeleteIsInvisible(false)
        }

        if (faltasAlunos[0]?.length === 0) {
            setDeleteIsInvisible(true)
        }
    }

    useEffect(() => {
        ativarBotaoDelete()

        const aulaLançada = consultaConteudo[0]?.aulas

        if (faltasAlunos[0]?.length > 0) {
            if (isNaN(parseInt(aulaLançada)) === true || aulaLançada === '') {
                //setValorAula('')
            } else {
                setValorAula(aulaLançada)
            }
        }


        if (faltasAlunos.length !== 0) {
            let maiorNumeroFaltas = []
            maiorNumeroFaltas = faltasAlunos.map((item) => item.faltas).sort().reverse()
            let aulasNumero = maiorNumeroFaltas[0]
        }
    }, [faltasAlunos])

    useEffect(() => {
        ativarBotaoDelete()
    }, [valorAula])

    function handleGetFaltas(alunos, dataAtual) {
        //faltasAlunosPush = []
        let countNotification = 0

        async function getFaltasAlunos() {
            setLoading(true)

            await Axios.post(`${baseUrl}/getFaltas`, {
                escola: escola,
                alunos: alunos,
                data: dataAtual,
                disciplina: polivalenteStatus === 'null' ? valorDisciplinaAtual : ''
            })
                .then((response) => {
                    faltasAlunosPush = []
                    faltasAlunosPush.push(response.data)
                    setFaltasAlunos(faltasAlunosPush)

                    setLoading(false)
                    if (faltasAlunosPush.length > 0) {
                        filtrarAlunosGetFaltas()
                        handleGetConteudo(data, valorCurso, valorDisciplinaAtual, valorTurmaAtual)
                    }
                })
                .catch((error) => {
                    console.log('Erro: ', error)
                    setLoading(false)
                })
        }

        if (data !== '' && alunosPermitidos.length > 0) {
            getFaltasAlunos()
        } else {
            getFaltasAlunos()
        }

    }

    async function handleGetConteudo(data, curso_id, disciplina_id, turma_id) {
        async function getConteudo() {
            conteudoPush = []
            setLoading(true)
            await Axios.post(`${baseUrl}/getConteudo`, {
                escola: escola,
                data: data,
                curso_id: curso_id,
                disciplina_id: polivalenteStatus === 'null' ? disciplina_id : '',
                turma_id: turma_id
            })
                .then((response) => {
                    for (let valor of response.data) {
                        conteudoPush.push(valor)
                    }
                    setLoading(false)
                    colarConteudo(conteudoPush)
                    colarAtividadeAula(conteudoPush)
                    colarAtividadeCasa(conteudoPush)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log('Erro ao consultar conteudo ', error)
                })
            setConsultaConteudo(conteudoPush)
            if (conteudoPush.length > 0) {
                if (isChecked === true || conteudoPush[0]?.aulas === 0) {
                    setValorAula(valorAula)
                } else if (conteudoPush[0]?.aulas > 0 || isChecked === true) {
                    setValorAula(conteudoPush[0].aulas)
                }
            } else {
                setValorAula(0)
            }
        }
        getConteudo()
    }

    async function inserirDados(dado, matricula, faltas) {
        let countNotification = 0
        await Axios.post(`${baseUrl}/criar-diario`,
            {
                escola: escola,
                formulario: dado
            }, options)
            .then(() => {
                if (countNotification === 0) {
                    notifySucessLancando()
                    setButtonDisable(true)
                    setLancarButtonLoadingClass('loadingLançar')
                    setLancarButtonText('    ENVIANDO...')
                    setTimeout(function () {
                        window.location.replace(window.location.origin + '/Diario/Lancar')
                        //window.location.reload()
                    }, 4000)
                    countNotification = 1
                }
            })
            .catch(() => {
                window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                setButtonDisable(false)
                setLancarButtonLoadingClass('')
                setLancarButtonText('CONFIRMAR')
            })
    }


    async function inserirConteudo(dado) {
        // id / data / curso-id / disciplina_id / turma / descricao / atividade_casa / etapa
        await Axios.post(`${baseUrl}/conteudo`,
            {
                escola: escola,
                formulario: dado
            }, options).then(() => {
                //console.log('Sucesso na inserção do conteúdo')
            })
    }

    async function deletarConteudo(dado) {
        // id / data / curso-id / disciplina_id / turma / descricao / atividade_casa / etapa
        await Axios.post(`${baseUrl}/deletar-conteudo`,
            {
                formulario: dado
            }, options)
            .then(() => {
                console.log('Sucesso em deletar conteúdo')
            })
            .catch((error) => {
                console.log('Erro em deletarConteudo: ', error)
            })
    }

    async function deletarDados(dado) {
        let countNotification = 0

        setExcluirButtonLoadingClass('loadingLançar')
        setExcluirButtonText('    EXCLUINDO...')
        await Axios.post(`${baseUrl}/deletar-diario`,
            {
                formulario: dado
            }, options)
            .then((result) => {
                const resultado = result.data
                const resultadoEsperado = 'Faltas deletadas com sucesso'
                if (resultado === resultadoEsperado) {
                    if (countNotification === 0) {
                        setDeleteIsInvisible(true)
                        notifyInfoDeleteSucess()
                        setButtonDisable(true)

                        setTimeout(function () {
                            window.location.replace(window.location.origin + '/Diario/Lancar')
                            // window.location.reload()
                        }, 4000)
                        countNotification = 1
                    }
                }
            })
            .catch((error) => {
                window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                console.log(error)
                setButtonDisable(false)
                setDeleteIsInvisible(false)
                setExcluirButtonLoadingClass('')
                setExcluirButtonText('EXCLUIR AULA')
            })
    }

    async function updateFaltas(dado) {
        let countNotification = 0

        await Axios.post(`${baseUrl}/updateFaltas`, {
            escola: escola,
            formulario: dado
        }, options)
            .then(() => {
                if (countNotification === 0) {
                    notifySucessAtualizando()
                    setButtonDisable(true)
                    setLancarButtonLoadingClass('loadingLançar')
                    setLancarButtonText('    ENVIANDO...')
                    setTimeout(function () {
                        window.location.replace(window.location.origin + '/Diario/Lancar')
                        //window.location.reload()
                    }, 4000)
                    countNotification = 1
                }
            })
            .catch(() => {
                window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                setButtonDisable(false)
                setLancarButtonLoadingClass('')
                setLancarButtonText('CONFIRMAR')
            })
    }

    async function updateConteudo(dado) {
        await Axios.post(`${baseUrl}/updateConteudo`, {
            escola: escola,
            formulario: dado
        }, options)
            .then((response) => {
                console.log(`${escola} CONTEÚDO ATUALIZADO`)
            })
            .catch((error) => {
                console.log(`${escola} FALHA AO ATUALIZAR CONTEUDO`, error)
            })
    }

    const onSubmit = async (dado) => {
        if (valorTurma === '' || valorTurma === undefined || data === '' || valorAula === 0) {
            window.alert('Por favor confirme se todos os dados estão preenchido')
        } else if (valorConteudo === '') {
            window.alert('O campo CONTEÚDO não pode ficar em branco')
        } else {
            if (
                (faltasAlunos[0]?.length === 0 || faltasAlunos[0] === undefined) ||
                (consultaConteudo.length === 0)
            ) {
                setLancado(true)
                setButtonDisable(true)
                setLancarButtonLoadingClass('loadingLançar')
                setLancarButtonText('    ENVIANDO...')

                if (consultaConteudo.length === 0) {
                    inserirConteudo(dado)
                    if (faltasAlunos[0]?.length === 0 || faltasAlunos[0] === undefined) {
                        inserirDados(dado)
                    } else {
                        updateFaltas(dado)
                    }
                } else {
                    inserirDados(dado)
                }
            } else {
                try {
                    setLancado(true)
                    setButtonDisable(true)
                    setLancarButtonLoadingClass('loadingLançar')
                    setLancarButtonText('    ENVIANDO...')

                    updateFaltas(dado)
                    updateConteudo(dado)
                } catch {
                    alert('Por favor confirme se todos os dados estão preenchido')
                    setButtonDisable(false)
                    setLancarButtonLoadingClass('')
                    setLancarButtonText('CONFIRMAR')
                }
            }

        }
    }

    const onDelete = async (dado) => {
        if (valorAula === 0 || valorAula === '') {
            if (faltasAlunos[0]?.length === 0) {
                window.alert('Não existe aula dessa turma e data para excluir')
            } else {
                window.alert('Para excluir, o NÚMERO DE AULA deve ser acima de Zero')
            }
        } else {
            if (valorTurma === '' || valorTurma === undefined || data === '') {
                alert('Por favor confirme se todos os dados estão preenchido')
            } else {
                if (window.confirm('DESEJA REALMENTE EXCLUIR ESSA AULA? ') === true) {
                    setLancado(true)
                    deletarDados(dado)
                    deletarConteudo(dado)
                    setExcluirButtonLoadingClass('loadingLançar')
                    setExcluirButtonText('      EXCLUINDO...')
                    setDeleteIsInvisible(true)
                }
            }
        }
    }

    function filtrarAlunosGetFaltas() {
        alunoFiltroToGetFaltas = []
        setAlunosFiltroGetFaltas([])
        let filtro = {}
        for (let individuo of alunosPermitidos) {
            if (individuo !== undefined) {
                let count = 0
                filtro = {}

                for (let dado in individuo) {

                    if (count < 5 && dado !== undefined) {
                        filtro[dado] = individuo[dado]
                        count = count + 1
                    } else {
                        alunoFiltroToGetFaltas.push(filtro)
                        count = count + 1
                        break
                    }

                }
            }
        }
        setAlunosFiltroGetFaltas(alunoFiltroToGetFaltas)
    }

    useEffect(() => {
        valorDisciplinaAtual = valorDisciplina

        if (faltasAlunos.length !== 0) {
            let maiorNumeroFaltas = faltasAlunos.map((item) => item.faltas).sort().reverse()
            let aulasNumero = maiorNumeroFaltas[0]
        }

        if (faltasAlunosPush.length === 0 && data !== '' && valorTurmaAtual !== '') {
            valorDisciplinaAtual = valorDisciplina
            const alunosPermitidosOpFilter = FiltrarAlunosPermitidos_E_Optativas()
            const permitidos = alunosPermitidosOpFilter !== undefined ? alunosPermitidosOpFilter : alunosPermitidos
            handleGetFaltas(permitidos, data)
        }

        setDetailsIsOpen(detailsIsOpen)
        let count = 1
        while (count < 2 && valorAula !== 0 && faltasAlunos.length > 0) {
            setDetailsIsOpen(false)
            setTimeout(() => {
                //setDetailsIsOpen(!detailsIsOpen)
            }, 2000)
            count = count + 1
        }
        filtrarAlunosGetFaltas()
    }, [data])

    const handleDate = (event) => {
        setData(event.target.value)
        valorDataAtual = event.target.value

        let maiorNumeroFaltas = []

        if (faltasAlunos.length !== 0) {
            maiorNumeroFaltas = faltasAlunos.map((item) => item.faltas).sort().reverse()
            let aulasNumero = maiorNumeroFaltas[0]
        }

        if (valorTurmaAtual !== '') {
            FiltrarDisciplina(valorCurso)
            FiltrarTurma(valorCurso, valorDisciplina)
            FiltrarAluno(valorCurso, valorTurma)
        }
        //filtrarAlunosGetFaltas()
        setDetailsIsOpen(true)
        setValorData(event.target.value)

        let filtrarAlunosDesligados = FiltrarDesligados(alunosPermitidosPush, alunosDesligados, valorDataAtual)
        // console.log('Alunos Desligados: ', filtrarAlunosDesligados)
    }

    useEffect(() => {
        if (consultaConteudo[0] === undefined) {
            setValorConteudo('')
            setValorAtividadeAula('')
            setValorAtividadeCasa('')
        }
        const conteudoBloqueado = consultaConteudo[0]?.bloqueado ? consultaConteudo[0]?.bloqueado : 0

        if (conteudoBloqueado === 0) {
            setConteudoBloqueado(false)
        } else {
            setConteudoBloqueado(true)
        }
    }, [consultaConteudo])

    function colarConteudo(dado) {
        if (dado.length !== 0) {
            if (valorConteudo === '') {
                setValorConteudo(dado[0].descricao)
                setInputConteudoFocus()
            } else {
                if (valorConteudo !== dado[0].descricao) {
                    setValorConteudo(dado[0].descricao)
                    setInputConteudoFocus()
                }
            }

        } else if (dado[0]?.descricao === undefined) {
            setValorConteudo('')
        }
    }

    function colarAtividadeAula(dado) {
        if (dado[0] !== undefined) {
            if (valorAtividadeAula === '') {
                setValorAtividadeAula(dado[0].atividade_aula)
                setInputAtividadeAulaFocus()
            } else {
                if (valorAtividadeAula !== dado[0].atividade_aula) {
                    setValorAtividadeAula(dado[0].atividade_aula)
                    setInputAtividadeAulaFocus()
                }
            }

        } else if (dado[0]?.atividade_aula === undefined) {
            setValorAtividadeAula('')
        }
    }

    function colarAtividadeCasa(dado) {
        if (dado[0] !== undefined) {
            if (valorAtividadeCasa === '') {
                setValorAtividadeCasa(dado[0].atividade_casa)
                setInputAtividadeCasaFocus()
            } else {
                if (valorAtividadeCasa !== dado[0].atividade_casa) {
                    setValorAtividadeCasa(dado[0].atividade_casa)
                    setInputAtividadeCasaFocus()
                }
            }

        } else if (dado[0]?.atividade_casa === undefined) {
            setValorAtividadeCasa('')
        }
    }

    if (logado === 'true') {
        return (
            <div >
                <Header />
                <div className="container">
                    <section>
                        <div className="info-bar" id="infoBarDiario">
                            <div className="title">
                                <p className="title-page">Lançar Diário</p>
                                <p className="subtitle-page">Colégio {localStorage.getItem('escola').toUpperCase()}</p>
                            </div>
                            <div className="container-informativo">
                                <p className="informativo-title">Observações</p>
                                <p className="informativo-desc">Os campos Atividades de Aula e Atividades de Casa são opcionais, porém se preenchidos, serão disponibilizados para Pais e Alunos.</p>
                            </div>
                        </div>

                        <form action="" method="post">
                            <div className="container-form" id="containerFormDiario">

                                <label htmlFor="curso">Curso</label>
                                <select onChange={onChangeCurso} onClick={onChangeCurso} value={valorCurso} name="curso" id="curso" ref={register} required={true}>
                                    <option value={9999} key='CursoBranco'></option>
                                    {cursoFilter.map((val, key) => {
                                        return <option value={val.Codigo} key={key}>{val.Curso}</option>
                                    })}
                                </select>

                                <label htmlFor="disciplina">Disciplina {polivalenteStatus !== 'null' ? '(Polivalente)' : ''}</label>
                                <select onChange={onChangeDisciplina} onClick={onChangeDisciplina} value={valorDisciplina}
                                    name="disciplina" id="disciplina" ref={register} required={true}
                                    disabled={polivalenteStatus !== 'null' ? true : false}
                                >
                                    <option value={6666} key='DisciplinaBranco'></option>
                                    {disciplinaFilter.map((val, key) => {
                                        return <option value={val.Codigo} key={key}>{val.Apelido}</option>
                                    })}
                                </select>

                                <label htmlFor="turma">Turma</label>
                                <select name="turma" onChange={onChangeTurma} value={valorTurma} id="turma" ref={register} required={true}>
                                    <option value={4444} key='TurmaBranco'> </option>
                                    {turmaFiltro.map((val, key) => {
                                        return <option value={val} key={key}>{val}</option>
                                    })
                                    }
                                </select>

                                <label htmlFor="data">Data</label>
                                <input type="date" id="data" value={data} min={`${anoPermissao}-01-01`} max={anoMaximo} onChange={handleDate} name="data" ref={register} required={true} />

                                <label htmlFor="aulas">Número de aulas</label>
                                <div className="aulasContainer">
                                    <input type="number" disabled={conteudoBloqueado} min='0' max='6' maxLength='1' onChange={onChangeAulas} value={valorAula} name="aulas" ref={register} required={true} />
                                    <div className="aulaCheckContainer">
                                        <input type="checkbox" id="aulasEdit" checked={isChecked} onChange={onChangeEditarAula} disabled={deleteIsInvisible} />
                                        <div className="editarCheckText">Editar</div>
                                    </div>
                                </div>

                                <label htmlFor="conteudo" >Conteúdo</label>
                                <div id="conteudoContainer">
                                    <textarea type="text" ref={register} id="conteudo" cols="30" rows="5"
                                        disabled={conteudoBloqueado}
                                        placeholder={consultaConteudo.length !== 0 ? consultaConteudo[0].descricao : ''}
                                        name="conteudo" maxLength="500" onChange={onChangeConteudo}
                                        value={valorConteudo} required={true} />

                                    <input id="colar" type="button" name="colar" onClick={() => { colarConteudo(conteudoPush) }} value=" " />
                                </div>
                                <label id='TotalTextoDiarioLancar'>{`${valorConteudo.length}/500`}</label>


                                <label htmlFor="atividade-aula">Atividades da aula</label>
                                <div id="conteudoContainer">
                                    <textarea name="atividade_aula" id="atividade_aula"
                                        disabled={conteudoBloqueado}
                                        placeholder={consultaConteudo.length !== 0 ? consultaConteudo[0].atividade_aula : ''}
                                        cols="30" rows="5" onChange={onChangeAtividadeAula} value={valorAtividadeAula} ref={register} />

                                    <input id="colar" type="button" name="colar" onClick={() => { colarAtividadeAula(conteudoPush) }} value="." />
                                </div>
                                <label id='TotalTextoDiarioLancar'>{`${valorAtividadeAula.length}/255`}</label>

                                <label htmlFor="atividade_casa">Atividades de Casa</label>
                                <div id="conteudoContainer">

                                    <textarea name="atividade_casa" id="atividade_casa"
                                        placeholder={consultaConteudo.length !== 0 ? consultaConteudo[0].atividade_casa : ''}
                                        disabled={conteudoBloqueado}
                                        cols="30" rows="5" onChange={onChangeAtividadeCasa} value={valorAtividadeCasa} ref={register} />
                                    <input id="colar" type="button" name="colar" onClick={() => { colarAtividadeCasa(conteudoPush) }} value="." />
                                </div>
                                <label id='TotalTextoDiarioLancar'>{`${valorAtividadeCasa.length}/255`}</label>

                                <label htmlFor="Observacao" id="observacao">
                                    {
                                        conteudoBloqueado ?
                                            'Está aula está disponível apenas para visualização.'
                                            :
                                            <></>
                                    }

                                </label>

                                <details open={detailsIsOpen}>
                                    <summary onClick={onClickAlunos} ref={register}>Alunos</summary>

                                    <table>
                                        <tbody>
                                            <tr>
                                                <th id="matriculaTitleGridDiario">Matrícula</th>
                                                <th id="nomeTitleGridDiario">Nome</th>
                                                <th id="faltasTitleGridDiario">Faltas</th>
                                                <th id="lancadosTitleGridDiario"
                                                    title="Caso apareça S/R = Sem faltas lançadas para a data e turma informados">Lançados</th>
                                            </tr>
                                            {
                                                /**
                                                * @type {[[GetAlunosReturn], Function]}
                                                */
                                                alunosPermitidos?.map((val, key) => {
                                                    return (
                                                        <tr key={`col_${key}`}>
                                                            <td key={key} ref={register} id="matriculaFieldGridDiario">{val?.Matricula}</td>
                                                            <td key={val?.Nome} id="nomeFieldGridDiario">{val?.Nome}</td>
                                                            <td className="Checkbox" id="checkBoxContainerDiario">
                                                                {camposFaltas(valorAula, val)}
                                                            </td>
                                                            <td className="RegistroFaltas" id="faltasFieldGridDiario">
                                                                {

                                                                    faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)[0] !== undefined ?
                                                                        faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)[0]?.faltas :
                                                                        'S/R'
                                                                }
                                                            </td>
                                                        </tr>
                                                    )

                                                }
                                                )
                                            }
                                        </tbody>

                                    </table>
                                </details>
                                <label id="TotalAlunosDiarioLancar">
                                    Total atuais: {alunosPermitidos?.length}
                                </label>

                                {
                                    conteudoBloqueado ?
                                        <></>
                                        :
                                        <div>
                                            <input type="submit"
                                                name="button"
                                                className={`button ${lancarButtonLoadingClass}`}
                                                id="confirmar"
                                                value={`${lancarButtonText}`}
                                                onClick={handleSubmit(onSubmit)}
                                                ref={register}
                                                disabled={buttonDisable}
                                            />

                                            { // Permissao para excluir dados
                                                permissaoExcluirDados === '0' ? <></> :
                                                    <input type="submit"
                                                        name="button"
                                                        className={`button ${excluirButtonLoadingClass}`}
                                                        id="deletar"
                                                        value={`${excluirButtonText}`}
                                                        onClick={handleSubmit(onDelete)}
                                                        ref={register}
                                                        disabled={deleteIsInvisible}
                                                    />
                                            }
                                        </div>

                                }
                            </div>
                        </form>
                    </section>
                    {cursoFilter.length === 0 || disciplinaFilter.length === 0 ? <ScaleLoader color={color} loading={loading} css={override} height={50} width={8} /> :
                        null}
                    <ScaleLoader color={color} loading={loading} css={override} height={50} width={8} />
                </div>
            </div>
        );

    } else {
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace(window.location.origin)
    }

}