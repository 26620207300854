import Axios from 'axios'

export default async function UpdateDadosUsuario(BaseUrl, Senha, idUsuario, CodCurso){
    let resultado = await Axios.put(`${BaseUrl}/updateDadosUsuario`, {
        Senha: Senha,
        CodCurso: CodCurso,
        IdUsuario: idUsuario
    })

    return resultado.data
}