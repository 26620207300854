import './Login.css';
import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import GetUsers from '../../../services/GetUsers';
import Clientes from '../../../Functions/Clientes'
import OrdenarItens from '../../../Functions/OrdenarItens'
import SelectAPI from '../../../Functions/SelectApi'
import AuthUser from '../../../services/AuthUser'
import Config from '../../../services/config'

let baseApi = ''
let globalUser = ''

toast.configure()

function LoginAdmin() {
  const { register, handleSubmit } = useForm()
  const [input, setInput] = useState('');
  const [users, setUsers] = useState([])
  const [valorUser, setValorUser] = useState('')
  const [ordemClientes, setOrdemClientes] = useState([])
  const [loading, setLoading] = useState(false)
  const [btnEnterLoading, setBtnEnterLoading] = useState('')
  const [valorEscola, setValorEscola] = useState(localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola')
  const [isActive, setIsActive] = useState('')
  const logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'

  //NOTIFICAÇÕES
  function notifyErrorLoginWrongPass() {
    toast.error('Senha incorreta', { autoClose: 3500, position: toast.POSITION.TOP_CENTER });
  }

  function notifyInfoLoginMissingUser() {
    toast.info('Favor selecionar usuário', { autoClose: 3500, position: toast.POSITION.TOP_CENTER });
  }

  function notifyInfoLoginMissingSchool() {
    toast.info('Favor selecionar escola', { autoClose: 3500, position: toast.POSITION.TOP_CENTER });
  }

  async function getInfo() {
    const infoResult = await Config(baseApi)

    if (infoResult !== undefined) {
      const Ano = infoResult[0]?.Ano
      const Status = infoResult[0]?.Status
      localStorage.setItem('Year', Ano)
      setIsActive(Status)
    }
  }

  async function getUsers() {
    const usersResult = await GetUsers(baseApi)
    setUsers(usersResult)
  }

  useEffect(() => {
    baseApi = SelectAPI(valorEscola)
    //Ordenar Escolas (Clientes)
    const Ordem = OrdenarItens(Clientes, 'Nome')
    setOrdemClientes(Ordem)
    //Consultar Usuários
    if (valorEscola !== 'escola' && valorEscola !== '' && valorEscola !== null) {
      getUsers()
      //CONSULTAR SE ESCOLA ESTÁ ATIVA
      getInfo()
    }
  }, [valorEscola])

  const onSubmit = async (dado) => {
    //Validar se escola foi selecionado
    if (valorEscola !== '' && valorEscola !== 'escola') {
      //Validar se usuario foi selecionado
      if (valorUser !== '' && valorUser !== 'usuario') {
        const authentication = await AuthUser(baseApi, dado.usuario, dado.senha)
        //Validar se senha está correta
        if (authentication[0].Status === 'Authenticated' && isActive.toLowerCase() === 'ativo') {
          const { Nome, Admin } = authentication[0]

          setBtnEnterLoading('loadingBtn')
          localStorage.setItem('user', Nome)
          localStorage.setItem('isAdmin', Admin)
          sessionStorage.setItem('idUser', valorUser)
          sessionStorage.setItem('isLoged', true)

          setTimeout(() => {
            window.location.replace('/Admin/Home')
          }, 1000)
        } else {
          notifyErrorLoginWrongPass()
        }
      } else {
        notifyInfoLoginMissingUser()
      }
    } else {
      notifyInfoLoginMissingSchool()
    }

  }

  const onChangeEscola = async (event) => {
    let escola = event.target.value
    console.log('escola: ', escola)
    setValorEscola(escola)
    setLoading('loading')
    localStorage.setItem('escola', escola)

    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const onChangeUser = async (event) => {
    globalUser = event.target.value
    setValorUser(globalUser)
  }

  if (logado === 'true') {
    window.location.replace('/Admin/Home')
    return (<></>)
  } else {
    sessionStorage.clear()

    return (
      <>
        <div className={`containerAdminPainel ${btnEnterLoading}Container`}>

          <div id="headerLoginAdmin">
            <div className="header-infodat-label">
              <h2>ADMINISTRATIVO</h2>
            </div>
          </div>

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* {
          loading !== false ?
            <object type="image/svg+xml" data={LoadingIcon} className="loading">svg-animation</object> :
            null
        } */}

          <form>
            <div className="professor-form-card">
              {/* <object type="image/svg+xml" data={NotePadIcon} className="icon-svg ">svg-animation</object> */}
              <img className="icon-svg " src="https://img.icons8.com/external-wanicon-flat-wanicon/75/000000/external-employee-business-model-canvas-wanicon-flat-wanicon.png" />

              <h1>INFODAT</h1>
              <select name="escolas" id="escolas" onChange={onChangeEscola} value={valorEscola} required ref={register}>
                <option value='escola' key='escola' id='escolaOption'>Escola</option>
                {
                  ordemClientes.map((val, key) => {
                    return (
                      <option value={val.ClienteValue} key={key}>{val.Nome}</option>
                    )
                  })
                }
              </select>

              <select name="usuario" id="usuarios" onChange={onChangeUser} value={valorUser} required ref={register}>
                <option value='usuario' key='usuario' id='usuarioOption'>Usuário</option>
                {
                  users.map((val, key) => {
                    return (
                      <option value={val.Id} key={key}>{val.Nome}</option>
                    )
                  })
                }
              </select>

              <br />

              <input className="password" onChange={e => setInput(e.target.value)} name="senha" type="password" placeholder="Senha" required ref={register} />

              <br />

              <div id='PainelAdministrativoLink' >
                <a onClick={() => window.location.replace('/')} >
                  Portal do Professor 👨‍🎓
                </a>
              </div>

              <a onClick={handleSubmit(onSubmit)}>
                <input name="button" className={`buttonEnter ${btnEnterLoading}`} type="submit" value="ENTRAR" required ref={register} />
              </a>

            </div>
          </form>

          {/* <footer></footer> */}
        </div>
      </>
    );
  }
}

export default LoginAdmin;