import React from 'react'
import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import './CalcularAux.css'


export default function CalcularAux() {

    return (
        <>
            <div id='HomePageGeneralContainer'>
                <SideBar />
                <div id='HomePageContainer'>
                    <Header />

                    <div id="CalcularAuxTitlePage">
                        Ferramentas Complementares / Calcular Auxiliares
                    </div>


                    <div className="CalcularAuxPainelContainer">
                        <h2>Calcular Auxiliares</h2>
                    </div>

                </div>
            </div>
        </>
    )
}