import '../Types/TypesServices'
import Axios from 'axios'


/** @returns {[GetQualitativasPorAluno]} */

export default async function GetQualitativasPorAluno(BaseUrl, chave, matricula){
    const qualitativas = await Axios.post(`${BaseUrl}/notas/getQualitativasPorAluno`, {
        chave: chave,
        matricula: matricula
    })
    return qualitativas.data
}