import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import SelectAPI from '../../../Functions/SelectApi'
import './Exportar.css'

export default function Exportar() {
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    const baseUrl = SelectAPI(valorEscola)

    

    if(logado !== 'true'){
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='ProfessoresGeneralDashContainer'>
                    <SideBar />
                    <div className='ProfessoresDashContainer'>
                        <Header />
                        <div id="professoresTitlePage">
                            EXPORTAR
                        </div>
    
    
                    </div>
                </div>
            </>
        )
    }
}