import './Diario.css'
import React from 'react';
import Header from '../../components/header'
import { Link } from 'react-router-dom';

export default function Diario(){

    let logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'

    // function lancarClick(){
    //     //document.location.href = local + 'Lancar';
    // }

    // function gerarClick(){
    //     document.location.href = local + 'Gerar';
    // }

    if(logado == 'true'){
        return(
            <div >
                <Header />
                <div className="container">
                    <section>
                        <div className="title" id="titleDiario">
                            <p className="title-page">Diário</p>
                            <p className="subtitle-page">{localStorage.getItem('escola').toUpperCase()}</p>
                        </div>
                        <div className="container-informativo">
                            <p className="informativo-title">INFORMATIVO</p>
                            <p className="informativo-desc">Digite nesta ferramenta as suas atividades de aula para a geração do Diário Escolar e também para consulta dos pais e alunos pela Internet. Para começar, clique em Adicionar atividade. Para consultar o Diário de uma determinada unidade, clique em Gerar Diário.</p>
                        </div>
                        <div className="container-buttons" id="containerButtonsDiario">
                            <Link to="/Diario/Lancar">
                                <input className="lancarButton" name="lancar" id="lancar" type="submit" value="Lançar Diário"/>
                            </Link>
                            <Link to="/Diario/Gerar">
                                <input className="gerarButton" name="gerar" id="gerar" type="submit" value="Gerar Diário"/>
                            </Link>
                        </div>
                    </section>
    
                    <footer>
                        <div></div>
                    </footer>
                </div>
            </div>
        );
    } else {
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace(window.location.origin)
    }
     
}