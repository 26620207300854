import Axios from 'axios'

export default async function PostArredondarDecimal(BaseUrl, todasNotas, avaliacao, curso, turma, disciplina){
    let resultado = await Axios.post(`${BaseUrl}/notas/arredondarDecimal`, {
        todasNotas: todasNotas,
        avaliacao: avaliacao,
        curso: curso,
        turma: turma,
        disciplina: disciplina
    })

    return resultado.data  
}