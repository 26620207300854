import Axios from 'axios'

export default async function GetAlunosFaltas(BaseUrl, curso, turma, disciplina, data, cliente){ 
    console.log('GetAlunosFaltas', {BaseUrl: BaseUrl, curso: curso, turma: turma, disciplina: disciplina, data: data, cliente: cliente})
    
    let alunos = await Axios.post(`${BaseUrl}/getAlunosFaltas?escola=${cliente}`, {
        curso: curso,
        turma: turma,
        disciplina: disciplina,
        data: data
    })

    

    return alunos.data
}