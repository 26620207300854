import React from 'react'
import './ButtonConfirm.css'

export default function ButtonConfirm({Title, Action}){
    async function onClickButton(){
        // ClickButton()
    }
    const ClickButton = Action


    return(
        <button id="ButtonConfirmDash" onClick={ClickButton}>
            {Title}        
        </button>
    )
}