import React from 'react'
import './ButtonBack.css'

export default function ButtonBack({Title}){

    return(
        <div id="ButtonBackDash">
            {Title}        
        </div>
    )
}