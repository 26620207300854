import { useEffect, useState } from 'react'
import GetAlunosOptativas from '../services/GetAlunosOpByDisciplina'

const { ApiFilter } = require('../Functions/FilterApiDomain')

export default function useBasicForm(
    selectedCurso,
    selectedDisciplina,
    selectedTurma
) {
    const [avaliacoes, setAvaliacoes] = useState([])
    const [avaliacoesNotasFiltro, setAvaliacoesNotasFiltro] = useState([])
    const [turmasFiltro, setTurmasFiltro] = useState([])
    const [disciplinasFiltroForm, setDisciplinaFiltro] = useState([])
    const [alunosFiltro, setAlunosFiltro] = useState([])
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const baseUrl = ApiFilter(sub)
    const [cursos, setCursos] = useState([])
    const [turmas, setTurmas] = useState([])
    const [grades, setGrades] = useState([])
    const [alunos, setAlunos] = useState([])

    let alunosPermitidosGlobalArr

    const pesquisarDadosForm = (cursos, turmas, disciplinas, grades) => {
        setCursos(cursos)
        setTurmas(turmas)
        setAlunos(disciplinas)
        setGrades(grades)
    }

    const changeCursoForm = (selectedCurso) => {
        filtroDisciplina(selectedCurso)
        filtroTurmas(selectedCurso)
        let alunos = []

        if (turmasFiltro.length > 0) {
            alunos = filtroAlunos(selectedCurso, selectedTurma)
        }
        return alunos
    }

    const useOnChangeTurma = (selectedTurma) => {
        filtroAlunos(selectedCurso, selectedTurma)
    }

    const filtroDisciplina = (selectedCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === selectedCurso)

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        // dadosDisciplina.push({CodDisciplina: '888', Disciplina: 'POLIVALENTE'})
        dadosDisciplina.push({ CodDisciplina: '666', Disciplina: 'TODOS' })
        // dadosDisciplina.push({ CodDisciplina: '', Disciplina: 'POLIVALENTE' })

        setDisciplinaFiltro(dadosDisciplina)
    }

    const filtroTurmas = (valorCurso) => {
        const findTurma = turmas.filter(item => item.Curso === valorCurso)
        setTurmasFiltro(findTurma)
    }

    const filtroAlunos = (valorCurso, valorTurma) => {
        const findAlunos = alunos.filter(item => item.Curso === valorCurso && item.Turma === valorTurma)
        const alunosPermitidosNomeOrdem = findAlunos.map((item) => item.Nome).sort()
        const alunosPermitidosPush = []

        for (const valor of alunosPermitidosNomeOrdem) {
            alunosPermitidosPush.push(findAlunos.find((item) => item.Nome === valor))
        }

        alunosPermitidosGlobalArr = alunosPermitidosPush
        setAlunosFiltro(alunosPermitidosPush)
        return alunosPermitidosPush
    }

    const filtroSistemaAvaliacao = (cursos, avaliacoes) => {
        const sistemaAvaliacao = cursos.filter(item => item.CodCurso === selectedCurso)[0]?.Sistema
        const avaliacoesFiltro = avaliacoes?.filter(item => item?.Sistema === sistemaAvaliacao)

        const avaliacoesNotas = avaliacoesFiltro?.filter((item) => {
            const find = item.Codigo

            if (find.search('N') !== -1) {
                return item
            }
        })
        setAvaliacoesNotasFiltro(avaliacoesNotas)
    }

    useEffect(() => {
        if (selectedCurso !== '') {
            filtroSistemaAvaliacao(cursos, avaliacoes)
        }
    }, [selectedCurso])

    useEffect(async () => {
        if (selectedDisciplina !== 666) {
            const alunosOp = await GetAlunosOptativas(baseUrl, selectedDisciplina)
            console.log('alunos: ', alunosFiltro)
            // console.log('alunosOp: ', alunosOp)

            if (alunosOp?.length > 0 && alunosOp?.length !== undefined) {
                const resultadoFinal = alunosFiltro?.filter(aluno => alunosOp.filter(alunoArr => alunoArr.Matricula === aluno.Matricula).length)
                setAlunosFiltro(resultadoFinal)
                console.log('OpFiltro: ', resultadoFinal)
            }
        }
    }, [selectedDisciplina])

    return {
        alunosFiltro,
        turmasFiltro,
        disciplinasFiltroForm,
        avaliacoesNotasFiltro,
        changeCursoForm,
        useOnChangeTurma,
        pesquisarDadosForm
    }
}