export const Meses = [
    { id: 1, Mes: 'JANEIRO' },
    { id: 2, Mes: 'FEVEREIRO' },
    { id: 3, Mes: 'MARÇO' },
    { id: 4, Mes: 'ABRIL' },
    { id: 5, Mes: 'MAIO' },
    { id: 6, Mes: 'JUNHO' },
    { id: 7, Mes: 'JULHO' },
    { id: 8, Mes: 'AGOSTO' },
    { id: 9, Mes: 'SETEMBRO' },
    { id: 10, Mes: 'OUTUBRO' },
    { id: 11, Mes: 'NOVEMBRO' },
    { id: 12, Mes: 'DEZEMBRO' },
]