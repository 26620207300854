import React, { useState, useEffect } from 'react'
import './CardGreen.css'
import School from '@material-ui/icons/School';
import CountUp from 'react-countup'
import GetAlunos from '../../services/GetAlunos';
const { ApiFilter } = require('../../Functions/FilterApiDomain')

export default function CardGreen() {

    const [alunos, setAlunos] = useState([])
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const baseUrl = ApiFilter(sub)

    async function Alunos() {
        setAlunos(await GetAlunos(baseUrl))
    }

    useEffect(() => {
        Alunos()
    }, [])

    return (
        <div className='CardDashConteinerGreen'>
            <div className='CardDashInfo'>
                <div id='NumberCardDash'>
                    <CountUp end={alunos.length} />
                </div>

                <School id="GroupIcon" />
            </div>
            <div id='DescriptionDashCard'>Alunos Ativos</div>
        </div>
    )
}