import './ArredondarNotas.css'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Backdrop, CircularProgress } from '@mui/material';
import Modal from 'react-modal';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import FormBasicAdmin from '../../../components/FormBasic/FormBasic'
import ButtonAvaliacao from '../../../components/ButtonAvaliacao/ButtonAvaliacao'

import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetGrades from '../../../services/GetGrades'
import GetTurmas from '../../../services/GetTurmas'

import FilterCursosByGrades from '../../../Functions/FilterCursosByGrades'
import PostArredondarDecimal from '../../../services/PostArredondarDecimal'
const { ApiFilter } = require('../../../Functions/FilterApiDomain')

Modal.setAppElement('#root')


export default function ArredondarNotas() {
    const [checkTodasNotas, setCheckTodasNotas] = useState(false)

    const [cursos, setCursos] = useState([])
    const [turmas, setTurmas] = useState([])
    const [grades, setGrades] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [msgResult, setMsgResult] = useState([])

    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [styleLabelTodasAsNotas, setStyleLabelTodasAsNotas] = useState('todasAsNotasDisabled')


    const { register, handleSubmit, errors } = useForm()
    const baseUrl = ApiFilter(sub)

    const closeModal = () => {
        setModalIsOpen(!modalIsOpen)
        window.location.reload()
    }

    //APIs
    const getGrades = async () => {
        const gradesResult = await GetGrades(baseUrl)
        setGrades(gradesResult)
        return gradesResult
    }

    const getTurmas = async () => {
        const turmaResult = await GetTurmas(baseUrl)
        setTurmas(turmaResult)
        return turmaResult
    }

    const getAvaliacoes = async () => {
        const avaliacoesResult = await GetAvaliacoes(baseUrl)
        setAvaliacoes(avaliacoesResult)
        return avaliacoesResult
    }


    //Process Functions
    const onChangeCheckTodasNotas = () => {
        setCheckTodasNotas(!checkTodasNotas)
    }

    useEffect(async () => {
        const promiseAll = await Promise.all(
            [
                getGrades(baseUrl),
                getTurmas(baseUrl),
                getAvaliacoes(baseUrl)
            ])

        const gradesReturn = await promiseAll[0]
        const filterCursos = FilterCursosByGrades(gradesReturn)
        setCursos(filterCursos)
    }, [])

    useEffect(() => {
        if (!checkTodasNotas) {
            setStyleLabelTodasAsNotas('todasAsNotasDisabled')
        } else {
            setStyleLabelTodasAsNotas('')
        }
    }, [checkTodasNotas])

    //Handle Data
    const onSubmit = async (dados) => {
        setOpen(true)

        const avaliacao = JSON.parse(dados.avaliacao).Codigo
        const curso = dados.curso === undefined ? '' : dados.curso
        const turma = dados.turma === undefined ? '' : dados.turma
        const disciplina = dados.disciplina === undefined ? '' : dados.disciplina

        console.log({ av: avaliacao, curso: curso, turma: turma, disciplina: disciplina })

        if (checkTodasNotas) {
            const arredondarResult = await PostArredondarDecimal(baseUrl, checkTodasNotas, avaliacao, curso, turma, disciplina)
            console.log('Arredondar: ', arredondarResult)
            setMsgResult(arredondarResult)

            if (arredondarResult.Status === 200) {
                // window.alert(arredondarResult.Msg)
            } else {
                window.alert('Não foi possível arredondar decimais')
            }

            // window.location.reload()

        } else {
            // Validar se foi selecionado os campos obrigatórios
            if (curso !== 'Todos') {
                if (avaliacao !== '' && curso !== '' && turma !== '' && disciplina !== '') {
                    const arredondarResult = await PostArredondarDecimal(baseUrl, checkTodasNotas, avaliacao, curso, turma, disciplina)
                    setMsgResult(arredondarResult)
                    console.log('Arredondar: ', arredondarResult)

                    if (arredondarResult.Status === 200) {
                        // window.alert(arredondarResult.Msg)
                    } else {
                        window.alert('Não foi possível arredondar decimais')
                    }

                    // window.location.reload()

                } else {
                    window.alert('Faltam campos para serem selecionados')
                }

            } else {
                const arredondarResult = await PostArredondarDecimal(baseUrl, checkTodasNotas, avaliacao, curso, turma, disciplina)
                setMsgResult(arredondarResult)
                console.log('Arredondar: ', arredondarResult)
                console.log('Msg: ', msgResult.Msg)

                if (arredondarResult.Status === 200) {
                    // window.alert(arredondarResult.Msg)
                } else {
                    window.alert('Não foi possível arredondar decimais')
                }

                // window.location.reload()
            }
        }

        console.log('MsgResult: ', msgResult)

        setModalIsOpen(!modalIsOpen)
        setOpen(false)
    }

    useEffect(() => {
        console.log('MsgResultEff: ', msgResult)
    }, [msgResult])

    return (
        <>
            <div id='HomePageGeneralContainer'>
                <SideBar />
                <div id='HomePageContainer'>
                    <Header />

                    <div id="ArredondarNotasTitlePage">
                        Arredondar Notas
                    </div>

                    <div className="ArredondarNotasPainelContainer">
                        {/* <h2>ARREDONDAR NOTAS</h2> */}

                        <div id="ArredondarNotasOptionsContainer">

                            <div id="ArredondarNotasTodasContainer">
                                <input type='checkbox' value={checkTodasNotas} onClick={onChangeCheckTodasNotas} />
                                <label className={styleLabelTodasAsNotas}>TODAS AS NOTAS</label>
                            </div>

                            <hr />

                            <FormBasicAdmin
                                grades={grades}
                                cursos={cursos}
                                turmas={turmas}
                                avaliacoes={avaliacoes}
                                disabled={checkTodasNotas}
                                cursoTodos={false}
                                register={register}
                            />

                            <div>
                                <label>AVALIAÇÃO <span style={{ opacity: 0.6, marginLeft: '0.3em', color: 'red' }}>(Obrigatório)</span> </label>
                                <ButtonAvaliacao apenasAvaliacao={false} disabled={false} register={register} />
                            </div>

                            <Backdrop
                                sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                                <p>Processando...</p>
                            </Backdrop>

                            {
                                msgResult.Msg !== undefined ?
                                    <Modal
                                        closeTimeoutMS={200}
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        contentLabel='Arredondar Notas Decimais'
                                        overlayClassName='modal-overlay'
                                        className='modal-content-notas-aux'
                                    >
                                        <h2>
                                            <div id="TitleModalDiarioDados">
                                                Processamento finalizado
                                            </div>
                                        </h2>

                                        <hr />
                                        <p>
                                            <div style={
                                                {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: msgResult.Status === 200 ? 'green' : 'orangered',
                                                    marginBottom: '-5px',
                                                }
                                            }>
                                                {
                                                    msgResult.Status === 200 ?
                                                        <CheckCircleIcon style={{ color: 'green', marginRight: '5px' }} />
                                                        :
                                                        <ErrorIcon style={{ color: 'orange', marginRight: '5px' }} />
                                                }
                                                {msgResult.Msg}
                                            </div>
                                        </p>

                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                                            <button id='ButtonModalStudent' onClick={closeModal}>
                                                FECHAR
                                            </button>
                                        </div>
                                    </Modal>
                                    :
                                    <></>
                            }

                            <div id="ArredondarNotasButtonContainer">
                                <input type='submit' id="ProcessarArredondarNotasBtn" value={"PROCESSAR"} onClick={handleSubmit(onSubmit)} />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}