import React, { useState, useEffect } from 'react'
import Edit from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import CheckIcon from '@material-ui/icons/Check'
import GetCursos from '../../services/GetCursos'
import CreateUsuario from '../../services/PostCreateUsuario'
import ButtonConfirm from '../ButtonConfirm/ButtonConfirm'
import ButtonDisabled from '../ButtonDisabled/ButtonDisabled'
import SelectAPI from '../../Functions/SelectApi'
import ButtonBack from '../ButtonBack/ButtonBack'
import TabelaPermissaoUsuario from '../TabelaPermissaoUsuario/TabelaPermissaoUsuario'
import TabelaPermissaoNovoUsuario from '../TabelaPermissaoNovoUsuario/TabelaPermissaoNovoUsuario'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './UsuariosNovoDados.css'

export default function UsuariosDados({ title, tableShow = false }) {
    const [senhaValue, setSenhaValue] = useState('')
    const [senhaConfirmarValue, setSenhaConfirmarValue] = useState('')
    const [digitacao, setDigitacao] = useState()
    const [user, setUser] = useState([])
    const [nomeValue, setNomeValue] = useState('')
    const [cursos, setCursos] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [dadosUsuario, setDadosUsuario] = useState(JSON.parse(sessionStorage.getItem('p')))
    const [btnEditIsDisabled, setBtnEditIsDisabled] = useState('')
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const isAdmin = localStorage.getItem('isAdmin') !== null ? localStorage.getItem('isAdmin') : ''
    const [showTable, setShowTable] = useState(tableShow)
    const baseUrl = SelectAPI(valorEscola)
    const { register, handleSubmit, errors } = useForm()

    //NOTIFICAÇÕES
    function notifyErro(text) {
        toast.error(text, { autoClose: 10000, position: toast.POSITION.TOP_CENTER });
    }

    //NOTIFICAÇÕES
    function notifySucess(text) {
        toast.success(text, { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }

    const columnsTitle = [
        "CURSOS"
    ]

    const bodyFields = [
        "Curso"
    ]

    async function getCursos() {
        const cursos = await GetCursos(baseUrl)
        setCursos(value => value = cursos)
    }

    useEffect(() => {
        getCursos()
        isAdmin === '1' ? setBtnEditIsDisabled('disabledBtnEdit') : setBtnEditIsDisabled('')
    }, [])

    const onChangeEditSenha = (event) => {
        let inputValue = event.target.value
        setSenhaValue(value => value = inputValue)
    }

    const onChangeEditConfirmarSenha = (event) => {
        let inputValue = event.target.value
        setSenhaConfirmarValue(value => value = inputValue)
    }

    const onChangeEditNome = (event) => {
        let inputValue = event.target.value
        setNomeValue(value => value = inputValue)
    }

    async function onSave(dado) {
        let codigoCursos = ''

        if (window.confirm('Confirma salvar dados?')) {
            //Converter dados para codigo de permissao de curso Ex: "001;002;003"
            setIsLoading(value => value = true)
            for (const value in dado) {
                if (dado[value]) {
                    codigoCursos += value + ';'
                }
            }

            try {
                let resultadoDados = await CreateUsuario(baseUrl, nomeValue,senhaValue, codigoCursos)
                notifySucess('Usuario criado com sucesso')

                setTimeout(() => {
                    window.location.replace('/Admin/Usuarios')
                }, 4000)
            } catch (error) {
                notifyErro('Não foi possível criar o usuário. Favor tentar novamente mais tarde')

                setTimeout(() => {
                    window.location.replace('/Admin/Usuarios')
                }, 10000)
            }
        }
    }

    const btnShow = () => {
        if (isLoading === false) {
            if (nomeValue === '' || senhaValue === '' || senhaConfirmarValue !== senhaValue) {
                return <ButtonDisabled Title="SALVAR" isLoading={false} className="linkBtn" cursorLoadind={false} />
            } else {
                return <ButtonConfirm Action={handleSubmit(onSave)} Title="SALVAR" className="linkBtn" />
            }
        } else {
            return <ButtonDisabled Title="AGUARDE" isLoading={true} cursorLoadind={true} className="linkBtn" />
        }
    }

    return (
        <div className='UsuarioNovoDadosContainer'>
            <div className='UsuarioNovosDadosDashContainer'>
                <div className='UsuarioNovoDadosDashBarraPesquisa'>
                    {title}
                </div>

                <div className="UsuarioNovoDadosBasicInfoContainer">
                    <div className='UsuarioNovoDadosIDContainer TextDescricao'>
                        ID
                        <input disabled={true} value={'000'} id="InputId" />
                    </div>
                    <div className='UsuarioNovoDadosNomeContainer TextDescricao'>
                        NOME
                        <div id='UsuarioNovoDadosNomeContainerEdit'>
                            <input value={nomeValue} onChange={onChangeEditNome} id="InputNome" />
                            {
                                nomeValue === '' ?
                                    <div id="infoIconNovoUsuario" title="Preenchimento obrigatório">
                                        <InfoIcon id="infoIcon"/>
                                    </div>
                                    :
                                    <div id="checkIconNovoUsuario" >
                                        <CheckIcon id="checkIcon"/>
                                    </div>
                            }
                            <Edit />
                        </div>
                    </div>
                    <div className='UsuarioNovoDadosStatusContainer TextDescricao' >
                        STATUS
                        <input disabled={true} value="ATIVO" id='InputStatus' />
                    </div>
                </div>

                <div>
                    <div className='UsuarioNovoDadosSenhaContainerGeral'>
                        <div className='UsuarioNovoDadosSenhaContainer TextDescricao'>
                            SENHA
                            <div className={`SenhaInfoContainer ${btnEditIsDisabled}`} >


                                {
                                    isAdmin === '1' ?
                                        <>
                                            <input onChange={onChangeEditSenha} type="password" value={senhaValue} class={`InputSenhaEditarSenha `} placeholder='' />
                                            {
                                                senhaValue == '' ?
                                                    <div id="infoIconNovoUsuario" title="Preenchimento obrigatório">
                                                        <InfoIcon id="infoIcon"/>
                                                    </div>
                                                    :
                                                    <div id="checkIconNovoUsuario" >
                                                        <CheckIcon id="checkIcon"/>
                                                    </div>
                                            }
                                            <Edit id="EditIconSenha" />
                                        </>
                                        :
                                        <>
                                            <input onChange={onChangeEditSenha} type="password" disabled value={senhaValue} class={`InputSenhaEditarSenha `} placeholder='******' />
                                        </>
                                }


                            </div>
                        </div>
                        <div className='UsuarioNovoDadosConfirmarSenhaContainer TextDescricao'>
                            CONFIRMAR SENHA
                            <div className={`SenhaInfoContainer ${btnEditIsDisabled}`} >


                                {
                                    isAdmin === '1' ?
                                        <>
                                            <input onChange={onChangeEditConfirmarSenha} type="password" value={senhaConfirmarValue} class={`InputSenhaEditarSenha `} placeholder='' />
                                            {
                                                senhaValue !== senhaConfirmarValue || senhaConfirmarValue === '' ?
                                                    <div id="infoIconNovoUsuario" title="Precisa ser igual a senha informada">
                                                        <InfoIcon id="infoIcon"/>
                                                    </div>
                                                    :
                                                    <div id="checkIconNovoUsuario" >
                                                        <CheckIcon id="checkIcon"/>
                                                    </div>
                                            }
                                            <Edit id="EditIconSenha" />
                                        </>
                                        :
                                        <>
                                            <input onChange={onChangeEditConfirmarSenha} type="password" disabled value={senhaConfirmarValue} class={`InputSenhaEditarSenha `} placeholder='******' />
                                        </>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='PermissaoDivDescription'>
                PERMISSÕES
            </div>

            {
                showTable === true ?
                    <TabelaPermissaoNovoUsuario
                        bodyDataRows={cursos}
                        headerTableTitles={columnsTitle}
                        bodyTableFields={bodyFields}
                        registerForm={register}
                        emptyFields={true}
                    /> :
                    <></>
            }

            <div className='UsuarioNovoDadosButtonsContainer'>
                {
                    isAdmin === '1' ?
                        btnShow() :
                        <>&nbsp; &nbsp; &nbsp; &nbsp;</>
                }
                <Link to='/admin/Usuarios' className="linkBtn">
                    <ButtonBack Title="VOLTAR" />
                </Link>
            </div>
        </div>
    )
}