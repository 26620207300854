import ButtonAvaliacao from '../ButtonAvaliacao/ButtonAvaliacao'
import './FormFaltasBasic.css'
import React, { useEffect, useState } from 'react'


export default function FormFaltasBasic({ grades, cursos, turmas, register, avaliacoes, disabled, cursoTodos }) {
    const [valorCurso, setValorCurso] = useState('')
    const [valorDisciplina, setValorDisciplina] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorAvaliacao, setValorAvaliacao] = useState('')

    const [disabledDisciplina, setDisabledDisciplina] = useState(disabled)
    const [disabledTurma, setDisabledTurma] = useState(disabled)
    const [checkTodasNotas, setCheckTodasNotas] = useState(false)

    const [turmasFiltro, setTurmasFiltro] = useState([])
    const [disciplinaFiltro, setDisciplinaFiltro] = useState([])
    const [avaliacoesNotasFiltro, setAvaliacoesNotasFiltro] = useState([])
    const anoPermitido = localStorage.getItem('Year')

    //Process Functions
    const onChangeCheckTodasNotas = () => {
        setCheckTodasNotas(!checkTodasNotas)
    }

    const onChangeCurso = (event) => {
        setValorCurso(event.target.value)

        if (event.target.value !== 'Todos') {
            filtroDisciplina(event.target.value)
            filtroTurmas(event.target.value)
        }
    }

    useEffect(() => {
        console.log('Disabled: ', disabled)
        if (disabled === true) {
            setValorCurso('')
            setValorTurma('')
            setValorDisciplina('')
            setValorAvaliacao('')
        }

        setDisabledDisciplina(disabled)
        setDisabledTurma(disabled)
    }, [disabled])

    const onChangeDisciplina = (event) => {
        setValorDisciplina(event.target.value)
    }

    const onChangeTurma = (event) => {
        setValorTurma(event.target.value)
    }

    const filtroDisciplina = (valorCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === valorCurso)

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        dadosDisciplina.push({ CodDisciplina: '666', Disciplina: 'TODOS' })
        setDisciplinaFiltro(dadosDisciplina)
    }

    console.log('DisciplinasFiltro: ', disciplinaFiltro)

    const filtroTurmas = (valorCurso) => {
        const findTurma = turmas.filter(item => item.Curso === valorCurso)
        setTurmasFiltro(findTurma)
    }

    const filtroSistemaAvaliacao = (cursos, avaliacoes) => {
        const sistemaAvaliacao = cursos.filter(item => item.CodCurso === valorCurso)[0].Sistema
        const avaliacoesFiltro = avaliacoes?.filter(item => item.Sistema === sistemaAvaliacao)

        const avaliacoesNotas = avaliacoesFiltro?.filter((item) => {
            const find = item.Codigo

            if (find.search('N') !== -1) {
                return item
            }
        })

        setAvaliacoesNotasFiltro(avaliacoesNotas)
    }

    useEffect(() => {
        if (valorCurso !== '') {
            filtroSistemaAvaliacao(cursos, avaliacoes)
        }

        if (valorCurso !== '' && valorCurso !== 'Todos') {
            setDisabledDisciplina(false)
            setDisabledTurma(false)
            setValorTurma('')
            setValorDisciplina('')

        } else if (valorCurso === 'Todos') {
            setDisabledDisciplina(true)
            setDisabledTurma(true)
            setValorTurma('todos')
            setValorDisciplina('todos')
        }
    }, [valorCurso])


    const onChangeAvaliacao = (event) => {
        const valor = event.target.value
        setValorAvaliacao(valor)
    }

    return (
        <>
            <form id="formTransferirFaltasBasic" action="" method="post">

                <div className="container-form" id="containerFormBasic">
                    <div className='containerFormFirstLine'>
                        <div id="cursoContainerFormBasic">
                            <label className={disabled ? 'disabledLabel' : ''} name="curso">Curso</label>
                            <select disabled={disabled} name="curso" id="curso" value={valorCurso} onChange={onChangeCurso} ref={register} required={true}>
                                <option value={undefined} key='CursoBranco'> </option>
                                {
                                    cursos?.map((val, key) => {
                                        return (
                                            <option value={val.CodCurso} key={key}> {val.Curso} </option>
                                        )
                                    })

                                }
                                {cursoTodos ? <option value={'Todos'} key={999}> TODOS </option> : null}
                            </select>
                        </div>

                        <div>
                            <label className={disabled ? 'disabledLabel' : ''} name="turma">Turma</label>
                            <select disabled={disabledTurma} name="turma" onChange={onChangeTurma} value={valorTurma} id="turma" ref={register} required={true}>
                                {
                                    disabledTurma ?
                                        <option value={'todos'} key='TurmaBranco' > TODOS </option>
                                        :
                                        <option value={undefined} key='TurmaBranco' > </option>
                                }

                                {
                                    turmasFiltro?.map((val, key) => {
                                        return (
                                            <option value={val.Turma} key={key}> {val.Turma} </option>
                                        )
                                    })
                                }

                            </select>
                        </div>
                    </div>

                    {/* DATAS */}
                    <div id="containerDatasFormFaltas">
                        <div>
                            <label>Início</label>
                            <input
                                type='date'
                                name={'dataInicial'}
                                id={'dataInicial'}
                                ref={register}
                                min={`${anoPermitido}-01-01`}
                                max={`${anoPermitido}-12-31`} />
                        </div>
                        <div>
                            <label>Fim</label>
                            <input
                                type='date'
                                name={'dataFinal'}
                                id={'dataFinal'}
                                ref={register}
                                min={`${anoPermitido}-01-01`}
                                max={`${anoPermitido}-12-31`} />
                        </div>
                    </div>

                    <label for="avaliacao">AVALIAÇÃO</label>
                    <select name="avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} id="avaliacao" ref={register} required={true} >
                        <option value={6666} key='AvaliacaoEmBranco'></option>
                        {
                            avaliacoesNotasFiltro?.map((val, key) => {
                                return (
                                    <option value={JSON.stringify(val)} key={key}> {val.Abreviatura} </option>
                                )
                            })
                        }
                    </select>

                    <div id='transferirFaltasDivisorLine'>
                        <hr />
                    </div>

                    <div id="TransferirFaltasPolivalenteTodasContainer">
                        <input type='checkbox' name={'isPolivalente'} value={checkTodasNotas} onClick={onChangeCheckTodasNotas} ref={register} />
                        <label className=''>POLIVALENTE</label>
                    </div>

                    <label className={disabled ? 'disabledLabel' : ''} name="disciplina">Disciplina</label>
                    <select disabled={disabledDisciplina} name="disciplina" onChange={onChangeDisciplina} value={valorDisciplina} id="disciplina" ref={register} required={true} >
                        {
                            disabledDisciplina ?
                                <option value={'todos'} key='DisciplinaBranco'> TODOS </option>
                                :
                                <option value={undefined} key='DisciplinaBranco'> </option>
                        }

                        {
                            disciplinaFiltro?.map((val, key) => {
                                return (
                                    <option value={val.CodDisciplina} key={key}> {val.Disciplina} </option>
                                )
                            })
                        }
                    </select>

                </div>

            </form>
        </>
    )
}