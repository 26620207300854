import React, { useState, useEffect, useRef } from 'react'
import './TabelaPermissaoNovoUsuario.css'

export default function TabelaPermissaoNovoUsuario(
    { bodyDataRows, headerTableTitles, bodyTableFields, bodyDataRowsChecked, registerForm, emptyFields = false }
) {
    const [dataRow, setDataRow] = useState([])
    const [headerTitles, setHeaderTitles] = useState(headerTableTitles)
    const [tableFields, setTableFields] = useState(bodyTableFields)
    const [dataRowChecked, setDataRowChecked] = useState([])
    const [checkedState, setCheckedState] = useState([]);
    let checkArray = []

    useEffect(() => {
        if (bodyDataRows.length > 0) {
            setDataRow(value => value = bodyDataRows)

            if(emptyFields === false){
                setDataRowChecked(value => value = bodyDataRowsChecked)      
            }                 
        }
    }, [bodyDataRows])

    useEffect( () => {
        if(bodyDataRowsChecked?.length > 0){
            initialArrayState() 
        }
    }, [bodyDataRowsChecked])


    //Array inicial contendo true ou false pre-definido para o checkbox
    const initialArrayState = () => {
        let arrayState = []

        if(emptyFields === false){
            for(const value of bodyDataRows){
                if(bodyDataRowsChecked.includes(value?.Codigo)){
                    arrayState.push(true)
                } else {
                    arrayState.push(false)
                }
            }
        } else {
            const rowsLenght = bodyDataRows?.length
            arrayState = Array.from( Array(rowsLenght).fill(false, 0, rowsLenght) ) //Cria novo array na quantidade de dados informado ja com valor pre-definido (false)
        }
        setCheckedState(value => value = arrayState)
    }

    //Muda marcação apenas do item selecionado (Usado no onclick do input)
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(value => value = updatedCheckedState);
    }

    //Verifica se o campo é checked ou não
    function checkFields(dataRowChecked, val, key) {
        checkArray = []

        if(emptyFields === false) {
            if (dataRowChecked?.includes(val?.Codigo)) {
                checkArray.push(
                    <input
                        type="checkbox"
                        onClick={() => handleOnChange(key)}
                        checked={checkedState[key]}
                        name={val.Codigo}
                        id={`Checkbox${val.Codigo}`}
                        key={`Check${val.Codigo}`}
                        ref={registerForm}
                    />
                )
            } else {
                checkArray.push(
                    <input
                        type="checkbox"
                        onClick={() => handleOnChange(key)}
                        checked={checkedState[key]}
                        name={val.Codigo}
                        id={`Checkbox${val.Codigo}`}
                        key={`Check${val.Codigo}`}
                        ref={registerForm}
                    />
                )
            }
        } else {
            checkArray.push(
                <input
                    type="checkbox"
                    onClick={() => handleOnChange(key)}
                    // checked={checkedState[key]}
                    name={val.Codigo}
                    id={`Checkbox${val.Codigo}`}
                    key={`Check${val.Codigo}`}
                    ref={registerForm}
                />
            )
        }

        return checkArray.map((item) => {
            return item
        })
    }

    return (
        <div className='TabelaGeralContainerPermissaoUsuario'>
            <table>
                <thead>
                    <tr>
                        {headerTitles?.map(item => (
                            <th>{item}</th>
                        )
                        )}
                    </tr>
                </thead>
            </table>
            <div id='linhaTabelaPermissaoUsuario'>
                {dataRow?.map((val, key) => {
                    return (
                        <div id='itemTabelaPermissaoUsuario'>
                            {
                                checkFields(dataRowChecked, val, key)
                            }
                            <div key={key} value={val.Curso} id="TabelaGeralDashRow" >
                                {tableFields?.map(item => (
                                    isNaN(val[item]) === false ?
                                        <div key={val.Curso} ref={registerForm} >{val[item]}</div>
                                        : <div key={val.Curso} ref={registerForm} >{val[item].toUpperCase()}</div>
                                )
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}