export default function FilterCursosByGrades(grades){
        const codCursosFiltro = [...new Set(grades.map(item => item.CodCurso))]
        let cursosPush = []

        for (const codigo of codCursosFiltro) {
            const itemFind = grades.filter(item => item.CodCurso === codigo)[0]
            cursosPush.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        }

        const dadosCursos = []

        cursosPush.filter((item) => {
            const itemFind = item
            dadosCursos.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        })

        return dadosCursos
}