import Axios from 'axios'

export default async function GetConteudoDisciplinasKeys(baseUrl, dataInicial, dataFinal, curso_id, turma_id) {

    const resultado = await Axios.post(`${baseUrl}/getConteudoDisciplinasKeys`, {
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        curso_id: curso_id,
        turma_id: turma_id
    })

    return resultado.data
}