import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './Login';
import Home from './Pages/Home'
import Diario from './Pages/Diario/Diario'
import Lancar from './Pages/Diario/Lancar/Lancar'
import GerarDiario from './Pages/Diario/Gerar/GerarDiario'
import FormProvider from './context/userForm'
import Notas from './Pages/Notas/Notas';
import LancarNotas from './Pages/Notas/Lancar/LancarNotas';
import Qualitativas from './Pages/Qualitativas/Qualitativas';
//ADMIN IMPORTS
import HomeAdmin from './Admin/Pages/Home/Home'
import ProfessoresAdmin from './Admin/Pages/Professores/Professores'
import DiarioAdmin from './Admin/Pages/Diario/Diario';
import NotasAdmin from './Admin/Pages/Notas/Notas';
import GerenciarAdmin from './Admin/Pages/Gerenciar/Gerenciar';
import RelatoriosAdmin from './Admin/Pages/Relatorios/Relatorios';
import RelatorioCompleto from './Admin/Pages/RelatorioCompleto/RelatorioCompleto';
import RelatorioListaFrequencia from './Admin/Pages/RelatorioListaFrequencia/RelatorioListaFrequencia';
import RelatorioResumoAtividades from './Admin/Pages/RelatoriorResumoAtividades/RelatorioResumoAtividades';
import RelatorioNotasAuxiliares from './Admin/Pages/RelatorioNotasAuxiliares/RelatorioNotasAuxiliares';
import EditarProfessorAdmin from './Admin/Pages/EditarProfessor/EditarProfessor';
import DetalhesDiarioAdmin from './Admin/Pages/DetalhesDiario/DetalhesDiario';
import DetalhesNotasAdmin from './Admin/Pages/DetalheNotas/DetalhesNotas'
import Usuarios from './Admin/Pages/Usuarios/Usuarios'
import EditarUsuario from './Admin/Pages/EditarUsuario/EditarUsuario'; 
import CriarUsuario from './Admin/Pages/CriarUsuario/CriarUsuario'; 
import LoginAdmin from './Admin/Pages/Login/Login';
import Exportar from './Admin/Pages/Exportar/Exportar';
import FecharFaltas from './Admin/Pages/FecharFaltas/FecharFaltas';
import ConceitoAdmin from './Admin/Pages/Conceito/Conceito';
import DetalhesConceitosAdmin from './Admin/Pages/DetalheConceitos/DetalhesConceitos';
import ExtrasAdmin from './Admin/Pages/Extras/Extras';
import ArredondarNotas from './Admin/Pages/ArredondarNotas/ArredondarNotas';
import CalcularAux from './Admin/Pages/CalcularAux/CalcularAux';
import RelatorioFaltasTotais from './Admin/Pages/RelatórioFaltasTotais/RelatorioFaltasTotais';
import LiberarBloquearNotas from './Admin/Pages/LiberarBloquearNotas/LiberarBloquearNotas';
import TransferirFaltas from './Admin/Pages/TransferirFaltas/TransferirFaltas';
import QualitativasAdmin from './Admin/Pages/Qualitativas/Qualitativas';
import DetalhesQualitativas from './Admin/Pages/DetalheQualitativas/DetalhesQualitativas';
import QualitativasPorCriterio from './Pages/Qualitativas/PorCriterio/PorCriterio';
import QualitativasPorAluno from './Pages/Qualitativas/PorAluno/PorAluno';

const Routes = () => (
    <FormProvider>
        <Router basename="/">
            <Switch>
                {/* NORMAL USER */}
                <Route exact path='/home/' component={Home} />
                <Route exact path='/Diario/' component={Diario} />
                <Route path='/Diario/Lancar/' component={Lancar} />
                <Route exact path='/Diario/Gerar/' component={GerarDiario} />
                <Route exact path='/Notas/' component={Notas} />
                <Route exact path='/Notas/LancarNotas/' component={LancarNotas} />
                <Route exact path='/Qualitativas/' component={Qualitativas} />
                <Route exact path='/Qualitativas/PorCriterio' component={QualitativasPorCriterio} />
                <Route exact path='/Qualitativas/PorAluno' component={QualitativasPorAluno} />
                <Route exact path='/' component={Login} />
                {/* ADMIN ROUTES */}
                <Route exact path="/Admin/Login" component={LoginAdmin} />
                <Route exact path="/Admin/Home" component={HomeAdmin} />
                <Route exact path="/Admin/Professores/" component={ProfessoresAdmin} />
                <Route exact path="/Admin/Professores/EditarProfessor/" component={EditarProfessorAdmin} />
                <Route exact path="/Admin/Usuarios/" component={Usuarios} />
                <Route exact path="/Admin/Usuarios/EditarUsuario" component={EditarUsuario} />
                <Route exact path="/Admin/Usuarios/CriarUsuario" component={CriarUsuario} />
                <Route exact path="/Admin/Diario/" component={DiarioAdmin} />
                <Route exact path="/Admin/Diario/Dados" component={DetalhesDiarioAdmin} />
                <Route exact path="/Admin/Notas/" component={NotasAdmin} />
                <Route exact path="/Admin/Notas/Dados" component={DetalhesNotasAdmin} />
                <Route exact path="/Admin/Conceitos/" component={ConceitoAdmin} />
                <Route exact path="/Admin/Conceitos/Dados" component={DetalhesConceitosAdmin} />
                <Route exact path="/Admin/Qualitativas/" component={QualitativasAdmin} />
                <Route exact path="/Admin/Qualitativas/Dados" component={DetalhesQualitativas} />
                <Route exact path="/Admin/Gerenciar/" component={GerenciarAdmin} />
                <Route exact path="/Admin/Gerenciar/FecharFaltas" component={FecharFaltas} />
                <Route exact path="/Admin/Relatorios/" component={RelatoriosAdmin} />
                <Route exact path="/Admin/Relatorios/RelatorioCompleto" component={RelatorioCompleto} />
                <Route exact path="/Admin/Relatorios/RelatorioListaFrequencia" component={RelatorioListaFrequencia} />
                <Route exact path="/Admin/Relatorios/RelatorioResumoAtividades" component={RelatorioResumoAtividades} />
                <Route exact path="/Admin/Relatorios/RelatorioNotasAuxiliares" component={RelatorioNotasAuxiliares} />
                <Route exact path="/Admin/Relatorios/RelatorioFaltasTotais" component={RelatorioFaltasTotais} />
                <Route exact path="/Admin/Extras/" component={ExtrasAdmin} />
                <Route exact path="/Admin/Extras/ArredondarNotas" component={ArredondarNotas} />
                <Route exact path="/Admin/Extras/CalcularAuxiliares" component={CalcularAux} />
                <Route exact path="/Admin/Extras/LiberarBloquearNotas" component={LiberarBloquearNotas} />
                <Route exact path="/Admin/Extras/TransferirFaltas" component={TransferirFaltas} />
                
                <Route exact path="/Admin/Exportar/" component={Exportar} />
            </Switch>
        </Router>
    </FormProvider>
);

export default Routes;