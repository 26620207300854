const AvaliacoesDetailsObj = () => {
    const dados = [
        {Ordem: 1, Codigo: 'N100', Abreviatura: '1°AV', IsAvaliacao: true},
        {Ordem: 2, Codigo: 'N200', Abreviatura: '2°AV', IsAvaliacao: true},
        {Ordem: 3, Codigo: 'N300', Abreviatura: '3°AV', IsAvaliacao: true},
        {Ordem: 4, Codigo: 'N400', Abreviatura: '4°AV', IsAvaliacao: true},
        {Ordem: 5, Codigo: 'N500', Abreviatura: '5°AV', IsAvaliacao: true},
        {Ordem: 6, Codigo: 'N600', Abreviatura: '6°AV', IsAvaliacao: true},
        {Ordem: 7, Codigo: 'N700', Abreviatura: '7°AV', IsAvaliacao: true},
        {Ordem: 8, Codigo: 'N800', Abreviatura: '8°AV', IsAvaliacao: true},
        {Ordem: 9, Codigo: 'N101', Abreviatura: '1°Re', IsAvaliacao: false},
        {Ordem: 10, Codigo: 'N201', Abreviatura: '2°Re', IsAvaliacao: false},
        {Ordem: 11, Codigo: 'N301', Abreviatura: '3°Re', IsAvaliacao: false},
        {Ordem: 12, Codigo: 'N401', Abreviatura: '4°Re', IsAvaliacao: false},
        {Ordem: 13, Codigo: 'S101', Abreviatura: 'Re1S', IsAvaliacao: false},
        {Ordem: 14, Codigo: 'S201', Abreviatura: 'Re2S', IsAvaliacao: false},
        {Ordem: 15, Codigo: 'N950', Abreviatura: 'R.F', IsAvaliacao: false}
    ]

    return dados
}

module.exports = {AvaliacoesDetailsObj}