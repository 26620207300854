export const alunosType = {
    Matricula: "",
    Curso: "",
    CursoNome: "",
    Turma: "",
    NumDiario: "",
    Nome: ""
}

export const avaliacaoType = {
    Sistema: "",
    Codigo: "",
    Avaliacao: "",
    Abreviatura: "",
    Ordem: 0,
    Digitar: 0,
    Entrega: null
}

export const gradesQuaType = {
    Curso: "",
    Codigo: "",
    Descricao: "",
    Nivel: 0,
    Grupo: 0,
    SubGrupo: 0,
    Destaque: 0,
    Dados: 0
}

export const digitacaoProfType = {
    Codigo: 0,
    CodFuncionario: "",
    CodDisciplina: "",
    CodCurso: "",
    Curso: "",
    Turma: "",
    Auxiliar: "",
    Lanca: 0,
    SistemaCurso: ""
}

export const gradesQuaCursoType = {
    Curso: "",
    Codigo: "01",
    Descricao: "",
    Nivel: 0,
    Grupo: 0,
    SubGrupo: 0,
    Destaque: 0,
    Dados: 0
}

export const cursosType = {
    Codigo: "",
    Curso: "",
    Sistema: ""
}

export const notasQuaConsultadasType = {
    id: 0,
    data_lanc: "",
    update_at: "",
    matricula: "",
    chave: "",
    disciplina: "",
    nota: null,
    conceito: "",
    falta: null,
    etapa: null,
    baixado: null|0
}