import React, { useState, useEffect } from 'react'
import './TabelaGeral.css'

export default function TabelaGeral(
    { bodyDataRows, headerTableTitles, bodyTableFields }
) {
    const [dataRow, setDataRow] = useState(bodyDataRows)
    const [headerTitles, setHeaderTitles] = useState(headerTableTitles)
    const [tableFields, setTableFields] = useState(bodyTableFields)

    useEffect(() => {
        setDataRow(bodyDataRows)
    }, [bodyDataRows])

    return (
        <div className='TabelaGeralContainer'>
            <table>
                <thead>
                    <tr>
                        {headerTitles?.map(item => (
                            <th>{item}</th>
                        )
                        )}
                        <th id="srollInvisibleHeadItem"></th>
                    </tr>
                </thead>
            </table>

            <div className="tabelaGeralBodyDataContainer">
                {dataRow?.map((val, key) => {
                    return (

                        <div id="TabelaGeralDashRow" >
                            {tableFields?.map((item, index) => (
                                isNaN(val[item]) === false ?
                                    <div id="itemBodyTabelaGeralDashRow" key={`${key}${index}`}> {val[item]} </div>
                                    : <div id="itemBodyTabelaGeralDashRow" key={`${key}${index}`}> {val[item].toUpperCase()} </div>
                            )
                            )}
                        </div>
                    )
                })}
            </div>

        </div>
    )
}