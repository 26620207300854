import Axios from 'axios'
import { ApiFilter } from "./FilterApiDomain"

const sub = localStorage.getItem('target')
const options = { header: { 'X-Custom-Header': 'value' } }
const baseUrl = ApiFilter(sub)

export default async function DeletarConteudoDiario(dado) {
    // id / data / curso-id / disciplina_id / turma / descricao / atividade_casa / etapa

    const resultado = await Axios.post(`${baseUrl}/deletar-conteudo`,
        {
            formulario: dado
        }, options)

        console.log('DeletarConteudoApi: ', resultado)
        
    const resultadoStatus = resultado.status

    return resultadoStatus
}