import './LiberarBloquearNotas.css'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import ButtonAvaliacao from '../../../components/ButtonAvaliacao/ButtonAvaliacao'
import FormBasicAdmin from '../../../components/FormBasic/FormBasic'
import { Backdrop, CircularProgress } from '@mui/material'

import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetGrades from '../../../services/GetGrades'
import GetTurmas from '../../../services/GetTurmas'
import PostLiberarBloquearNotas from '../../../services/PostLiberarBloquearNotas'

import FilterCursosByGrades from '../../../Functions/FilterCursosByGrades'
const { ApiFilter } = require('../../../Functions/FilterApiDomain')



export default function LiberarBloquearNotas() {
    const [open, setOpen] = useState(false)
    const [grades, setGrades] = useState([])
    const [cursos, setCursos] = useState([])
    const [turmas, setTurmas] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])
    const [checkTodasNotas, setCheckTodasNotas] = useState(false)
    const [styleLabelTodasAsNotas, setStyleLabelTodasAsNotas] = useState('todasAsNotasDisabled')
    const [sub, setSub] = useState(localStorage.getItem('target'))

    const baseUrl = ApiFilter(sub)

    const { register, handleSubmit, errors } = useForm()

    useEffect(() => {
        Promise.all([GetAvaliacoes(baseUrl), GetGrades(baseUrl), GetTurmas(baseUrl)])
            .then((values) => {
                setAvaliacoes(values[0])
                setGrades(values[1])
                setTurmas(values[2])

                const gradesReturn = values[1]
                const filterCursos = FilterCursosByGrades(gradesReturn)
                setCursos(filterCursos)
            })
            .catch((e) => {
                console.log('Error PromiseAll: ', e)
            })
    }, [])


    const onSubmit = async (dados) => {
        const codCurso = dados.curso ?? 'todos'
        const turma = dados.turma ?? 'todos'
        const codDisciplina = dados.disciplina ?? 'todos'
        const codAvaliacao = JSON.parse(dados.avaliacao).Codigo ?? 'todos'

        const postResultado = await PostLiberarBloquearNotas(baseUrl, codCurso, codDisciplina, codAvaliacao, turma, checkTodasNotas)

        window.alert(postResultado.msg)

        console.log('ResultadoPost2: ', postResultado)
    }

    return (
        <>
            <div id='HomePageGeneralContainer'>
                <SideBar />
                <div id='HomePageContainer'>
                    <Header />

                    <div id="ExtrasTitlePage">
                        Liberar Notas
                    </div>

                    <div className="ArredondarNotasPainelContainer">
                        {/* <h2>ARREDONDAR NOTAS</h2> */}

                        <div id="ArredondarNotasOptionsContainer">

                            <div id="ArredondarNotasTodasContainer">
                                <input type='checkbox' value={checkTodasNotas} onClick={() => setCheckTodasNotas(!checkTodasNotas)} />
                                <label className={styleLabelTodasAsNotas}>TODAS AS NOTAS</label>
                            </div>

                            <hr />

                            <FormBasicAdmin
                                grades={grades}
                                cursos={cursos}
                                turmas={turmas}
                                avaliacoes={avaliacoes}
                                disabled={checkTodasNotas}
                                cursoTodos={true}
                                register={register}
                            />

                            <div>
                                <label>AVALIAÇÃO <span style={{ opacity: 0.6, marginLeft: '0.3em', color: 'red' }}>(Obrigatório)</span> </label>
                                <ButtonAvaliacao apenasAvaliacao={false} disabled={false} register={register} />
                            </div>

                            <Backdrop
                                sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                                <p>Processando...</p>
                            </Backdrop>


                            <div id="ArredondarNotasButtonContainer">
                                <input type='submit' id="ProcessarArredondarNotasBtn" value={"PROCESSAR"} onClick={handleSubmit(onSubmit)} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}