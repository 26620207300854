import React, { useState, useEffect } from 'react'
import ButtonBack from '../ButtonBack/ButtonBack'
import GetQualitativasByChave from '../../services/GetQualitativasByChave'
import QualitativasTabelaAlunos from '../QualitativasTabelaAlunos/QualitativasTabelaAlunos'
import SelectAPI from '../../Functions/SelectApi'
import GetNotasAuxLegenda from '../../services/GetNotasAuxLegenda'
import './QualitativasDados.css'
import { Link } from 'react-router-dom'

export default function QualitativasDados({ title }) {
    const [dadosDiario, setDadosDiario] = useState(JSON.parse(sessionStorage.getItem('d')))
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [notasAuxLeg, setNotasAuxLeg] = useState([])
    const [notas, setNotas] = useState([])
    const [alunos, setAlunos] = useState([])
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const baseUrl = SelectAPI(valorEscola)

    const onBack = () => {
        sessionStorage.removeItem('d')
        sessionStorage.removeItem('p')
    }

    const notasTableTitles = [
        "MATRICULA",
        "NOME",
        ""
    ]

    const notasTableFields = [
        "matricula",
        "nome"
    ]

    async function getNotas() {
        /** @type {[GetQualitativasByChaveReturn]} */
        const resultadoNotas = await GetQualitativasByChave(baseUrl, dadosDiario.Chave)
        console.log('resultadoNotas: ', resultadoNotas)
        // setNotas(resultadoNotas)
        setAlunos(resultadoNotas)
    }

    useEffect(() => {
        getNotas()
    }, [])

    const closeModal = () => {
        setModalIsOpen(!modalIsOpen)
    }

    async function GetAuxLegenda() {
        const resultAux = await GetNotasAuxLegenda(baseUrl)
        console.log('LegendaGet', resultAux)
        const resultAuxFilter = resultAux.filter(item => item.Chave === dadosDiario?.Codigo)
        const exists = filterExistAuxLeg(resultAuxFilter)
        setNotasAuxLeg(exists)

        console.log('ExistLeg', exists)
    }

    function filterExistAuxLeg(notasAuxLeg) {
        const notasAuxFilterExist = []
        for (const valueItem in notasAuxLeg[0]) {

            if (valueItem.includes('Leg')) {
                const addItem = { [valueItem]: notasAuxLeg[0][valueItem] }
                if (notasAuxLeg[0][valueItem] !== "") {
                    notasAuxFilterExist.push(addItem)
                }
            }
        }

        return notasAuxFilterExist
    }

    const showTableAuxLeg = () => {
        const renderAlunos = (aluno, idx) => {
            const alunoRenderItem = []

            for (const alunoItem in aluno) {
                if (alunoItem !== 'Nome' && alunoItem !== 'matricula' && alunoItem !== 'nota' && alunoItem !== 'falta') {
                    const numberLeg = alunoItem.split('_')[1]

                    const resultFindLeg = notasAuxLeg.filter(item => {
                        const itemAux = item
                        const leg = `Leg${numberLeg}`
                        return itemAux[leg]
                    })
                    if (resultFindLeg[0] !== undefined) {
                        console.log('ResultFindLeg', resultFindLeg[0])
                        alunoRenderItem.push(<td>{aluno[alunoItem]}</td>)
                    }
                }
            }
            return (
                alunoRenderItem.map((item, idx) => {
                    return item
                })
            )
        }

        return (
            //RENDER FINAL
            <div id="notasDadosTableContainer">
                <table>
                    <thead>
                        <tr>
                            <td>ALUNOS</td>
                            {
                                notasAuxLeg.map((notas, index) => {
                                    const numberLeg = index !== 9 ? `Leg0${index + 1}` : 'Leg10'
                                    return (
                                        <td >{notas[numberLeg]}</td>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody >
                        {
                            alunos.map((aluno, index) => {
                                return (
                                    <tr>
                                        <td>{aluno.matricula}</td>
                                        <td>{aluno.nome}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    useEffect(() => {
        if (dadosDiario.Codigo) {
            getNotas()
            GetAuxLegenda()
        }
    }, [dadosDiario])


    return (
        <div className='NotasDadosContainer'>
            <div className='NotassDadosDashContainer'>
                <div className='NotasDadosDashBarraPesquisa'>
                    {title}
                </div>

                <div className="NotasDadosBasicInfoContainer">
                    <div className='NotasDadosIDContainer TextDescricao'>
                        CÓDIGO
                        <input disabled={true} value={dadosDiario?.Chave} id="InputCodigo" />
                    </div>
                    <div className='NotasDadosCursoContainer TextDescricao'>
                        CURSO
                        <div id='NotasDadosCursoContainerEdit'>
                            <input disabled={true} value={dadosDiario?.Curso?.toUpperCase()} id="InputCurso" />
                        </div>
                    </div>
                    <div className='NotasDadosTurmaContainer TextDescricao' >
                        TURMA
                        <input value={dadosDiario?.Turma} disabled={true} id='InputTurma' />
                    </div>
                </div>

                <div className='NotasDadosBasicInfoContainerRow2'>
                    <div className='QualitativasDadosAvaliacaoContainer TextDescricao'>
                        AVALIAÇÃO
                        <input disabled value={dadosDiario?.Avaliacao} id="InputAvaliacao" />
                    </div>
                </div>
            </div>

            <div className='PermissaoDivDescription'>
                PERMISSÕES
            </div>

            <QualitativasTabelaAlunos
                bodyDataRows={alunos}
                headerTableTitles={notasTableTitles}
                bodyTableFields={notasTableFields}
            />

            <div className='NotasDadosButtonsContainer'>
                <Link to='/admin/Qualitativas' className="linkBtn">
                    <ButtonBack Title="VOLTAR" />
                </Link>
            </div>
        </div>
    )
}