import React, { useState, useEffect } from 'react'
import './CardBlue.css'
import CountUp from 'react-countup';
import GroupIcon from '@material-ui/icons/Group';
import GetProfessores from '../../services/GetProfessores';
const { ApiFilter } = require('../../Functions/FilterApiDomain')

export default function CardBlue() {
    const [professores, setProfessores] = useState([])
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const baseUrl = ApiFilter(sub)

    async function Professores() {
        setProfessores(await GetProfessores(baseUrl))
    }

    useEffect(() => {
        Professores()
    }, [])

    return (
        <div className='CardDashConteinerBlue'>
            <div className='CardDashInfo'>
                <div id='NumberCardDash'>
                    <CountUp end={professores?.length} duration={8} id='NumberCardDash' />
                </div>
                <GroupIcon id="GroupIcon" />
            </div>
            <div id='DescriptionDashCard'>Professores Ativos</div>
        </div>
    )
}