import React, { useEffect, useState } from 'react'
const { AvaliacoesDetailsObj } = require('../../Functions/AvaliacoesDetailsObj')


export default function ButtonAvaliacao({ apenasAvaliacao, disabled, register }) {
    const [valorAvaliacao, setValorAvaliacao] = useState('')
    const [avaliacoes, setAvaliacoes] = useState(AvaliacoesDetailsObj())
    const [avaliacoesFiltro, setAvaliacoesFiltro] = useState([])
    

    const onChangeAvaliacao = ({target}) => {
        setValorAvaliacao(target.value)
    }

    const filtrarAvaliacoes = (apenasAvaliacao) => {
        let avaliacoesReturn = []

        for(const av of avaliacoes){
            if(apenasAvaliacao){
                if(av.IsAvaliacao){
                    avaliacoesReturn.push(av)
                }
            } else {
                avaliacoesReturn.push(av)
            }
        }

        setAvaliacoesFiltro(avaliacoesReturn)
    }

    useEffect(() => {
        if (avaliacoes.length > 0) {
            filtrarAvaliacoes(apenasAvaliacao)
        }
    }, [avaliacoes])

    return (
        <select disabled={disabled} name="avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} id="avaliacao" ref={register} required={true} >
            {/* <option value={6666} key='AvaliacaoEmBranco'></option> */}
            {
                avaliacoesFiltro?.map((val, key) => {
                    return (
                        <option value={JSON.stringify(val)} key={key}> {val.Abreviatura} </option>
                    )
                })
            }
        </select>
    )
}