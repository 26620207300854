import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import UsuariosTabela from '../../../components/UsuariosTabela/UsuariosTabela'
import GetUsers from '../../../services/GetUsers'
import SelectAPI from '../../../Functions/SelectApi'
import './Usuarios.css'

export default function Usuarios() {
    const [professores, setProfessores] = useState([])
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const isAdmin = localStorage.getItem('isAdmin') !== null ? localStorage.getItem('isAdmin') : '0'
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    const baseUrl = SelectAPI(valorEscola)

    const professoresTableFields = [
        "Id",
        "Nome",
        "Status"
    ]

    const professoresTableTitles = [
        "Codigo",
        "Nome",
        "Status"
    ]

    async function getProfessores() {
        setProfessores(await GetUsers(baseUrl))
    }

    useEffect(() => {
        if (valorEscola !== 'escola') {
            getProfessores()
        }
    }, [valorEscola])

    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='ProfessoresGeneralDashContainer'>
                    <SideBar />
                    <div className='ProfessoresDashContainer'>
                        <Header />
                        <div id="professoresTitlePage">
                            USUÁRIOS
                        </div>


                        <UsuariosTabela
                            bodyDataRows={professores}
                            bodyTableFields={professoresTableFields}
                            headerTableTitles={professoresTableTitles}
                            searchBarInputPlaceHolder="Usuário"
                            filterBy='Nome'
                            searchBarShow={true}
                            isAdministrator={isAdmin}
                        />
                    </div>
                </div>
            </>
        )
    }
}