import './home.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Home() {
    const [session, setSession] = useState({})
    const [primeiroNome, setPrimeiroNome] = useState('')

    let logado = sessionStorage.getItem('isLoged')

    let nomeLowerCase = ''
    let nomeCapitalize = ''

    useEffect(() => {
        setSession(sessionStorage.length > 0 ? sessionStorage : '')
        logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'

        if (session.length > 0) {
            logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'
            setPrimeiroNome(session.nome.split(" ")[0])
        } else {
            return
        }
        capitalizeString()
    });

    async function capitalizeString() {
        nomeLowerCase = await primeiroNome.toLowerCase()
        nomeCapitalize = await nomeLowerCase.trim().replace(/^\w/, (c) => c.toUpperCase())
        setPrimeiroNome(nomeCapitalize)
    }
    //CAPITALIZAR NOME PROFESSOR
    useEffect(() => {
        capitalizeString()
    }, [session])

    function lancarClick() {
        const anoPermissao = localStorage.getItem('Year')
        if (anoPermissao === '2021') {
            const senha = prompt('Digite a senha de acesso')
            if (senha == '12345678') {
                document.location.href = '/Notas/';
            }
        } else {
            document.location.href = '/Notas/';
        }
    }


    //RENDERIZA SE EXISTIR isLoged no SESSION-STORAGE
    if (logado == 'true') {
        try {
            return (
                <div data-testid="home-screen"  id="HomeAppContainer">
                    <div id="headerAppHome">
                        <p>Olá {primeiroNome}</p>
                    </div>

                    <section className="section-container">
                        <Link to="/Diario/">
                            <div className="card one">
                                <svg className="diario-icon" xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" fill="none" stroke="#36bd41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                                <h1>Diário</h1>
                                <p>Registre as faltas dos alunos e mantenha o controle de suas aulas</p>
                            </div>
                        </Link>

                        <Link to="/Notas/">
                            <div className="card">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" fill="none" stroke="#36bd41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                                <h1>Notas Web</h1>
                                <p>Lance as notas dos seus alunos dentro das etapas permitidas</p>
                            </div>
                        </Link>

                        <Link to="/Qualitativas/">
                            <div className="card">
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#36bd41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                                <h1>Qualitativas</h1>
                                <p>Avalie seus alunos dentro dos critérios definidos</p>
                            </div>
                        </Link>
                    </section>

                    <footer></footer>
                </div>
            );
        } catch {
            window.location.reload()
        }
    } else {
        //localStorage.clear()
        sessionStorage.clear()
        window.location.replace(window.location.origin)
        //history.push('/home')
    }
}

export default Home;