import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ApiFilter } from '../../Functions/FilterApiDomain'
import Close from '@material-ui/icons/Close'
import SistemaAvaliacaoCard from '../SistemaAvaliacaoCard/SistemaAvaliacaoCard'
import './ButtonModalFecharFaltas.css'


export default function ButtonModalFecharFaltas({ ref, cursosUnicos, dadosFinais }) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const baseUrl = ApiFilter(localStorage.getItem('target'))
    const escola = localStorage.getItem('escola')
    const [dadosFinaisRender, setDadosFinaisRender] = useState([])

    useEffect(() => {
        setDadosFinaisRender(dadosFinais)
    }, [dadosFinais])

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    useEffect(() => {
        
    }, [])

    return (
        <div id='ContainerModalFecharFaltas'>
            <button id='ButtonModalFecharOpen' className='' onClick={openModal}> AVANÇAR </button>
            <Modal
                closeTimeoutMS={200}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Lista de estudantes'
                overlayClassName='modal-overlay'
                className='modal-content'
                
            >
                <h2>SISTEMAS DE AVALIAÇÃO</h2>
                <hr />
                <p>
                    TIPOS DISPONÍVEIS
                </p>

                <SistemaAvaliacaoCard ref={ref} dadosFinais={dadosFinaisRender} cursosUnicos={cursosUnicos} />
                

                <div id='ButtonModalFecharFaltasContainer'>
                    <button id='ButtonModalFecharFaltas' onClick={closeModal}>
                        <Close id="CloseIconModalFecharFaltas" />
                        FECHAR
                    </button>

                    <button id='ButtonModalFecharConfirmar' onClick={closeModal}>
                        CONFIRMAR
                    </button>
                </div>
            </Modal>
        </div>
    )
}