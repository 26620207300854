import './PorCriterio.css'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Header from '../../../components/header'
import { ScaleLoader } from 'react-spinners'

import GetCursos from '../../../services/GetCursos'
import GetDigitacaoProfessor from '../../../services/GetDigitacaoProfessor'
import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetAlunos from '../../../services/GetAlunos'
import GetGradesQua from '../../../services/GetGradesQua'

import { ApiFilter } from '../../../Functions/FilterApiDomain'
import FiltrarCurso from '../../../Functions/FiltrarCurso'
import OrdemItem from '../../../Functions/OrdenarItens'
import PostInserirQualitativas from '../../../services/PostInserirQualitativas'
import PostUpdateQualitativas from '../../../services/PostUpdateQualitativas'
import GetObservacoesQualitativas from '../../../services/GetObservacoesQualitativas'
import GetNotasQualitativasPorChave from '../../../services/GetQualitativasPorChave'
import PostInserirObservacoes from '../../../services/PostInserirObservacoes'
import PostUpdateObservacoes from '../../../services/PostUpdateObservacoes'
// import Sort_by from '../../Functions/OrdenarNivelQualitativas'


let valorCursoAtual = ''
let valorAlunoAtual = ''


export default function QualitativasPorCriterio() {

    const [cursos, setCursos] = useState([])
    const [turmas, setTurmas] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])
    const [alunos, setAlunos] = useState([])
    const [gradesQua, setGradesQua] = useState([])
    const [gradesQuaCurso, setGradesQuaCurso] = useState([])
    const [gradesQuaNivel, setGradesQuaNivel] = useState([])

    const [digitacaoProf, setDigitacaoProf] = useState([])
    const [cursosPermitidos, setCursosPermitidos] = useState([])
    const [avaliacoesFiltro, setAvaliacoesFiltro] = useState([])
    const [alunosPermitidos, setAlunosPermitidos] = useState([])

    const sessionForm = JSON.parse(sessionStorage.getItem('formQua'))
    const [valorCurso, setValorCurso] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorAvaliacao, setValorAvaliacao] = useState('')
    const [valorAluno, setValorAluno] = useState('')
    const [valorObservacoes, setValorObservacoes] = useState('')
    const [valorGradesQua, setValorGradesQua] = useState('')
    const [valorGradesNivel, setValorGradesNivel] = useState('')

    const [dadosConsultados, setDadosConsultados] = useState(false)
    const [notasQuaConsultadas, setNotasQuaConsultadas] = useState([])
    const [observacoesQua, setObservacoesQua] = useState([])

    const { register, handleSubmit } = useForm()
    const codProfessor = sessionStorage.getItem('professores')
    const sub = localStorage.getItem('target')
    const baseUrl = ApiFilter(sub)
    let count = 0

    const onChangeCurso = ({ target }) => {
        setValorTurma('')
        setValorAvaliacao('')
        setValorAluno('')
        setValorGradesQua('')
        setValorGradesNivel('')

        setValorCurso(target.value)
    }

    const onChangeTurma = ({ target }) => {
        setValorAvaliacao('')
        setValorAluno('')
        setValorGradesQua('')
        setValorGradesNivel('')

        setValorTurma(target.value)
    }

    var sort_by;

    (function () {
        // utility functions
        var default_cmp = function (a, b) {
            if (a == b) return 0;
            return a < b ? -1 : 1;
        },
            getCmpFunc = function (primer, reverse) {
                var dfc = default_cmp, // closer in scope
                    cmp = default_cmp;
                if (primer) {
                    cmp = function (a, b) {
                        return dfc(primer(a), primer(b));
                    };
                }
                if (reverse) {
                    return function (a, b) {
                        return -1 * cmp(a, b);
                    };
                }
                return cmp;
            };

        // actual implementation
        sort_by = function () {
            var fields = [],
                n_fields = arguments.length,
                field, name, reverse, cmp;

            // preprocess sorting options
            for (var i = 0; i < n_fields; i++) {
                field = arguments[i];
                if (typeof field === 'string') {
                    name = field;
                    cmp = default_cmp;
                }
                else {
                    name = field.name;
                    cmp = getCmpFunc(field.primer, field.reverse);
                }
                fields.push({
                    name: name,
                    cmp: cmp
                });
            }

            // final comparison function
            return function (A, B) {
                var a, b, name, result;
                for (var i = 0; i < n_fields; i++) {
                    result = 0;
                    field = fields[i];
                    name = field.name;

                    result = field.cmp(A[name], B[name]);
                    if (result !== 0) break;
                }
                return result;
            }
        }
    }());

    const onChangeAvaliacao = ({ target }) => {
        setValorAluno('')
        setValorGradesQua('')
        setValorGradesNivel('')

        setValorAvaliacao(target.value)
    }

    const getNotasQualitativas = async () => {
        const chave = valorAvaliacao + '1111' + valorCurso + valorTurma
        const notas = await GetNotasQualitativasPorChave(baseUrl, chave)
        return notas
    }

    const getObservacoesQua = async () => {
        const chave = valorAvaliacao + '1111' + valorCurso + valorTurma
        const obsResult = await GetObservacoesQualitativas(baseUrl, chave, valorAluno)
        return obsResult
    }

    const onChangeAluno = async (event) => {
        setValorGradesQua('')
        setValorGradesNivel('')

        valorAlunoAtual = event.target.value
        setValorAluno(event.target.value)
    }

    const onChangeObservacoes = ({ target }) => {
        setValorObservacoes(target.value)
    }

    const onChangeGradeQuaCurso = ({ target }) => {
        console.log('valorGradesQua: ', target.value)
        setValorGradesQua(target.value)
    }

    const onChangeGradeQuaNivel = ({ target }) => {
        setValorGradesNivel(target.value)
    }

    const FiltrarCursos = () => {
        const resultadoCursosPermitidos = FiltrarCurso(digitacaoProf, cursos)
        setCursosPermitidos(resultadoCursosPermitidos)

        if (count === 0) {
            setValorCurso(sessionForm ? sessionForm.curso : '')
            setValorTurma(sessionForm ? sessionForm.turma : '')
            setValorAluno(sessionForm ? sessionForm.aluno : '')
            setValorAvaliacao(sessionForm ? sessionForm.avaliacao : '')
            count++
        }
    }

    const FiltrarTurma = (curso_selecionado) => {
        const turmaPermissao = []
        setTurmas([])
        //Varrer permissao por curso e turma selecionados
        for (const item of digitacaoProf) {
            if (item.CodCurso === curso_selecionado) {
                turmaPermissao.push(item);
            }
        }

        //Salvar Array de Turmas evitando duplicidade
        const turmasDistinct = [...new Set(turmaPermissao.map(item => item.Turma))]
        const turmasOrder = turmasDistinct.sort()
        setTurmas(turmasOrder)
    }


    const FiltrarAvaliacoes = (valorCurso) => {
        const avaliacaoPush = []
        const cursoSistema = cursosPermitidos.filter(item => item.Codigo === valorCurso)[0]?.Sistema

        for (const value of avaliacoes) {
            if (
                value.Codigo === 'N100' && cursoSistema === value.Sistema ||  // 1° AV
                value.Codigo === 'N200' && cursoSistema === value.Sistema ||  // 2° AV 
                value.Codigo === 'N300' && cursoSistema === value.Sistema ||  // 3° AV
                value.Codigo === 'N400' && cursoSistema === value.Sistema ||  // 4° AV
                value.Codigo === 'N500' && cursoSistema === value.Sistema ||  // 5° AV
                value.Codigo === 'N600' && cursoSistema === value.Sistema ||  // 6° AV
                value.Codigo === 'N700' && cursoSistema === value.Sistema ||  // 7° AV
                value.Codigo === 'N800' && cursoSistema === value.Sistema ||  // 8° AV
                value.Codigo === 'S101' && cursoSistema === value.Sistema ||  // Re 1S
                value.Codigo === 'S201' && cursoSistema === value.Sistema ||  // Re 2S
                value.Codigo === 'N900' && cursoSistema === value.Sistema ||  // P.F
                value.Codigo === 'N950' && cursoSistema === value.Sistema     // Re.F
            ) {
                avaliacaoPush.push(value)
            }
        }
        setAvaliacoesFiltro(avaliacaoPush)
    }

    const FiltrarAluno = (curso_selecionado, turma_selecionada) => {
        const alunosPermitidosPush = []
        setAlunosPermitidos([])

        //Pega alunos permitidos
        const alunosPermissao = alunos.filter(item => item.Curso === curso_selecionado && item.Turma === turma_selecionada)
        //Pega apenas os nomes em ordem alfabetica
        const alunosPermitidosNomeOrdem = alunosPermissao.map((item) => item.Nome).sort()

        //Compara cada nome da ordem alfabetica com o alunosPermissao. E adiciona em um array
        for (let valor of alunosPermitidosNomeOrdem) {
            alunosPermitidosPush.push(alunosPermissao.find((item) => item.Nome === valor))
        }
        //Ordena alfabetica pt-br (desconsidera acentos)
        const alunosOrdenados = OrdemItem(alunosPermitidosPush, 'Nome')
        setAlunosPermitidos(alunosOrdenados)
    }

    const FiltroGradesQuaPorCurso = (curso_selecionado) => {
        const gradesFiltro = gradesQua.filter(item => item.Curso === curso_selecionado && item.Grupo === 0 && item.SubGrupo === 0)
        return gradesFiltro
    }

    const FiltroGradesQuaPorNivel = (curso_selecionado, criterio_avaliativo) => {
        const nivelFiltro = gradesQua.filter(item =>
            item.Curso === curso_selecionado &&
            item.Nivel === criterio_avaliativo &&
            !(item.Grupo === 0 && item.SubGrupo === 0))

        const result = gradesQua.filter(item => item.Curso === '005' && JSON.parse(valorGradesQua).Codigo === '108' && item.Codigo === '169')

        let nivelFiltroEdInfantilIng = []

        if (result.length > 0) {
            nivelFiltroEdInfantilIng = nivelFiltro.filter(item => item.Codigo === '165')
            const nivelOrdem = nivelFiltroEdInfantilIng.sort(sort_by('Grupo', { name: 'SubGrupo', primer: parseInt }));
            return nivelOrdem

        } else {
            const nivelOrdem = nivelFiltro.sort(sort_by('Grupo', { name: 'SubGrupo', primer: parseInt }));
            return nivelOrdem
        }

    }

    const onSubmit = async (dados) => {
        const sessionDados = { curso: valorCurso, turma: valorTurma, aluno: valorAluno, avaliacao: valorAvaliacao }

        if (window.confirm('Deseja salvar os dados informados?')) {
            if (valorCurso !== '' && valorTurma !== '' && valorAvaliacao !== '' && valorAluno !== '') {
                let resultadoQualitativasNovas = { Status: 200 }
                let resultadoQualitativasExistentes = { Status: 200 }
                const arrayDadosInsert = []

                for (const dado in dados) {
                    if (dado.includes('d_') && dados[dado].replaceAll(' ', '') !== '') {
                        const chave = dados.avaliacao + '1111' + dados.curso + dados.turma
                        const disciplina = dado.split('_')[1]
                        arrayDadosInsert.push([dados.aluno, chave, disciplina, dados[dado]])
                    }
                }

                let resultadoObservacoesNovas = 200   // Retorno para notificação
                let resultadoObservacoesExistentes = 200 // Retorno para notificação

                const chave = valorAvaliacao + '1111' + valorCurso + valorTurma
                const matricula = valorAluno
                //Inserir observacoes
                if (valorObservacoes !== '' && observacoesQua.length === 0) {
                    resultadoObservacoesNovas = await PostInserirObservacoes(baseUrl, chave, matricula, dados.observacoes)
                    //Atualizar observacoes
                } else if (valorObservacoes !== '' && observacoesQua.length > 0) {
                    resultadoObservacoesExistentes = await PostUpdateObservacoes(baseUrl, chave, matricula, dados.observacoes)
                }

                //Se existe notas a serem registradas
                if (arrayDadosInsert.length > 0) {
                    let notasNovas = []
                    let notasExistentes = []

                    for (const dado of arrayDadosInsert) {
                        const disciplinaDado = dado[2]
                        const matriculaDado = dado[0]
                        const find = notasQuaConsultadas.filter(item => item.disciplina === disciplinaDado && item.matricula === matriculaDado)

                        if (find.length > 0) {
                            notasExistentes.push(dado)
                        } else {
                            notasNovas.push(dado)
                        }
                    }

                    if (notasNovas.length > 0) {
                        const returnQualitativas = await PostInserirQualitativas(baseUrl, notasNovas)
                        resultadoQualitativasNovas = returnQualitativas
                    }

                    if (notasExistentes.length > 0) {
                        resultadoQualitativasExistentes = await PostUpdateQualitativas(baseUrl, notasExistentes)
                    }
                }

                if (resultadoQualitativasExistentes?.Status === 200 && resultadoQualitativasNovas?.Status === 200) {
                    window.alert('Dados registrados com sucesso')
                    sessionStorage.setItem('formQua', JSON.stringify(sessionDados))
                    window.location.reload()
                } else {
                    window.alert('Erro no registro de um ou mais dados. Favor consultar as informações selecionadas e checar a conexão de rede')
                    window.location.reload()
                }


            } else {
                window.alert('Preencha os campos obrigatorios')
            }
        }
    }

    useEffect(() => {
        Promise.all([
            GetDigitacaoProfessor(baseUrl, codProfessor),
            GetCursos(baseUrl),
            GetAvaliacoes(baseUrl),
            GetAlunos(baseUrl),
            GetGradesQua(baseUrl, codProfessor)
        ])

            .then((resultado) => {
                const [digitacaoProfessor, cursos, avaliacoes, alunos, gradesqua] = resultado
                setDigitacaoProf(digitacaoProfessor)
                setCursos(cursos)
                setAvaliacoes(avaliacoes)
                setAlunos(alunos)
                setGradesQua(gradesqua)
                setDadosConsultados(true)
            })

            .catch((erro) => {
                console.log('Erro ao consultar dados: ', erro)
            })
    }, [])

    useEffect(() => {
        console.log('sessionForm: ', sessionForm)
    }, [gradesQua])

    useEffect(async () => {
        if (valorAluno !== '') {
            const obsQuaReturn = await getObservacoesQua()
            setObservacoesQua(obsQuaReturn)
        }
    }, [valorAluno])

    useEffect(() => {
        console.log('gradesQuaCurso: ', gradesQuaCurso)
    }, [gradesQuaCurso])

    useEffect(async () => {
        if (valorGradesQua !== '') {
            const criterioAvaliativo = JSON.parse(valorGradesQua)

            setGradesQuaNivel([])
            const filtroNivel = FiltroGradesQuaPorNivel(valorCurso, criterioAvaliativo.Nivel)
            setGradesQuaNivel(filtroNivel)

            const notaQuaReturn = await getNotasQualitativas()
            setNotasQuaConsultadas(notaQuaReturn)

            const obsQuaReturn = await getObservacoesQua()
            setObservacoesQua(obsQuaReturn)

        } else {
            setGradesQuaNivel([])
        }
    }, [valorGradesQua])

    useEffect(() => {
        dadosConsultados ? FiltrarCursos() : console.log(null)
    }, [dadosConsultados])

    useEffect(() => {
        if (valorCurso !== '' && valorCurso !== '9999') {
            FiltrarTurma(valorCurso)
            FiltrarAvaliacoes(valorCurso)
        } else {
            setTurmas([])
            setAvaliacoesFiltro([])
        }
    }, [valorCurso])

    useEffect(() => {
        if (valorTurma !== '' && valorTurma !== '4444') {
            FiltrarAluno(valorCurso, valorTurma)
            setGradesQuaCurso(FiltroGradesQuaPorCurso(valorCurso))
        }
    }, [valorTurma])


    useEffect(() => {
        const observacoes = observacoesQua.length > 0 ? observacoesQua[0]?.observacao : ''
        setValorObservacoes(observacoes)
        // if(observacoesQua.length > 0){
        // }
    }, [observacoesQua])


    const onShowQualitativasLevel = (val, key) => {
        // console.log('val: ', val)
        const condicao = notasQuaConsultadas.filter(item => item.matricula === valorAluno && item.disciplina === val.Codigo).length !== 0

        if (condicao) {
            return (
                <input
                    type='text'
                    placeholder={notasQuaConsultadas?.filter(item => item.matricula === valorAluno && item.disciplina === val.Codigo)[0].conceito}
                    name={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    id={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    maxLength={4}
                    key={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    ref={register}
                    required />
            )

        } else {
            return (
                <input
                    type='text'
                    name={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    key={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    id={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    maxLength={4}
                    ref={register}
                    required />
            )
        }

    }



    return (
        <div>
            <Header />
            <div id="qualitativasContainer">
                <section>
                    <div className="info-bar">
                        <div className="title">
                            <p className="title-page">Lançar Qualitativas por Critério</p>
                            <p className="subtitle-page">Colégio {localStorage.getItem('escola').toUpperCase()}</p>
                        </div>
                        <div className="container-informativo">
                            <p className="informativo-title">Observações</p>
                            <p className="informativo-desc">Os campos Atividades de Aula e Atividades de Casa são opcionais, porém se preenchidos, serão disponibilizados para Pais e Alunos.</p>
                        </div>
                    </div>

                    <form action="" method="post">
                        <div className="container-form">

                            <label>Curso</label>
                            <select onChange={onChangeCurso} value={valorCurso} name="curso" id="curso" ref={register} required={true}>
                                <option value={9999} key='CursoBranco'></option>
                                {
                                    cursosPermitidos.map((val, key) => {
                                        return <option value={val.Codigo} key={key}>{val.Curso}</option>
                                    })
                                }
                            </select>

                            <label>Turma</label>
                            <select name="turma" id="turma" ref={register} value={valorTurma} required={true} onChange={onChangeTurma}>
                                <option value={4444} key='TurmaBranco'></option>
                                {
                                    turmas.map((val, key) => {
                                        return <option value={val} key={key}>{val}</option>
                                    })
                                }
                            </select>

                            <label>Avaliação</label>
                            <select name="avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} id="turma" ref={register} required={true}>
                                <option value='' key='TurmaBranco'></option>
                                {avaliacoesFiltro.map((val, key) => {
                                    return <option value={val.Codigo} key={key}>{val.Abreviatura}</option>
                                })}
                            </select>

                            <label>Aluno</label>
                            <select name="aluno" onChange={onChangeAluno} value={valorAluno} id="alunos" ref={register} required={true}>
                                <option value='' key='alunoEmBranco'></option>
                                {
                                    alunosPermitidos.map((val, key) => {
                                        return <option value={val.Matricula} key={key}>{val.Nome}</option>
                                    })
                                }
                            </select>

                            <label>Critério Avaliativo</label>
                            <select name="gradesQuaCurso" onChange={onChangeGradeQuaCurso} value={valorGradesQua} id="gradesQuaCurso" required={true}>
                                <option value='' key='gradesQuaCursoBranco'></option>
                                {
                                    gradesQuaCurso.map((val, key) => {
                                        return <option value={JSON.stringify(val)} key={key}>{val.Descricao}</option>
                                    })
                                }
                            </select>

                            {
                                gradesQuaNivel.length > 0 ?
                                    <label>Conceito</label> :
                                    <></>
                            }
                            <div className='nivelContainer'>
                                {
                                    gradesQuaNivel.map((val, key) => {
                                        return (
                                            <div className='nivelRowContainer' key={val + '_' + key}>
                                                {
                                                    val.Dados == 1 || val.Dados == '1' ?
                                                        <>
                                                            <div id="nivelDescricao" name={val.Codigo} ref={register}>
                                                                <p>
                                                                    {`${val.Descricao}`}
                                                                </p>
                                                                {/* <p id="nivelDescricaoDetails">{`N:${val.Nivel} | G:${val.Grupo} | SG:${val.SubGrupo}`}</p> */}
                                                            </div>
                                                            {
                                                                onShowQualitativasLevel(val)
                                                            }
                                                        </>
                                                        :
                                                        <div id="nivelTitulo">
                                                            {`${val.Descricao}`}
                                                        </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <label>Observações:</label>
                            <div id="conteudoContainer">
                                <textarea name="observacoes" id="observacoes"
                                    cols="30" rows="5" onChange={onChangeObservacoes} value={valorObservacoes} ref={register} />
                            </div>

                            <input type="submit"
                                name="button"
                                className={`button`}
                                id="confirmar"
                                value={`Lancar`}
                                onClick={handleSubmit(onSubmit)}
                                ref={register}
                                disabled={false}
                            />

                        </div>
                    </form>
                </section>
                <ScaleLoader color={"color"} loading={false} css={"override"} height={50} width={8} />
            </div>
        </div>
    )
}
