//FILTRAR DADOS EM FORMA DE CASCATA (UM PÓS O OUTRO)
export default function FiltrarDadosDiario(dados, curso, turma, disciplina, dataInicial, dataFinal) {
    const dadosDiario = dados
    const valorDataInicial = dataInicial !== '' ? new Date(dataInicial) : ''
    const valorDataFinal = dataFinal !== '' ? new Date(dataFinal) : ''

    //FILTRAR POR CURSO
    const resultadoCurso = dadosDiario.filter(
        item => curso !== '' ? item.curso_id === curso : item.curso_id
    )
    //FILTRAR POR TURMA
    const resultadoTurma = resultadoCurso.filter(
        item => turma !== '' ? item.turma_id === turma : item.turma_id
    )
    //FILTRAR POR DISCIPLINA
    const resultadoDisciplina = resultadoTurma.filter(
        item => {
            if(disciplina === ''){          
                return item
            } else {
                if(disciplina === 'POLIVALENTE'){
                    return item.Disciplina === 'Polivalente'
                }
                return item.disciplina_id === disciplina
            }
        }
    )
    //FILTRAR POR DATA INICIAL
    const resultadoDataInicial = resultadoDisciplina.filter(
        item => dataInicial !== '' ? new Date(item.data) >= valorDataInicial : item.data
    )
    //FILTRAR POR DATA FINAL
    const resultadoDataFinal = resultadoDataInicial.filter(
        item => dataFinal !== '' ? new Date(item.data) <= valorDataFinal : item.data
    )
    //PEGAR ULTIMO DADO FILTRADO
    const resultadoDados = resultadoDataFinal
    //RETORNAR 
    return resultadoDados
}