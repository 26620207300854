export const faixaDataMes = (idMes) => {
    const anoPermitido = localStorage.getItem('Year')
    if (idMes === 1) {
        return { dataInicial: `${anoPermitido}-01-01`, dataFinal: `${anoPermitido}-01-31`, nomeMes: 'JANEIRO' }

    } else if (idMes === 2) {
        return { dataInicial: `${anoPermitido}-02-01`, dataFinal: `${anoPermitido}-02-29`, nomeMes: 'FEVEREIRO' }

    } else if (idMes === 3) {
        return { dataInicial: `${anoPermitido}-03-01`, dataFinal: `${anoPermitido}-03-31`, nomeMes: 'MARÇO' }

    } else if (idMes === 4) {
        return { dataInicial: `${anoPermitido}-04-01`, dataFinal: `${anoPermitido}-04-30`, nomeMes: 'ABRIL' }

    } else if (idMes === 5) {
        return { dataInicial: `${anoPermitido}-05-01`, dataFinal: `${anoPermitido}-05-31`, nomeMes: 'MAIO' }

    } else if (idMes === 6) {
        return { dataInicial: `${anoPermitido}-06-01`, dataFinal: `${anoPermitido}-06-30`, nomeMes: 'JUNHO' }

    } else if (idMes === 7) {
        return { dataInicial: `${anoPermitido}-07-01`, dataFinal: `${anoPermitido}-07-31`, nomeMes: 'JULHO' }

    } else if (idMes === 8) {
        return { dataInicial: `${anoPermitido}-08-01`, dataFinal: `${anoPermitido}-08-31`, nomeMes: 'AGOSTO' }

    } else if (idMes === 9) {
        return { dataInicial: `${anoPermitido}-09-01`, dataFinal: `${anoPermitido}-09-30`, nomeMes: 'SETEMBRO' }

    } else if (idMes === 10) {
        return { dataInicial: `${anoPermitido}-10-01`, dataFinal: `${anoPermitido}-10-31`, nomeMes: 'OUTUBRO' }

    } else if (idMes === 11) {
        return { dataInicial: `${anoPermitido}-11-01`, dataFinal: `${anoPermitido}-11-30`, nomeMes: 'NOVEMBRO' }

    } else if (idMes === 12) {
        return { dataInicial: `${anoPermitido}-12-01`, dataFinal: `${anoPermitido}-12-28`, nomeMes: 'DEZEMBRO' }
    }
}