import Axios from 'axios'

export default async function PostInserirObservacoes(BaseUrl, chave, matricula, observacoes){
    let resultado = await Axios.post(`${BaseUrl}/notas/inserirObservacoes`, {
        chave: chave,
        matricula: matricula,
        observacoes: observacoes
    })

    return resultado.data  
}