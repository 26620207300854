import React, { useEffect, useState } from 'react'
import Visibility from '@material-ui/icons/Visibility'
import SearchIconAnimated from '../../images/Search-1s-200px.svg'
import './QualitativasTabela.css'

export default function QualitativasTabela({ bodyDataRows, headerTableTitles, bodyTableFields, filterBy, searchStatusIcon }) {

    const [pessoasFiltro, setPessoasFiltro] = useState([])
    const [searchStatus, setSearchStatus] = useState(searchStatusIcon)
    const tableTitles = headerTableTitles
    const tableFields = bodyTableFields
    const filterByProp = filterBy

    useEffect(() => {
        if (bodyDataRows.length > 0) {
            setSearchStatus(false)
            setPessoasFiltro(bodyDataRows)
        }
    }, [bodyDataRows])

    useEffect(() => {
        setSearchStatus(searchStatusIcon)
    }, [searchStatusIcon])

    const onClickPessoa = async (dado) => {
        sessionStorage.setItem('d', JSON.stringify(dado))
        window.location.replace('/Admin/Qualitativas/Dados')
    }

    return (
        <div className='NotasTabelaDashContainer'>
            {
                searchStatus === false ?
                    <div className='NotasTabelaDashTable'>
                        <table >
                            <thead>
                                <tr>
                                    {tableTitles?.map(item => (
                                        <th>{item}</th>
                                    )
                                    )}
                                </tr>
                            </thead>

                            <tbody >
                                {pessoasFiltro?.map((val, key) => {
                                    return (
                                        <tr id={"Row" + key} name={val[filterByProp]} >
                                            {tableFields?.map((item, key) => (
                                                <td name={item} >
                                                    {
                                                        new Date(val[item]?.toString()) instanceof Date && !isNaN(new Date(val[item]?.toString())) ? //Valida se é data
                                                            new Date(val[item]?.toString()).toLocaleString().split(',')[0] : //Formata para data local caso verdadeiro
                                                            val[item]?.toString()?.toUpperCase() //Texto em caixa alta caso não seja data
                                                    }
                                                </td>
                                            )
                                            )}
                                            <Visibility id="IconViewNotas" onClick={() => onClickPessoa(val)} />
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div id='ContainerIconSearchLoadingStatusNotas'>
                        <p>
                            Procurando...
                        </p>
                        <img src={SearchIconAnimated} alt='Search Icon' />
                    </div>
            }
        </div>
    )
}