//FILTRAR DADOS EM FORMA DE CASCATA (UM PÓS O OUTRO)
export default function FiltrarDadosQualitativas(dados, curso, turma, avaliacao) {
    //PEGAR DADOS GERAIS
    const dadosDiario = dados
    console.log('dados: ', dados)
    console.log('Avaliação: ', avaliacao, ' | ', typeof(avaliacao), ' | ', 'length: ', avaliacao.length)
    
    //FILTRAR POR CURSO
    const resultadoCurso = dadosDiario.filter(
        item => curso !== '' ? item.CodCurso === curso : item.CodCurso
    )
    console.log('ResultadoCurso: ', resultadoCurso)
    //FILTRAR POR TURMA
    const resultadoTurma = resultadoCurso.filter(
        item => turma !== '' ? item.Turma === turma : item.Turma
    )
    console.log('ResultadoTurma: ', resultadoTurma)
    //FILTRAR AVALIACAO...
    const resultadoAvaliacao = resultadoTurma.filter(
        item => avaliacao !== '' ? item.CodAvaliacao === avaliacao : item.CodAvaliacao
    )
    console.log('ResultadoAvaliacao: ', resultadoAvaliacao)

    //PEGAR ULTIMO DADO FILTRADO
    const resultadoDados = resultadoAvaliacao
    //RETORNAR 
    return resultadoDados
}