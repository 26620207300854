import Axios from 'axios'

export default async function PostUpdateObservacoes(BaseUrl, chave, matricula, observacoes){
    let resultado = await Axios.post(`${BaseUrl}/notas/updateObservacoes`, {
        chave: chave,
        matricula: matricula,
        observacoes: observacoes
    })

    return resultado.data  
}