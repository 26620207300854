import GetCursos from "../services/GetCursos"
import GetDisciplina from "../services/GetDisciplinas"
import GetAlunos from "../services/GetAlunos"
import GetDigitacao from "../services/GetDigitacao"

const PesquisarDados = async (BaseUrl) => {
    const [cursosDados, disciplinas, alunos, digitacao] = await Promise.all([
        GetCursos(BaseUrl),
        GetDisciplina(BaseUrl),
        GetAlunos(BaseUrl),
        GetDigitacao(BaseUrl)
    ])

    return {
        cursosDado: cursosDados,
        disciplinasDado: disciplinas,
        alunosDado: alunos,
        digitacaoDado: digitacao
    }
}

export default PesquisarDados