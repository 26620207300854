import './Diario.css'
import React, { useState, useEffect } from 'react'

import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import DiarioTabela from '../../../components/DiarioTabela/DiarioTabela'

import GetCursos from '../../../services/GetCursos'
import GetDisciplina from '../../../services/GetDisciplinas'
import GetTurmas from '../../../services/GetTurmas'
import GetConteudos from '../../../services/GetConteudos'
import SelectAPI from '../../../Functions/SelectApi'

import FiltrarDadosDiario from '../../../Functions/FiltrarDadosDiario'

export default function Diario() {
    const [conteudos, setConteudos] = useState([])
    const [cursos, setCursos] = useState([])
    const [disciplinas, setDisciplinas] = useState([])
    const [turmas, setTurmas] = useState([])
    const [valorCurso, setValorCurso] = useState('')
    const [valorDisciplina, setValorDisciplina] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorDataInicial, setValorDataInicial] = useState('')
    const [valorDataFinal, setValorDataFinal] = useState('')
    const [filtroTurmas, setFiltroTurmas] = useState([])
    const [filtroConteudos, setFiltroConteudos] = useState([])
    const [valorEscola, setValorEscola] = useState(localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola')
    const [userCursos, setUserCursos] = useState(value => value = sessionStorage.getItem('c'))
    const [searchStatusBarIcon, setSearchStatusBarIcon] = useState(true)
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    let baseUrl = 'http://localhost:3001'
    let getDisciplinas = []

    const titlesHeader = [
        "CURSO",
        "TURMA",
        "DISCIPLINA",
        "DATA"
    ]

    const fieldsBody = [
        "Curso",
        "turma_id",
        "Disciplina",
        "data"
    ]

    const onChangeCurso = async (event) => {
        let valorCursoAtual = event.target.value
        setValorCurso(valorCursoAtual)

        let filtroTurma = turmas.filter(item => item.Curso == valorCursoAtual)
        setFiltroTurmas(filtroTurma)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCursoAtual,
            valorTurma,
            valorDisciplina,
            valorDataInicial,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
    }

    const onChangeDisciplina = async (event) => {
        let valorDisciplinaAtual = event.target.value
        setValorDisciplina(valorDisciplinaAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurma,
            valorDisciplinaAtual,
            valorDataInicial,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
    }

    const onChangeTurma = async (event) => {
        let valorTurmaAtual = event.target.value
        setValorTurma(valorTurmaAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurmaAtual,
            valorDisciplina,
            valorDataInicial,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
    }

    const onChangeDataInicio = async (event) => {
        let valorDataInicioAtual = event.target.value
        setValorDataInicial(valorDataInicioAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurma,
            valorDisciplina,
            valorDataInicioAtual,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
    }

    const onChangeDataFinal = async (event) => {
        let valorDataFinalAtual = event.target.value
        setValorDataFinal(valorDataFinalAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurma,
            valorDisciplina,
            valorDataInicial,
            valorDataFinalAtual
        )
        setFiltroConteudos(filtroDados)
    }

    async function getCurso() {
        let curso = await GetCursos(baseUrl)
        setCursos(curso)
    }

    async function getDisciplina() {
        let disciplina = await GetDisciplina(baseUrl)
        getDisciplinas = disciplina
        setDisciplinas(value => value = disciplina)
    }

    async function getTurma() {
        let turmas = await GetTurmas(baseUrl)
        setTurmas(turmas)
    }

    function FiltrarDisciplinas(conteudos, disciplinas){
        let novoConteudo = []

        conteudos.filter( (curso, index) => {
           const resultado = disciplinas.filter(disciplina => disciplina.Codigo === curso?.disciplina_id)[0]   
           novoConteudo.push(curso)

           if(resultado?.Disciplina !== undefined){
               novoConteudo[index]['Disciplina'] = resultado?.Disciplina
           } else {
               novoConteudo[index]['Disciplina'] = 'Polivalente'
           }
           //console.log(resultado !== undefined ? resultado : 'Poli')
           }
       )

       return novoConteudo
    }

    async function getConteudo() {
        let conteudoConsulta = await GetConteudos(baseUrl)
        conteudoConsulta = conteudoConsulta?.filter(item => userCursos?.includes(item.curso_id))
        const conteudoComDisciplinas = FiltrarDisciplinas(conteudoConsulta, getDisciplinas)
        
        setConteudos(conteudoComDisciplinas)
        setFiltroConteudos(conteudoComDisciplinas)
        setSearchStatusBarIcon(value => value = false)
    }

    function cursosUserPermissao() {
        const permitidos = userCursos?.split(';')?.filter(item => item !== '')
        setUserCursos(permitidos)
    }

    useEffect(() => {
        baseUrl = SelectAPI(valorEscola)
    }, [valorEscola])

    useEffect(() => {
        getCurso()
        getDisciplina()
        getTurma()
        getConteudo()
        cursosUserPermissao()
    }, [])

    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='DiarioGeneralAdminContainer'>
                    <SideBar />
                    <div className='DiarioAdminContainer'>
                        <Header />

                        <div id="DiarioTitlePage">
                            DIARIO
                        </div>

                        <div className='DiarioSearchContainer'>

                            <select name="Curso" id="Curso" onChange={onChangeCurso} value={valorCurso} >
                                <option value='' id="Curso01">Curso</option>
                                {
                                    cursos.map((val, key) => {
                                        return (
                                            userCursos?.includes(val?.Codigo) === true ?
                                                <option value={val?.Codigo} key={key} id="Curso01">{val?.Curso}</option>
                                                : <></>
                                        )
                                    })
                                }
                            </select>

                            <select name="Turma" id="Turma" onChange={onChangeTurma} value={valorTurma} >
                                <option value='' id="Turma01">Turma</option>
                                {
                                    filtroTurmas.map((val, key) => {
                                        return (
                                            <option value={val.Turma} key={key} id="Turma01">{val.Turma}</option>
                                        )
                                    })
                                }
                            </select>

                            <select name="Disciplina" id="Disciplina" onChange={onChangeDisciplina} value={valorDisciplina} >
                                <option value='' >Disciplina</option>
                                {
                                    disciplinas.map((val, key) => {
                                        return (
                                            <option value={val.Codigo} key={key} id="Matematica">{val.Disciplina}</option>
                                        )
                                    })
                                }
                                <option value="POLIVALENTE" key="POLIVALENTE" id="POLIVALENTE"> POLIVALENTE </option>
                            </select>

                            <label id='labelInicioDiarioData'>
                                Início
                            </label>
                            <input type='date' onChange={onChangeDataInicio} value={valorDataInicial} />

                            <label id='labelFimDiarioData'>
                                Fim
                            </label>
                            <input type='date' onChange={onChangeDataFinal} value={valorDataFinal} />

                        </div>

                        <DiarioTabela
                            searchBarShow={false}
                            headerTableTitles={titlesHeader}
                            bodyTableFields={fieldsBody}
                            bodyDataRows={filtroConteudos}
                            searchStatusIcon={searchStatusBarIcon}
                        />
                    </div>
                </div>
            </>
        )
    }
}