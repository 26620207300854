import React, { useState, useEffect } from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import NotasTabela from '../../../components/NotasTabela/NotasTabela'
import NotasTabelaConceito from '../../../components/NotasTabelaConceito/NotasTabelaConceito'
import FiltrarDadosNotas from '../../../Functions/FiltrarDadosNotas'
import GetAllConceitos from '../../../services/getAllConceitos'
import GetCursos from '../../../services/GetCursos'
import GetDisciplinas from '../../../services/GetDisciplinas'
import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetTurmas from '../../../services/GetTurmas'
import uniqueArray from '../../../Functions/FiltrarArrayDeObjetos'
import SelectAPI from '../../../Functions/SelectApi'
import ButtonDark from '../../../components/ButtonDark/ButtonDark'
import GetAllNotasExportAdmin from '../../../services/GetAllNotasExportAdmin'
import DeleteFolderExportNotas from '../../../services/DeleteFolderExportNotas'
import './Conceito.css'

let cursosResult = []
let disciplinasResult = []
let turmasResult = []
let avaliacoesResult = []

export default function Conceito() {
    const [allCursos, setAllCursos] = useState([])
    const [cursos, setCursos] = useState([])
    const [disciplinas, setDisciplinas] = useState([])
    const [AllDisciplinas, setAllDisciplinas] = useState([])
    const [turmas, setTurmas] = useState([])
    const [allTurmas, setAllTurmas] = useState([])
    const [valorCurso, setValorCurso] = useState('')
    const [valorDisciplina, setValorDisciplina] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [notas, setNotas] = useState([])
    const [notasDetails, setNotasDetails] = useState([])
    const [filtroNotasDetails, setFiltroNotasDetails] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])
    const [allAvaliacoes, setAllAvaliacoes] = useState([])
    const [valorAvaliacao, setValorAvaliacao] = useState('')
    const [searchStatusBarIcon, setSearchStatusBarIcon] = useState(true)
    const [userCursos, setUserCursos] = useState(value => value = sessionStorage.getItem('c'))
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [darkButtonDisabled, setDarkButtonDisabled] = useState(false)
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    const userId = sessionStorage.getItem('idUser') !== null ? sessionStorage.getItem('idUser') : 'false'
    const baseUrl = SelectAPI(valorEscola)

    let cursosPush = []
    let turmasPush = []
    let disciplinasPush = []
    let avaliacoesPush = []
    let allNotas = []
    let notasDetailsPush = []

    const titlesHeader = [
        "CURSO",
        "TURMA",
        "DISCIPLINA",
        "AVALIAÇÃO",
        ""
    ]

    console.log('userId:', userId)

    const fieldsBody = [
        "Curso",
        "Turma",
        "Disciplina",
        "Avaliacao"
    ]

    function FiltrarCurso(dado) {
        const findCurso = dado.slice(0, 3)
        return findCurso
    }

    function FiltrarDisciplina(dado) {
        const findDisciplina = dado.slice(4, 7)
        return findDisciplina
    }

    function FiltrarTurma(dado) {
        const findTurma = dado.slice(3, 4)
        return findTurma
    }

    function FiltrarAvaliacao(dado) {
        const findAvaliacao = dado.slice(7, 11)
        return findAvaliacao
    }

    function cursosUserPermissao() {
        if (typeof (userCursos) === 'string') {
            const permitidos = userCursos?.split(';')?.filter(item => item !== '')
            setUserCursos(value => value = permitidos)
            return permitidos
        }
    }

    function FiltrarItemsPermitidos(dados, userCursosPermissao) {
        const resultado = dados?.filter(item => userCursosPermissao?.includes(item.CodCurso))
        setSearchStatusBarIcon(value => value = false)
        return resultado
    }

    function SepararDadosChave() {
        notasDetailsPush = []

        console.log('ALLNOTAS: ', allNotas)

        for (let nota of allNotas) {
            //FILTRAR CÓDIGOS SEPARADAMENTE (CURSO, TURMA, DISCIPLINA, AVALIACAO)
            let CodCursoResult = FiltrarCurso(nota)
            let CodDisciplinaResult = FiltrarDisciplina(nota)
            let CodAvaliacaoResult = FiltrarAvaliacao(nota)
            let filtroTurmaResult = FiltrarTurma(nota)

            //FILTRAR DESCRIÇÃO (NOME) DOS ITENS: CURSO, DISCIPLINA E AVALIACAO
            let cursoFiltro = cursosResult.filter(item => item.Codigo === CodCursoResult)[0]?.Curso
            let disciplinaFiltro = disciplinasResult.filter(item => item.Codigo === CodDisciplinaResult)[0]?.Disciplina
            let avaliacoesFiltro = avaliacoesResult.filter(item => item.Codigo === CodAvaliacaoResult)[0]?.Abreviatura

            //ARMAZENAR EM VARIAVEIS DE ACORDO COM CADA OBJETIVO
            notasDetailsPush.push({
                CodCurso: CodCursoResult,
                Curso: cursoFiltro,
                CodDisciplina: CodDisciplinaResult,
                Disciplina: disciplinaFiltro,
                Turma: filtroTurmaResult,
                CodAvaliacao: CodAvaliacaoResult,
                Avaliacao: avaliacoesFiltro,
                Codigo: nota
            })

            cursosPush.push({ Codigo: CodCursoResult, Curso: cursoFiltro })
            disciplinasPush.push({ Codigo: CodDisciplinaResult, Disciplina: disciplinaFiltro })
            turmasPush.push(filtroTurmaResult)
            avaliacoesPush.push({ Codigo: CodAvaliacaoResult, Avaliacao: avaliacoesFiltro })
            notasDetailsPush.push()
        }

        //ARMAZENAR VARIAVEIS EM ESTADOS
        setNotasDetails(notasDetailsPush) // DADOS GERAIS PARA TABELA
        const permitidos = cursosUserPermissao()
        const filtrarNotasPermitidas = FiltrarItemsPermitidos(notasDetailsPush, permitidos)
        setFiltroNotasDetails(filtrarNotasPermitidas) // DADOS GERAIS PARA FILTRAR NA TABELA APÓS SELECT

        setAllCursos(uniqueArray(cursosPush))
        setAllDisciplinas(uniqueArray(disciplinasPush))
        setTurmas([...new Set(turmasPush)])
        setAllAvaliacoes(uniqueArray(avaliacoesPush))
    }

    const onChangeCurso = async (event) => {
        let valorCursoAtual = event.target.value
        setValorCurso(valorCursoAtual)

        const filtroDados = await FiltrarDadosNotas(
            notasDetails,
            valorCursoAtual,
            valorTurma,
            valorDisciplina,
            valorAvaliacao
        )

        const filtrarNotasPermitidas = FiltrarItemsPermitidos(filtroDados, userCursos)
        setFiltroNotasDetails(filtrarNotasPermitidas)
    }

    const onChangeDisciplina = async (event) => {
        let valorDisciplinaAtual = event.target.value
        setValorDisciplina(valorDisciplinaAtual)

        const filtroDados = await FiltrarDadosNotas(
            notasDetails,
            valorCurso,
            valorTurma,
            valorDisciplinaAtual,
            valorAvaliacao
        )
        setFiltroNotasDetails(filtroDados)
    }

    const onChangeTurma = async (event) => {
        let valorTurmaAtual = event.target.value
        setValorTurma(valorTurmaAtual)

        const filtroDados = await FiltrarDadosNotas(
            notasDetails,
            valorCurso,
            valorTurmaAtual,
            valorDisciplina,
            valorAvaliacao
        )
        setFiltroNotasDetails(filtroDados)
    }

    const onChangeAvaliacao = async (event) => {
        let valorAvaliacaoAtual = event.target.value
        setValorAvaliacao(event.target.value)

        const filtroDados = await FiltrarDadosNotas(
            notasDetails,
            valorCurso,
            valorTurma,
            valorDisciplina,
            valorAvaliacaoAtual
        )
        setFiltroNotasDetails(filtroDados)
    }

    async function getAllConceitos() {
        allNotas = await GetAllConceitos(baseUrl)
        setNotas(allNotas)
        return allNotas
    }

    async function getAvaliacoes() {
        avaliacoesResult = await GetAvaliacoes(baseUrl)
        setAvaliacoes(avaliacoesResult)
        return avaliacoesResult
    }

    async function getCursos() {
        cursosResult = await GetCursos(baseUrl)
        setCursos(cursosResult)
        return cursosResult
    }

    async function getDisciplinas() {
        disciplinasResult = await GetDisciplinas(baseUrl)
        setDisciplinas(disciplinasResult)
        return disciplinasResult
    }

    async function getTurmas() {
        turmasResult = await GetTurmas(baseUrl)
        setAllTurmas(turmasResult)
        return turmasResult
    }

    const onClickBaixar = async () => {
        const selectCurso = valorCurso !== '' ? valorCurso : '%'
        const selectTurma = valorTurma !== '' ? valorTurma : '%'
        const selectDisciplina = valorDisciplina !== '' ? valorDisciplina : '%'
        const selectAvaliacao = valorAvaliacao !== '' ? valorAvaliacao : '%'
        const chave = selectCurso + selectTurma + selectDisciplina + selectAvaliacao
        console.log('OnClickBaixar foi clicado', chave)
        setDarkButtonDisabled(true)

        setTimeout( ()=> {
            setDarkButtonDisabled(false)
            DeleteFolderExportNotas(baseUrl)
        }, 20000)
        
        setTimeout(()=>{
            setModalIsOpen(false)
        }, 3000)
        
        const exportResult = GetAllNotasExportAdmin(baseUrl, chave, userId)
        console.log('ExportResult', exportResult)
        // const deleteResult = DeleteFolderExportNotas(baseUrl, chave)
    }

    useEffect(() => {

        Promise.allSettled([
            getAllConceitos(),
            getAvaliacoes(),
            getCursos(),
            getDisciplinas(),
            getTurmas()
        ])
            .then(() => {
                SepararDadosChave()
            })
            .catch((err) => {
                if (err) {
                    console.log('Algum erro nas promises all', err)
                }
            })
    }, [])

    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='NotasGeneralContainerAdmin'>
                    <SideBar />
                    <div className='NotasContainerAdmin'>
                        <Header />

                        <div id="NotasTitlePage">
                            CONCEITO
                        </div>

                        <div className='NotasSearchContainer'>

                            <select name="Curso" id="Curso" onChange={onChangeCurso} value={valorCurso} >
                                <option value='' id="Curso01">Curso</option>
                                {
                                    allCursos.map((val, key) => {
                                        return (
                                            userCursos.includes(val.Codigo) === true ?
                                                <option value={val.Codigo} key={key} id="Curso01">{val.Curso}</option>
                                                : <></>
                                        )
                                    })
                                }
                            </select>
                            <select name="Turma" id="Turma" onChange={onChangeTurma} value={valorTurma} >
                                <option value='' id="Turma01">Turma</option>
                                {
                                    turmas.map((val, key) => {
                                        return (
                                            <option value={val} key={key} id="Turma01">{val}</option>
                                        )
                                    })
                                }
                            </select>
                            <select name="Disciplina" id="Disciplina" onChange={onChangeDisciplina} value={valorDisciplina} >
                                <option value='' >Disciplina</option>
                                {
                                    AllDisciplinas.map((val, key) => {
                                        return (
                                            <option value={val.Codigo} key={key} id="Matematica">{val.Disciplina}</option>
                                        )
                                    })
                                }
                            </select>
                            <select name="Avaliacao" id="Avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} >
                                <option value='' >Avaliação</option>
                                {
                                    allAvaliacoes.sort().map((val, key) => {
                                        return (
                                            <option value={val.Codigo} key={key} id={val}>{val.Avaliacao}</option>
                                        )
                                    })
                                }
                            </select>

                        </div>

                        <NotasTabelaConceito
                            searchBarShow={false}
                            headerTableTitles={titlesHeader}
                            bodyDataRows={filtroNotasDetails}
                            bodyTableFields={fieldsBody}
                            searchStatusIcon={searchStatusBarIcon}
                        />

                        <div className='button-Info-Container'>
                            {/* <ButtonDark
                                ButtonName={'EXPORTAR'}
                                Title={'BAIXAR NOTAS'}
                                Description={'Deseja realmente baixar as notas selecionadas?'}
                                Action={onClickBaixar}
                                modalIsOpened={modalIsOpen}
                                Disabled={darkButtonDisabled}
                            /> */}
                            <div id='registros-Notas-Info'>
                                Registros: {filtroNotasDetails.length}
                            </div>
                        </div>

                    </div>

                </div>
            </>
        )
    }
}