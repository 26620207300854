import OrdemItem from './OrdenarItens'

export default function FiltrarCurso(permissao, curso) {
    let filtroCurso = []
    let cursosDistinct = [...new Set(permissao.map(item => item.CodCurso))]

    for (let idCurso of cursosDistinct) {
        //Varrer curso
        for (let valor of curso) {
            if (valor.Codigo == idCurso) {
                filtroCurso.push(valor)
            }
        }
    }

    let ordemItems = OrdemItem(filtroCurso, "Codigo")

    return ordemItems
}