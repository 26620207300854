import React from 'react'
import './SideBar.css'
import { SideBarData } from './SideBarData'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Logomarca from '../../images/Logomarca.png'

export default function SideBar() {
    const path = window.location.pathname
    const schoolName = localStorage.getItem('escola')

    const onSair = () => {
        sessionStorage.clear()
        localStorage.clear()
        window.location.replace('/Admin/Login')
    }

    return (
        <div className='SideBar' key={'SideBar'}>
            <div>
                <header key="CabecalhoSideBar">
                    <img src={Logomarca} width="50" alt='LogoMarca' />
                    <div key="Escola" id="Escola">{schoolName}</div>
                    <div key="Cargo" id="Cargo">Administrativo</div>
                    <hr />
                </header>
                <ul className='SideBarList' key="SideBarList">
                    {
                        SideBarData.map((val, key) => {
                            return (
                                val.active === 'Active' ?
                                    <li
                                        key={`${val.Codigo}_${key}`}
                                        className="row"
                                        id={path === val.link ? "active" : ""}
                                        onClick={() => {
                                            window.location.replace(val.link)
                                        }}>
                                        <div key={key+"_icon"} id="icon">{val.icon}</div>
                                        <div key={key+"_title"} id="title">{val.title}</div>
                                    </li> :
                                    null
                            )
                        })
                    }
                </ul>
            </div>

            <button id="SairButtonSideBarAdmin" onClick={onSair}>
                <ExitToApp />
                SAIR
            </button>
        </div>
    )
}