import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header/Header'
import CardYellow from '../../../components/CardYellow/CardYellow'
import CardBlue from '../../../components/CardBlue/CardBlue'
import CardGreen from '../../../components/CardGreen/CardGreen'
import SideBar from '../../../components/SideBar/SideBar'
import TabelaPessoas from '../../../components/TabelaPessoas/TabelaPessoas'
import GetAlunos from '../../../services/GetAlunos'
import SelectAPI from '../../../Functions/SelectApi'
import GetUsersById from '../../../services/GetUserById'
import './Home.css'

export default function Home() {
    const [alunos, setAlunos] = useState([])
    const [user, setUser] = useState([])
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    const userId = sessionStorage.getItem('idUser') !== null ? sessionStorage.getItem('idUser') : 'false'
    const baseUrl = SelectAPI(valorEscola)
    const tabelaAlunosNome = 'PESQUISAR ALUNOS'
    const tabelaAlunosPlaceHolder = 'Nome do aluno'

    const tabelaAlunosHeaderFields = [
        'Matrícula',
        'Nome',
        'Curso',
        'Turma'
    ]
    
    const tabelaAlunosBodyFields = [
        'Matricula',
        'Nome',
        'CursoNome',
        'Turma'
    ]    

    async function Alunos() {
        setAlunos(await GetAlunos(baseUrl))
    }

    async function GetUserInfoById() {
        let userResult = await GetUsersById(baseUrl, userId)
        setUser(value => value = userResult)
        console.log('UserResult', userResult)
        sessionStorage.setItem('c', userResult[0]?.Cursos)
    }

    useEffect(() => {
        Alunos()
        GetUserInfoById()
    }, [])

    if(logado !== 'true'){
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
        
    } else {
        return (
            <>
                <div id='HomePageGeneralContainer'>
                    <SideBar />
                    <div id='HomePageContainer'>
                        <Header />
                        <div className='HomePageCardsContainer'>
                            <CardBlue />
                            <CardGreen />
                            <CardYellow />
                        </div>
    
                        <TabelaPessoas
                            searchBarTitle={tabelaAlunosNome}
                            searchBarInputPlaceHolder={tabelaAlunosPlaceHolder}
                            headerTableTitles={tabelaAlunosHeaderFields}
                            bodyTableFields={tabelaAlunosBodyFields}
                            bodyDataRows={alunos}
                        />
                    </div>
                </div>
            </>
        )
    }
    
}