import Axios from 'axios'

export default async function PostLiberarBloquearNotas(
    BaseUrl = '',
    codCurso = '',
    codDisciplina = '',
    codAvaliacao = '',
    turma = '',
    checkTodasNotas = false
    ){
        
    const resultado = await Axios.post(`${BaseUrl}/notas/liberarNotas`, {
        codCurso: codCurso,
        codDisciplina: codDisciplina,
        codAvaliacao: codAvaliacao,
        turma: turma,
        checkTodasNotas: checkTodasNotas
    })

    return resultado.data  
}