import React, { useState, useEffect } from 'react'
import './NotasTabelaGeral.css'

export default function TabelaGeral(
    { bodyDataRows, headerTableTitles, bodyTableFields }
) {
    const [dataRow, setDataRow] = useState(bodyDataRows)
    const [headerTitles, setHeaderTitles] = useState(headerTableTitles)
    const [tableFields, setTableFields] = useState(bodyTableFields)

    useEffect(() => {
        setDataRow(bodyDataRows)
    }, [bodyDataRows])

    return (
        <div className='NotasTabelaGeralContainer'>
            <div className='NotasTabelaGeralTable'>
                <div className='NotasTabelaGeralHeaderTable'>
                        {headerTitles?.map(item => (
                            <div id="itemHeaderNotasTabelaGeralDashRow">{item}</div>
                        )
                        )}
                        <div id="srollInvisibleHeadItem"></div>
                </div>
            </div>

            <div className="NotastabelaGeralBodyDataContainer">
                {dataRow?.map((val, key) => {
                    return (

                        <div id="NotasTabelaGeralDashRow" >
                            {tableFields?.map((item, index) => (
                                isNaN(val[item]) === false ?
                                    <div id="itemBodyNotasTabelaGeralDashRow" key={`${key}${index}`}> {val[item]} </div>
                                    : <div id="itemBodyNotasTabelaGeralDashRow" key={`${key}${index}`}> {val[item].toUpperCase()} </div>
                            )
                            )}
                        </div>
                    )
                })}
            </div>
                
        </div>
    )
}