import './GerarDiario.css'
import React, { useEffect, useState } from 'react'
import Header from '../../../components/header'
import Axios from 'axios'
import { useForm } from 'react-hook-form'
import { Usuario } from '../../../context/userForm'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import PesquisarDados from '../../../Functions/PesquisarDados'
import FiltrarCurso from '../../../Functions/FiltrarCurso'
import GetGrades from '../../../services/GetGrades'
import GetEmpresa from '../../../services/GetEmpresa'
import OrdemItem from '../../../Functions/OrdenarItens'
import useBasicForm from '../../../hooks/useBasicForm'
import { faixaDataMes } from '../../../Functions/FaixaDataMes'
import { Meses } from '../../../Functions/Meses'
const { ApiFilter } = require('../../../Functions/FilterApiDomain')


export default function GerarDiario() {

    const { form, setForm } = Usuario()
    const { isLoged, setIsLoged } = Usuario()
    //VALORES GERAIS
    const [polivalenteStatus, setPolivalenteStatus] = useState(sessionStorage.getItem('Poli'))
    const [escola, setEscola] = useState(localStorage.getItem('escola').toUpperCase())
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [idProfessor, setIdProfessor] = useState(sessionStorage.getItem('professores'))
    const [DataCompleta, setDataCompleta] = useState(new Date().toLocaleDateString().split('/'))
    const [anoPermissao, setAnoPermissao] = useState('')
    const [DataAno, setDataAno] = useState('')
    const [DataMes, setDataMes] = useState('')
    const [DataDia, setDataDia] = useState('')
    const [aluno, setAluno] = useState([]);
    const [curso, setCurso] = useState([]);
    const [digitacao, setDigitacao] = useState([]);
    const [disciplina, setDisciplina] = useState([]);
    const [faltasAlunos, setFaltasAlunos] = useState([]);
    const [cursosFiltro, setCursosFiltro] = useState([])
    const [disciplinasFiltro, setDisciplinasFiltro] = useState([])
    const [consultaConteudo, setConsultaConteudo] = useState([])
    const [grades, setGrades] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [valorEmpresa, setValorEmpresa] = useState([])
    //VALORES FILTRADOS
    const [permissao, setPermissao] = useState([]);
    const [disciplinaFilter, setDisciplinaFilter] = useState([])
    const [cursoFilter, setCursoFilter] = useState([])
    const [turmaFiltro, setTurmaFiltro] = useState([])
    const [alunosPermitidos, setAlunosPermitidos] = useState([])

    const [valorData, setValorData] = useState('')
    const [valorDataFinal, setValorDataFinal] = useState('')
    const [valorCurso, setValorCurso] = useState([]);
    const [valorDisciplina, setValorDisciplina] = useState([]);
    const [valorTurma, setValorTurma] = useState([])
    const [data, setData] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [valorMes, setValorMes] = useState('')

    //FORMULARIO
    const { register, handleSubmit, errors } = useForm()
    const [session, setSession] = useState(sessionStorage)
    // const { alunosFiltro, avaliacoesNotasFiltro, pesquisarDadosForm, disciplinasFiltroForm, turmasFiltro, changeCursoForm, useOnChangeTurma } = useBasicForm()
    //VARIÁVEIS AUXILIARES
    const anoPermitido = localStorage.getItem('Year')
    let logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'
    let idProf = form.length > 0 ? form.professores : session.professores //Index dos ids retornado inicia no valor 0. Colocar +1 pra nivelar com valor real do id do banco
    let profId = parseInt(form.length > 0 ? form.professores : session.professores)
    let nomeProfessor = sessionStorage.getItem('nome') !== null ? sessionStorage.getItem('nome') : ''
    let permissaoFiltro = []
    let disciplinaDistinct = []
    let disciplinaPermissao = []
    let disciplinaFiltro = []
    let turmaPermissao = []
    let turmaDistinct = []
    let PrimeiroTurma = []
    let alunosPermissao = []
    let alunosPermitidosNomeOrdem = []
    let alunosPermitidosPush = []
    let faltasAlunosPush = []
    let nomeMes = ''

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState('#19648C');
    const override = css`
      background-color: rgba(0,0,0,0.3);
      position: absolute;
      margin-top: -5vh;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: red;
      width: 100%;
      height: 100%;
      cursor: progress;
    `;

    sessionStorage.getItem('isLoged') ? setIsLoged(true) : setIsLoged(false)

    //NOTIFICAÇÕES
    function notifyWarnGeracao() {
        toast.warn('Favor informar data inicial e final', { autoClose: 6000, position: toast.POSITION.TOP_CENTER });
    }

    function notifyErrorGeracao() {
        toast.error('Não existe alunos matriculados para essa turma', { autoClose: 6000, position: toast.POSITION.TOP_CENTER });
    }

    let baseUrl = ApiFilter(sub)

    const getEmpresa = async () => {
        const empresaResult = await GetEmpresa(baseUrl)
        setEmpresa(empresaResult)
    }

    //CONSULTAR DADOS
    let cursosDadosFunc = async () => {
        const [{ cursosDado, disciplinasDado, alunosDado, digitacaoDado }] = await Promise.all([PesquisarDados(baseUrl)])
        setCurso(cursosDado)
        setDisciplina(disciplinasDado)
        setAluno(alunosDado)
        setDigitacao(digitacaoDado)

        setAnoPermissao(localStorage.getItem('Year'))

        return cursosDado
    }

    //CONSULTAR GRADES
    const getGrades = async () => {
        const gradesResult = await GetGrades(baseUrl)
        setGrades(gradesResult)
    }

    useEffect(() => {
        setDataAno(DataCompleta[2])
        setDataMes(DataCompleta[1])
        setDataDia(DataCompleta[0])
    }, [DataCompleta])

    useEffect(() => {
        //CURSOS GET
        cursosDadosFunc()
        FiltrarCursos()
        getGrades()
        getEmpresa()
    }, [escola]);

    useEffect(() => {
        setDigitacao(digitacao)
        setCurso(curso)
        setDisciplina(disciplina)
        setAluno(aluno)
        FiltrarCursos()
    }, [permissao])

    //FILTRAR CURSOS PERMITIDOS
    function FiltrarCursos() {
        let filtrarCursosImport = FiltrarCurso(permissao, curso)
        setCursoFilter(filtrarCursosImport)
        FiltrarDisciplina(valorCurso)
    }

    useEffect(() => {
        for (let valor of digitacao) {
            if (valor.CodFuncionario === idProf) {
                permissaoFiltro.push(valor)
            }
        }
        setPermissao(permissaoFiltro)
    }, [digitacao])

    useEffect(() => {
        if (valorTurma !== Number) {
            PrimeiroTurma = turmaFiltro[0]
            setValorTurma(PrimeiroTurma)
            FiltrarTurma(valorCurso, valorDisciplina)
        }
        FiltrarTurma(valorCurso, valorDisciplina)
        FiltrarAluno(valorCurso, valorTurma)
    }, [valorDisciplina])

    useEffect(() => {
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)

        if (polivalenteStatus === 'null') {
            if (valorTurma !== Number) {
                PrimeiroTurma = turmaFiltro[0]
                setValorTurma(PrimeiroTurma)
                FiltrarTurma(valorCurso, valorDisciplina)
                PermisssaoSelecionado(valorCurso)
                FiltrarDisciplina(valorCurso)
                FiltrarTurma(valorCurso, valorDisciplina)
            }
        }

        FiltrarAluno(valorCurso, valorTurma)
    }, [valorCurso])

    useEffect(() => {
        if (cursoFilter.length === 0) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [cursoFilter])

    useEffect(() => {
        FiltrarAluno(valorCurso, valorTurma)
    }, [valorTurma])

    //FILTRAR PERMISSAO DE ACORDO COM O CURSO SELECIONADO
    function PermisssaoSelecionado(curso_selecionado) {
        disciplinaPermissao = []
        //Varrer Permissao
        for (let valor of permissao) {
            if (valor.CodCurso === curso_selecionado) {
                disciplinaPermissao.push(valor)
            }
        }

    }
    PermisssaoSelecionado()

    //FILTRAR DISCIPLINA DE ACORDO COM O CURSO SELECIONADO
    async function FiltrarDisciplina(curso_selecionado) {
        disciplinaFiltro = []
        setDisciplinaFilter([])
        PermisssaoSelecionado(curso_selecionado)
        disciplinaDistinct = [...new Set(disciplinaPermissao.map(item => item.CodDisciplina))]

        for (let idDisciplina of disciplinaDistinct) {
            //Varrer Disciplina
            for (let valor of disciplina) {

                if (valor.Codigo === idDisciplina) {
                    disciplinaFiltro.push(valor)
                }
            }
        }
        await setDisciplinaFilter(disciplinaFiltro)
    }

    //disciplinaPermissao Where codigo_disciplina === disciplinaSelecionada
    async function FiltrarTurma(curso_selecionado, disciplina_selecionada) {
        turmaPermissao = []
        setTurmaFiltro([])
        //Varrer permissao por curso e turma selecionados
        if (polivalenteStatus === 'null') {
            for (let valor of permissao) {
                if (valor.CodCurso === curso_selecionado && valor.CodDisciplina === disciplina_selecionada) {
                    turmaPermissao.push(valor);

                }
            }
            //Salvar Array de Turmas evitando duplicidade
            turmaDistinct = [...new Set(turmaPermissao.map(item => item.Turma))]
            setTurmaFiltro(turmaDistinct)
        } else {
            let digitacaoPermitido = digitacao.filter(item => item.CodFuncionario === idProf)
            let turmasProf = digitacaoPermitido.map(item => item.Turma)
            let turmasPermitido = [... new Set(turmasProf)]
            setTurmaFiltro(turmasPermitido)
        }
    }

    async function FiltrarAluno(curso_selecionado, turma_selecionada) {
        //Reinicia variavel alunosPermitidosPush
        alunosPermitidosPush = []
        let contador = 0
        //Pega alunos permitidos
        alunosPermissao = aluno.filter(item => item.Curso === curso_selecionado && item.Turma === turma_selecionada)
        //Pega apenas os nomes em ordem alfabetica
        alunosPermitidosNomeOrdem = alunosPermissao.map((item) => item.Nome).sort()

        //Compara cada nome da ordem alfabetica com o alunosPermissao. E adiciona em um array
        for (let valor of alunosPermitidosNomeOrdem) {
            alunosPermitidosPush.push(alunosPermissao.find((item) => item.Nome === valor))
        }

        const alunosOrdenados = OrdemItem(alunosPermitidosPush, 'Nome')
        setAlunosPermitidos(alunosOrdenados)
    }

    //FILTRAR DIGITACAO PERMITIDA
    useEffect(() => {
        FiltrarCursos()
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)

        FiltrarTurma(valorCurso, valorDisciplina)
    }, [digitacao])

    useEffect(() => {
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)
    }, [valorCurso])

    const onChangeCurso = async (event) => {
        setValorCurso(event.target.value)
        if (polivalenteStatus === 'null') {
            FiltrarDisciplina(valorCurso)
            FiltrarTurma(valorCurso, valorDisciplina)
        } else {
            FiltrarTurma(valorCurso)
        }

        // const dados = changeCursoForm(event.target.value)
        // console.log('Dados: ', dados)
    }

    const onChangeDisciplina = async (event) => {
        setValorDisciplina(event.target.value)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)
    }

    const onChangeTurma = async (event) => {
        setValorTurma(event.target.value)
    }

    const onChangeEmpresa = async (event) => {
        setValorEmpresa(event.target.value)
    }

    const onChangeMes = (event) => {
        setValorMes(event.target.value)
    }

    useEffect(() => {
        if (faltasAlunos.length !== 0) {
            let maiorNumeroFaltas = []
            maiorNumeroFaltas = faltasAlunos.map((item) => item.faltas).sort().reverse()
        }
    }, [faltasAlunos])

    async function handleGetFaltas(alunos, disciplina, curso, turma, dataInicial, dataFinal, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario) {
        faltasAlunosPush = []

        async function getFaltasAlunos() {
            faltasAlunosPush = []
            await Axios.post(`${baseUrl}/getFaltasGerarDiario`, {
                alunos: alunos,
                dataInicial: dataInicial,
                dataFinal: dataFinal,
                disciplina: disciplina
            })
                .then((response) => {
                    faltasAlunosPush.push(response.data)
                    setFaltasAlunos(response.data)
                    console.log('FaltasAlunosPush', faltasAlunosPush)
                    handleGetConteudo(alunos, dataInicial, dataFinal, curso, disciplina, turma, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush)
                })
                .catch((error) => {
                    console.log('Erro getFaltas: ', error)
                })
        }

        await getFaltasAlunos()
    }

    async function handleGetConteudo(alunos, dataInicial, dataFinal, curso_id, disciplina_id, turma_id, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush) {
        let conteudoPush = []
        //CONTEUDO GET
        async function getConteudo() {
            conteudoPush = []
            await Axios.post(`${baseUrl}/getConteudoGerarDiario`, {
                dataInicial: dataInicial,
                dataFinal: dataFinal,
                curso_id: curso_id,
                disciplina_id: disciplina_id,
                turma_id: turma_id
            })
                .then((response) => {
                    console.log('handleGetConteudo', response)
                    for (let valor of response.data) {
                        conteudoPush.push(valor)
                    }
                    setConsultaConteudo(conteudoPush)
                    consultarDiario(alunos, response.data, curso_id, disciplina_id, turma_id, dataInicial, dataFinal, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush)
                })
                .catch((error) => {
                    console.log('error handleGetConteudo', error)

                    window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                    console.log(error)
                })
            setConsultaConteudo(conteudoPush)
        }
        await getConteudo()
    }

    async function consultarDiario(alunos, conteudo, curso, disciplina, turma, dataInicial, dataFinal, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush) {
        const returnDisciplinaFiltro = filtroDisciplina(curso)
        console.log('returnDisciplinaFiltro', returnDisciplinaFiltro)

        const formulario = { curso: curso, disciplina: disciplina, turma: turma, dataInicial: dataInicial, dataFinal: dataFinal }

        const nomeCurso = await cursosFiltro.filter(item => item.CodCurso === curso)[0]?.Curso
        console.log('disciplinaFiltroConsultarDiario', disciplinaFiltro)
        console.log('disciplinaConsultarDiario', disciplina)
        const nomeDisciplina = await disciplina === '' ? 'POLIVALENTE' : returnDisciplinaFiltro.filter(item => item.CodDisciplina === disciplina)[0]?.Disciplina
        const dataAtualPDF = `${DataAno}${DataMes}${DataDia}`

        let conteudoFiltroData = conteudo

        for (let item of conteudoFiltroData) {
            item.data = item.data.slice(0, 10)
            //2021-06-17
            let dividirData = item.data.split('-')
            //["2021","06","17"]
            item.data = `${dividirData[2]}/${dividirData[1]}/${dividirData[0]}`
        }

        console.log('nomeDisciplina: ', nomeDisciplina)
        console.log('PaginaAtual', paginaAtual, 'paginasTotais', paginasTotais)

        await Axios.post(`${baseUrl}/gerarDiario`, {
            escola: escola,
            idProfessor: idProfessor,
            nomeProfessor: nomeProfessor,
            data: dataAtualPDF,
            nomeCurso: nomeCurso,
            nomeDisciplina: nomeDisciplina,
            formulario: formulario,
            alunos: alunos,
            conteudo: conteudoFiltroData,
            aulas: conteudo.aulas,
            alunosFaltas: faltasAlunosPush,
            paginaAtual: paginaAtual,
            paginasTotais: paginasTotais,
            empresa: empresa,
            anoLetivo: anoLetivo,
            tipoCurso: tipoCurso,
            nomeMes: nomeMes,
            tipoDiario: tipoDiario
        })
            .then((response) => {
                const resposta = response.data
                console.log('Diario Response antes: ', resposta)

                if (resposta === 'Feito') {
                    console.log('Diario Response feito: ', resposta)
                    window.open(`${baseUrl}/download/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')
                    setLoading(false)
                }
                //window.location.replace(window.location.origin + '/Diario/Gerar')
            })
            .catch((error) => {
                console.log('Error consultarDiario', error)
                try {
                    console.log('OPEN CATCH: ', error)
                    setTimeout(() => {
                        window.open(`${baseUrl}/download/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')
                        setLoading(false)
                    }, 2500)
                } catch {
                    console.log(`${escola} FALHA AO ATUALIZAR CONTEUDO`, error)
                    console.log(error)
                    setLoading(false)
                }
            })
    }


    const filterCursos = () => {
        const codCursosFiltro = [...new Set(grades.map(item => item.CodCurso))]
        let cursosPush = []

        for (const codigo of codCursosFiltro) {
            const itemFind = grades.filter(item => item.CodCurso === codigo)[0]
            cursosPush.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso })
        }

        const dadosCursos = []
        cursosPush.filter((item) => {
            const itemFind = item
            dadosCursos.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso })
        })

        setCursosFiltro(dadosCursos)
    }

    const filtroDisciplina = (valorCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === valorCurso)

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        setDisciplinasFiltro(dadosDisciplina)
        return dadosDisciplina
    }

    useEffect(() => {
        filterCursos()
    }, [grades])

    const onSubmit = async (dados) => {
        console.log('Dados', dados)
        const alunos = JSON.parse(dados.alunos)
        const disciplina = dados.disciplina               // CODIGO DISCIPLINA
        const curso = dados.curso                         // CODIGO CURSO
        const turma = dados.turma
        const dataMes = faixaDataMes(parseInt(dados.Mes)) //DATA CONVERTIDA (2022-01-31)
        const empresa = dados.Empresa
        const nomeCurso = cursosFiltro.filter(item => item.CodCurso === curso)[0]?.Curso.toUpperCase()
        let tipoCurso = ''
        const tipoDiario = 'T'

        if (nomeCurso?.includes('FUNDAMENTAL')) {
            tipoCurso = 'FUNDAMENTAL'

        } else if (nomeCurso?.includes('MÉDIO') || nomeCurso?.includes('MEDIO')) {
            tipoCurso = 'MEDIO'

        } else {
            tipoCurso = 'INFANTIL'
        }

        const disciplinasTotais = filtroDisciplina(curso)
        disciplinasTotais.push({ CodDisciplina: '999', Disciplina: 'POLIVALENTE' }) //ADD POLIVALENTE

        const lengthDisciplinas = disciplinasTotais.length - 1 //Quantidade total de disciplinas (Começando do index 0)
        console.log('#disciplinasTotais', disciplinasTotais)

        if (alunosPermitidos.length === 0) {
            notifyErrorGeracao()
        } else if (valorMes === '' || valorMes === 6666) {
            notifyWarnGeracao()
        } else {
            if (window.confirm('Confirma gerar impressao? ') === true) {
                setLoading(true)
                await handleGetFaltas(
                    alunos,
                    disciplina,
                    curso,
                    turma,
                    dataMes.dataInicial,
                    dataMes.dataFinal,
                    lengthDisciplinas,
                    lengthDisciplinas,
                    empresa,
                    anoPermitido,
                    tipoCurso,
                    dataMes.nomeMes,
                    tipoDiario
                )
            }
        }
    }

    const handleDate = (event) => {
        setData(event.target.value)
        setValorData(event.target.value)
    }

    const handleDateFinal = (event) => {
        setDataFinal(event.target.value)
        setValorDataFinal(event.target.value)
    }

    console.log('CursosFiltro', cursosFiltro)



    return (
        <>
            <Header />
            <div className="container">
                <section>
                    <div className="info-bar">
                        <div className="title">
                            <p className="title-page">Gerar Diário</p>
                            <p className="subtitle-page">Colégio {localStorage.getItem('escola').toUpperCase()}</p>
                        </div>
                    </div>

                    <form action="" method="post">
                        <div className="container-form">

                            <label for="curso">Curso</label>
                            <select onChange={onChangeCurso} onClick={onChangeCurso} value={valorCurso} name="curso" id="curso" ref={register} autoFocus required={true}>
                                <option value={9999} key='CursoBranco'></option>
                                {cursoFilter.map((val, key) => {
                                    return <option value={val.Codigo} key={key}>{val.Curso}</option>
                                })}
                            </select>

                            <label for="disciplina">Disciplina {polivalenteStatus !== 'null' ? '(Polivalente)' : ''}</label>
                            <select onChange={onChangeDisciplina} onClick={onChangeDisciplina} value={valorDisciplina}
                                name="disciplina" id="disciplina" autoFocus ref={register} required={true}
                                disabled={polivalenteStatus !== 'null' ? true : false}
                            >
                                <option value={6666} key='DisciplinaBranco'></option>
                                {disciplinaFilter.map((val, key) => {
                                    return <option value={val.Codigo} key={key}>{val.Apelido}</option>
                                })}
                            </select>

                            <label for="turma">Turma</label>
                            <select name="turma" onChange={onChangeTurma} onClick={onChangeTurma} value={valorTurma} id="turma" autoFocus ref={register} required={true}>
                                <option value={4444} key='TurmaBranco'> </option>
                                {turmaFiltro.map((val, key) => {
                                    return <option value={val} key={key}>{val}</option>
                                })
                                }
                            </select>

                            <div className="datas">
                                <label>Mês</label>
                                <select name="Mes" onChange={onChangeMes} value={valorMes} id="Mes" ref={register} required={true} >
                                    <option value={6666} key='MesBranco'></option>
                                    {
                                        Meses?.map((val, key) => {
                                            return (
                                                <option value={val.id} key={key}> {val.Mes} </option>
                                            )
                                        })
                                    }

                                </select>
                            </div>

                            <label for="Empresa">Empresa</label>
                            <select name="Empresa" onChange={onChangeEmpresa} onClick={onChangeEmpresa} value={valorEmpresa} id="Empresa" autoFocus ref={register} required={true}>
                                <option value={4444} key='empresaBranco'> </option>
                                {empresa.map((val, key) => {
                                    return <option value={JSON.stringify(val)} key={key}> {val.Nome} </option>
                                })
                                }
                            </select>

                            <select name="alunos" onChange={onChangeTurma} value={alunosPermitidos} onClick={onChangeTurma} id="alunos" ref={register} >
                                <option value={JSON.stringify(alunosPermitidos)}></option>
                            </select>



                            <input type="submit"
                                name="button"
                                className="button"
                                id="confirmar"
                                value="CONFIRMAR"
                                onClick={handleSubmit(onSubmit)}
                                ref={register}
                                disabled={loading}
                            />


                        </div>
                    </form>
                </section>
                {cursoFilter.length === 0 || disciplinaFilter.length === 0 ? <ScaleLoader color={color} loading={loading} css={override} height={50} width={8} /> :
                    null}

            </div>
        </>
    );
}