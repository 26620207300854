import OrdemItem from "./OrdenarItens"

export const FiltrarAlunosTurma = (alunos, curso_selecionado, turma_selecionada) => {
    const alunosPermitidosPush = []
    //Pega alunos permitidos
    const alunosPermissao = alunos?.filter(item => item.Curso === curso_selecionado && item.Turma === turma_selecionada)
    //Pega apenas os nomes em ordem alfabetica
    const alunosPermitidosNomeOrdem = alunosPermissao?.map((item) => item.Nome).sort()
    //Compara cada nome da ordem alfabetica com o alunosPermissao. E adiciona em um array
    for (let valor of alunosPermitidosNomeOrdem) {
        alunosPermitidosPush.push(alunosPermissao?.find((item) => item.Nome === valor))
    }
    //Ordena alfabetica pt-br (desconsidera acentos)
    const alunosOrdenados = OrdemItem(alunosPermitidosPush, 'Nome')
    return alunosOrdenados
}