import './Notas.css'
import React from 'react';
import Header from '../../components/header'
import { Link } from 'react-router-dom';

export default function Notas(){

    let logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'

    function lancarClick(){
        document.location.href = '/Notas/LancarNotas';
    }

    if(logado === 'true'){
        return(
            <div >
                <Header />
                <div className="container">
                    <section>
                        <div className="title">
                            <p className="title-page">Notas</p>
                            <p className="subtitle-page">{localStorage.getItem('escola').toUpperCase()}</p>
                        </div>
                        <div className="container-informativo">
                            <p className="informativo-title">INFORMATIVO</p>
                            <p className="informativo-desc">Digite nesta ferramenta as notas dos seus alunos para a formação do Boletim Escolar e também para consulta dos pais e alunos pela Internet. Para começar, clique em Lançar.</p>
                        </div>
                        <div className="container-buttons">
                            <Link onClick={lancarClick}>
                                <input className="lancarButton" name="lancar" id="lancar" type="submit" value="Lançar Notas"/>
                            </Link>
                        </div>
                    </section>
    
                    <footer>
                        <div></div>
                    </footer>
                </div>
            </div>
        );
    } else {
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace(window.location.origin)
    }
     
}