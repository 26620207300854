import React, { useState, useEffect } from 'react'
import './ButtonDisabled.css'
// import BeatLoader from "react-spinners/BeatLoader";
// import BounceLoader from "react-spinners/BounceLoader";
import PuffLoader from "react-spinners/PuffLoader";


export default function ButtonDisabled({ Title = "ButtonDisabled", isLoading = false, cursorLoadind = true }) {
    const [loading, setLoading] = useState(isLoading);
    const [color, setColor] = useState("#ffffff");
    const [cssLoading, setCssLoading] = useState('')
    const [cursorIsLoading, setCursorIsLoading] = useState('')

    useEffect(() => {
        if (loading) {
            setCssLoading('loadingIconActivated')
        }
        if(cursorLoadind){
            setCursorIsLoading('cursorIsLoadingActivated')
        }
    }, [])

    return (
        <button className={`ButtonDisabledDash ${cssLoading} ${cursorIsLoading}`} disabled >
            {
                loading ?
                    <div id="loadingIconDisabled">
                        <PuffLoader color={color} loading={loading} size={12} />
                    </div> :
                    <></>
            }
            {Title}
        </button>
    )
}