export const FiltrarTurmaProfQua = (curso_selecionado, digitacaoProfQua) => {
    const turmaPermissao = []
    //Varrer permissao por curso e turma selecionados
    for (const item of digitacaoProfQua) {
        if (item.CodCurso === curso_selecionado) {
            turmaPermissao.push(item);
        }
    }

    //Salvar Array de Turmas evitando duplicidade
    const turmasDistinct = [...new Set(turmaPermissao.map(item => item.Turma))]
    const turmasOrder = turmasDistinct.sort()
    return turmasOrder
}