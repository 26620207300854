import './header.css'
import React from 'react'
import { Link, withRouter } from 'react-router-dom';


function Header(){
    
    function onSair(){
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace(window.location.origin);
    }

    return(
        <header id='AppHeader'>
            <Link to="/home">
                <h1>Professor</h1>
            </Link>
            <div className="menu">
                <div className="menu-inicio">
                    <Link className="item-menu" to="/home/">Início</Link>
                </div>
                <div className="menu-diario">
                    <Link className="item-menu" to="/Diario/">Diário</Link>
                </div>
                <div className="menu-notas">
                    <Link className="item-menu" to="/Notas/">Notas</Link>
                </div>
                <div className="menu-pedagogico">
                    <Link className="item-menu" to="/Qualitativas/">Qualitativas</Link>
                </div>
            </div>
            <a type="button" value="Sair" className="button-sair" onClick={onSair}>
                <p>Sair</p>
                <svg id="icon-log-out" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
            </a>
        </header>
    );
}

export default withRouter(Header);