import React, { useState, useEffect } from 'react';
import SideBar from '../../../components/SideBar/SideBar';
import Header from '../../../components/Header/Header';
import FormRelatorioAdmin from '../../../components/FormRelatorio/FormRelatorio';
import { useForm } from 'react-hook-form';
import { Backdrop, CircularProgress } from '@mui/material';
import GetAvaliacoes from '../../../services/GetAvaliacoes';
import GetAlunos from '../../../services/GetAlunos';
import GetTurmas from '../../../services/GetTurmas';
import GetGrades from '../../../services/GetGrades';
import GetEmpresa from '../../../services/GetEmpresa';
import GetConteudoDisciplinasKeys from '../../../services/GetConteudoDisciplinasKeys';
import { ApiFilter } from '../../../Functions/FilterApiDomain';
import OrdemItem from '../../../Functions/OrdenarItens';
import GetAlunosFaltasTotais from '../../../services/GetFaltasTotaisAlunos';
import PostConsultarDiario from '../../../services/PostConsultarDiario';


export default function RelatorioFaltasTotais() {
    const [escola, setEscola] = useState(localStorage.getItem('escola').toUpperCase())
    const [idProfessor, setIdProfessor] = useState('Admin' + sessionStorage.getItem('idUser'))
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [open, setOpen] = useState(false);
    const [cursosFiltro, setCursosFiltro] = useState([])
    const [disciplinaFiltro, setDisciplinaFiltro] = useState([])
    const [grades, setGrades] = useState([])
    const [turmas, setTurmas] = useState([])
    const [alunos, setAlunos] = useState([])
    const [DataCompleta, setDataCompleta] = useState(new Date().toLocaleDateString().split('/'))
    const [DataAno, setDataAno] = useState('')
    const [DataMes, setDataMes] = useState('')
    const [DataDia, setDataDia] = useState('')
    const [empresa, setEmpresa] = useState([])
    const [processing, setProcessing] = useState(false)
    const [avaliacoes, setAvaliacoes] = useState([])

    const { register, handleSubmit } = useForm()

    const nomeProfessor = sessionStorage.getItem('user') !== null ? sessionStorage.getItem('user') : ''
    const anoPermitido = localStorage.getItem('Year')
    const baseUrl = ApiFilter(sub)

    const getEmpresa = async () => {
        const empresaResult = await GetEmpresa(baseUrl)
        setEmpresa(empresaResult)
    }

    const getGrades = async () => {
        const gradesResult = await GetGrades(baseUrl)
        setGrades(gradesResult)
    }

    const getTurmas = async () => {
        const turmaResult = await GetTurmas(baseUrl)
        setTurmas(turmaResult)
    }

    const getAlunos = async () => {
        const alunosResult = await GetAlunos(baseUrl)
        const alunosOrdenados = OrdemItem(alunosResult, 'Nome')
        setAlunos(alunosOrdenados)
    }

    const getAvaliacoes = async () => {
        const resultado = await GetAvaliacoes(baseUrl)
        setAvaliacoes(resultado)
    }

    const filterCursos = () => {
        const codCursosFiltro = [...new Set(grades.map(item => item.CodCurso))]
        let cursosPush = []

        for (const codigo of codCursosFiltro) {
            const itemFind = grades.filter(item => item.CodCurso === codigo)[0]
            cursosPush.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        }

        const dadosCursos = []

        cursosPush.filter((item) => {
            const itemFind = item
            dadosCursos.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        })

        setCursosFiltro(dadosCursos)
    }

    const onGetFaltasTotais = async (baseUrl, curso, turma, disciplina, dataInicial, dataFinal) => {
        const resultadoFaltasTotais = await GetAlunosFaltasTotais(baseUrl, curso, turma, disciplina, dataInicial, dataFinal)
        return resultadoFaltasTotais
    }

    const filtroDisciplina = (valorCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === valorCurso)

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        setDisciplinaFiltro(dadosDisciplina)
        return dadosDisciplina
    }

    const onSubmit = async (dados) => {
        setOpen(true)   //Loading is activated

        const alunos = JSON.parse(dados.alunos)
        const curso = dados.curso
        const disciplina = dados.disciplina
        const turma = dados.turma
        const dataInicial = dados.dataInicial
        const dataFinal = dados.dataFinal
        const empresa = dados.Empresa
        const checkEmBranco = true

        const tipoDiario = 'F'
        const dataAtualPDF = `${DataAno}${DataMes}${DataDia}`
        const nomeCurso = cursosFiltro.filter(item => item.CodCurso === curso)[0].Curso.toUpperCase()
        const countpage = 1

        let tipoCurso = ''
        if (nomeCurso.includes('FUNDAMENTAL')) {
            tipoCurso = 'FUNDAMENTAL'

        } else if (nomeCurso.includes('MÉDIO') || nomeCurso.includes('MEDIO')) {
            tipoCurso = 'MEDIO'

        } else {
            tipoCurso = 'INFANTIL'
        }

        const disciplinasKeysResultado = await GetConteudoDisciplinasKeys(baseUrl, dataInicial, dataFinal, curso, turma)

        let disciplinasTotais = filtroDisciplina(curso)

        disciplinasTotais.push({ CodDisciplina: '999', Disciplina: 'POLIVALENTE' }) //ADD POLIVALENTE
        disciplinasTotais = checkEmBranco ? disciplinasKeysResultado : disciplinasTotais

        const lengthDisciplinas = disciplinasTotais.length - 1 //Quantidade total de disciplinas (Começando do index 0)
        // paginasTotaisGlobal = lengthDisciplinas

        if (window.confirm('Gerar relatório?')) {
            if (disciplina === '666') { //Valida se disciplina selecionada foi "TODOS"

                for (const disciplinaItem of disciplinasTotais) {
                    const propDisciplina = checkEmBranco ? 'disciplina_id' : 'CodDisciplina'
                    //CODIGO DISCIPLINA DISCIPLINA
                    const disciplina = disciplinaItem[propDisciplina] === '999' ? '' : disciplinaItem[propDisciplina]

                    const indexDisciplina = disciplinasTotais.findIndex(item => item[propDisciplina] === disciplinaItem[propDisciplina])
                    const paginaAtualGerar = indexDisciplina
                    const paginasTotais = lengthDisciplinas
                    const returnDisciplinaFiltro = filtroDisciplina(curso)
                    const nomeDisciplina = await disciplina === '' ? 'POLIVALENTE' : returnDisciplinaFiltro.filter(item => item.CodDisciplina === disciplina)[0]?.Disciplina

                    //-------------- CONSULTAR E GERAR DIARIO A PARTIR DAQUI ----------------------
                    const alunosFaltas = await onGetFaltasTotais(baseUrl, curso, turma, disciplina, dataInicial, dataFinal)
                    //Return if don't have any register
                    if (alunosFaltas.length === 0) {
                        window.alert('Não existe lançamentos para a faixa de data informada')
                        setOpen(false)   //Loading is inactivated
                        return
                    }

                    const diarioGerado = await PostConsultarDiario(
                        baseUrl,
                        escola,
                        idProfessor,
                        dataAtualPDF,
                        nomeProfessor,
                        nomeDisciplina,
                        nomeCurso,
                        alunos,
                        curso,
                        disciplina,
                        turma,
                        dataInicial,
                        dataFinal,
                        paginaAtualGerar,
                        paginasTotais,
                        empresa,
                        anoPermitido,
                        tipoCurso,
                        tipoDiario,
                        alunosFaltas)

                    if (diarioGerado === 'Feito') {
                        window.open(`${baseUrl}/downloadFaltasCompletas/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')

                    } else if (diarioGerado.length === 0) {
                        window.alert('Não foi possível gerar o diário. Por favor tente novamente.')
                    }
                }

            } else {
                const alunosFaltas = await onGetFaltasTotais(baseUrl, curso, turma, disciplina, dataInicial, dataFinal)
                //Return if don't have any register
                if (alunosFaltas.length === 0) {
                    window.alert('Não existe lançamentos para a faixa de data informada')
                    setOpen(false)   //Loading is inactivated
                    return
                }

                const returnDisciplinaFiltro = filtroDisciplina(curso)
                const nomeDisciplina = await disciplina === '' ? 'POLIVALENTE' : returnDisciplinaFiltro.filter(item => item.CodDisciplina === disciplina)[0]?.Disciplina

                const diarioGerado = await PostConsultarDiario(baseUrl, escola, idProfessor, dataAtualPDF, nomeProfessor, nomeDisciplina, nomeCurso, alunos, curso, disciplina, turma, dataInicial, dataFinal, countpage, countpage, empresa, anoPermitido, tipoCurso, tipoDiario, alunosFaltas)

                if (diarioGerado === 'Feito') {
                    window.open(`${baseUrl}/downloadFaltasCompletas/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')

                } else {
                    window.alert('Não foi possível gerar o diário. Por favor tente novamente.')
                }
            }
        }

        setOpen(false) //Loading is inactivated
    }

    useEffect(() => {
        getAlunos()
        getEmpresa()
        getGrades()
        getTurmas()
        getAvaliacoes()
    }, [])

    useEffect(() => {
        filterCursos()
    }, [grades])

    useEffect(() => {
        setDataAno(DataCompleta[2])
        setDataMes(DataCompleta[1])
        setDataDia(DataCompleta[0])
    }, [DataCompleta])

    return (
        <>
            <div id='RelatorioCompletoGeneralContainer'>
                <SideBar />

                <div className='RelatorioCompletoContainer'>
                    <Header />

                    <div id="RelatorioCompletoTitlePage">
                        TOTAL DE FALTAS
                    </div>

                    <div className="RelatorioCompletoPainelContainer">
                        <FormRelatorioAdmin
                            grades={grades}
                            cursos={cursosFiltro}
                            empresa={empresa}
                            meses={[]}
                            turmas={turmas}
                            alunos={alunos}
                            avaliacoes={avaliacoes}
                            register={register}
                            showAvaliacoes={false}
                            showFiltrarEmBranco={false}
                            mesPorFaixa={false}
                        />

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        <input type="submit"
                            name="button"
                            disabled={processing}
                            className={`button`}
                            id="confirmar"
                            value={'Confirmar'}
                            onClick={handleSubmit(onSubmit)}
                            ref={register}
                        />

                    </div>

                </div>

            </div>
        </>
    )
}