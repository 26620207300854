import React from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import UsuariosDados from '../../../components/UsuariosDados/UsuariosDados'

import './EditarUsuario.css'

export default function EditarUsuario() {
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'    

    if(logado !== 'true'){
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='EditarUsuarioGeneralContainer'>
                    <SideBar />
                    <div className='EditarUsuarioContainer'>
                        <Header />
                        <div id="editarUsuarioTitlePage">
                            EDITAR USUÁRIO
                        </div>
                        <UsuariosDados title={"Dados do Usuário"} />
                    </div>
                </div>
            </>
        )
    }
}