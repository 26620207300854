import './Qualitativas.css'
import React from 'react';
import Header from '../../components/header'
import { Link } from 'react-router-dom';

export default function Qualitativas() {

    let logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'

    function lancarClickPorCriterio() {
        document.location.href = '/Qualitativas/PorCriterio';
    }

    function lancarClickPorAluno() {
        document.location.href = '/Qualitativas/PorAluno';
    }

    if (logado === 'true') {
        return (
            <div >
                <Header />
                <div className="container">
                    <section>
                        <div className="title">
                            <p className="title-page">Qualitativas</p>
                            <p className="subtitle-page">{localStorage.getItem('escola').toUpperCase()}</p>
                        </div>
                        <div className="container-informativo">
                            <p className="informativo-title">INFORMATIVO</p>
                            <p className="informativo-desc">Avalie seus alunos do ensino infantil de forma individualizada e personalizada de acordo com os critérios necessários. Para começar, em uma das opções.</p>
                        </div>
                        <div className="container-buttons">
                            <Link onClick={lancarClickPorCriterio}>
                                <input className="lancarButton" name="lancar" id="lancar" type="submit" value="Por Critério" />
                            </Link>
                            <Link onClick={lancarClickPorAluno}>
                                <input className="lancarButton" name="lancar" id="lancar" type="submit" value="Por Aluno" />
                            </Link>
                        </div>
                    </section>

                    <footer>
                        <div></div>
                    </footer>
                </div>
            </div>
        );
    } else {
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace(window.location.origin)
    }

}