import Axios from 'axios'
import { ApiFilter } from "./FilterApiDomain"

const sub = localStorage.getItem('target')
const options = { header: { 'X-Custom-Header': 'value' } }
const baseUrl = ApiFilter(sub)

export default async function DeletarDadosDiario(dado) {

    const resultado = await Axios.post(`${baseUrl}/deletar-diario`,
        {
            formulario: dado
        }, options)

    const resultadoStatus = resultado.status

    return resultadoStatus
}