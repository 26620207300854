import './Relatorios.css'
import React from 'react'
import Header from '../../../components/Header/Header'
import { Link } from 'react-router-dom'
import SideBar from '../../../components/SideBar/SideBar'
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';


export default function Relatorios() {
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'

    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div className="RelatoriosGerenciarAdminContainer">
                    <SideBar />

                    <div className="RelatoriosPageRigthSide">
                        <Header />

                        <div id="RelatoriosTitlePage">
                            Relatórios
                        </div>

                        <div className="RelatoriosPainelContainer">
                            <Link to="/Admin/Relatorios/RelatorioCompleto" className="RelatoriosPainelCard CardRelatorios">
                                <AssignmentIcon className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                                <div>
                                    DIÁRIO COMPLETO
                                </div>
                            </Link>

                            <Link to="/Admin/Relatorios/RelatorioListaFrequencia"  className="RelatoriosPainelCard CardRelatorios">
                                <FactCheckIcon className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                                <div>
                                    LISTA FREQUÊNCIA
                                </div>
                            </Link>

                            <Link to="/Admin/Relatorios/RelatorioResumoAtividades" className="RelatoriosPainelCard CardRelatorios">
                                <NoteAltIcon className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                                <div>
                                    RESUMO ATIVIDADES
                                </div>
                            </Link>

                            <Link to="/Admin/Relatorios/RelatorioNotasAuxiliares" className="RelatoriosPainelCard CardRelatorios">
                                <FormatListBulleted className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                                <div>
                                    NOTAS AUXILIARES
                                </div>
                            </Link>

                            <Link to="/Admin/Relatorios/RelatorioFaltasTotais" className="RelatoriosPainelCard CardRelatorios">
                                <FormatListBulleted className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                                <div>
                                    TOTAL DE FALTAS
                                </div>
                            </Link>
                        </div>


                    </div>

                </div>

            </>
        )
    }
}