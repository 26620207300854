import Axios from 'axios'

export default async function PostTransferirFaltas(
    BaseUrl = '', 
    chave = '', 
    dataInicial = '', 
    dataFinal = '', 
    avaliacaoSelect = '', 
    disciplinaTransf = '', 
    polivalente = false){
        
    const resultado = await Axios.post(`${BaseUrl}/transferirFaltas`, {
        chave:  chave,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        avaliacaoSelect: avaliacaoSelect,
        disciplinaTransf: disciplinaTransf,
        polivalente: polivalente
    })

    return resultado.data  
}