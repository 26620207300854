export const FiltrarAvaliacoes = (valorCurso, cursosPermitidos, avaliacoes) => {
    const avaliacaoPush = []
    const cursoSistema = cursosPermitidos.filter(item => item.Codigo === valorCurso)[0]?.Sistema

    for (const value of avaliacoes) {
        if (
            value.Codigo === 'N100' && cursoSistema === value.Sistema ||  // 1° AV
            value.Codigo === 'N200' && cursoSistema === value.Sistema ||  // 2° AV 
            value.Codigo === 'N300' && cursoSistema === value.Sistema ||  // 3° AV
            value.Codigo === 'N400' && cursoSistema === value.Sistema ||  // 4° AV
            value.Codigo === 'N500' && cursoSistema === value.Sistema ||  // 5° AV
            value.Codigo === 'N600' && cursoSistema === value.Sistema ||  // 6° AV
            value.Codigo === 'N700' && cursoSistema === value.Sistema ||  // 7° AV
            value.Codigo === 'N800' && cursoSistema === value.Sistema ||  // 8° AV
            value.Codigo === 'S101' && cursoSistema === value.Sistema ||  // Re 1S
            value.Codigo === 'S201' && cursoSistema === value.Sistema ||  // Re 2S
            value.Codigo === 'N900' && cursoSistema === value.Sistema ||  // P.F
            value.Codigo === 'N950' && cursoSistema === value.Sistema     // Re.F
        ) {
            avaliacaoPush.push(value)
        }
    }
    return avaliacaoPush
}