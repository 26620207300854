import './FormRelatorioNotasAux.css'
import React, { useEffect, useState } from 'react'


export default function FormRelatorioAdminNotasAux({ grades, cursos, empresa, meses, turmas, alunos, register, avaliacoes }) {
    const [valorCurso, setValorCurso] = useState('')
    const [valorDisciplina, setValorDisciplina] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorMes, setValorMes] = useState('')
    const [valorEmpresa, setValorEmpresa] = useState('')
    const [turmasFiltro, setTurmasFiltro] = useState([])
    const [disciplinaFiltro, setDisciplinaFiltro] = useState([])
    const [alunosFiltro, setAlunosFiltro] = useState([])
    const [avaliacoesNotasFiltro, setAvaliacoesNotasFiltro] = useState([])
    const [valorAvaliacao, setValorAvaliacao] = useState([])

    const onChangeCurso = (event) => {
        setValorCurso(event.target.value)
        filtroDisciplina(event.target.value)
        filtroTurmas(event.target.value)

        if (turmasFiltro.length > 0) {
            filtroAlunos(event.target.value, valorTurma)
        }
    }

    const onChangeDisciplina = (event) => {
        setValorDisciplina(event.target.value)
    }

    const onChangeTurma = (event) => {
        setValorTurma(event.target.value)
        filtroAlunos(valorCurso, event.target.value)
    }

    const onChangeEmpresa = (event) => {
        setValorEmpresa(event.target.value)
    }

    const onChangeMes = (event) => {
        setValorMes(event.target.value)
    }

    const filtroDisciplina = (valorCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === valorCurso)

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        // dadosDisciplina.push({CodDisciplina: '888', Disciplina: 'POLIVALENTE'})
        dadosDisciplina.push({ CodDisciplina: '666', Disciplina: 'TODOS' })
        // dadosDisciplina.push({ CodDisciplina: '', Disciplina: 'POLIVALENTE' })

        setDisciplinaFiltro(dadosDisciplina)
    }

    const filtroTurmas = (valorCurso) => {
        const findTurma = turmas.filter(item => item.Curso === valorCurso)
        setTurmasFiltro(findTurma)
    }

    const filtroAlunos = (valorCurso, valorTurma) => {
        const findAlunos = alunos.filter(item => item.Curso === valorCurso && item.Turma === valorTurma)
        const alunosPermitidosNomeOrdem = findAlunos.map((item) => item.Nome).sort()
        const alunosPermitidosPush = []

        for (const valor of alunosPermitidosNomeOrdem) {
            alunosPermitidosPush.push(findAlunos.find((item) => item.Nome === valor))
        }

        setAlunosFiltro(alunosPermitidosPush)
        // console.log('alunos', alunosPermitidosPush)
    }

    console.log('AlunosFiltro: ', alunosFiltro)

    const filtroSistemaAvaliacao = (cursos, avaliacoes) => {

        const sistemaAvaliacao = cursos.filter(item => item.CodCurso === valorCurso)[0].Sistema


        const avaliacoesFiltro = avaliacoes.filter(item => item.Sistema === sistemaAvaliacao)

        const avaliacoesNotas = avaliacoesFiltro?.filter((item) => {
            const find = item.Codigo

            if (find.search('N') !== -1) {
                return item
            }
        })

        setAvaliacoesNotasFiltro(avaliacoesNotas)
    }

    useEffect(() => {
        if (valorCurso !== '') {
            filtroSistemaAvaliacao(cursos, avaliacoes)
        }
    }, [valorCurso])

    const onChangeAvaliacao = (event) => {
        const valor = event.target.value
        setValorAvaliacao(valor)
    }

    return (
        <>
            <form id="formRelatorio" action="" method="post">

                <div className="container-form" id="containerFormRelatorio">
                    <div className='containerFormFirstLine'>
                        <div id="cursoContainerFormRelatorio">
                            <label for="curso">Curso</label>
                            <select name="curso" id="curso" value={valorCurso} onChange={onChangeCurso} ref={register} required={true}>
                                <option value={9999} key='CursoBranco'> </option>
                                {
                                    cursos?.map((val, key) => {
                                        return (
                                            <option value={val.CodCurso} key={key}> {val.Curso} </option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div>
                            <label for="turma">Turma</label>
                            <select name="turma" onChange={onChangeTurma} value={valorTurma} id="turma" ref={register} required={true}>
                                <option value={4444} key='TurmaBranco' > </option>
                                {
                                    turmasFiltro?.map((val, key) => {
                                        return (
                                            <option value={val.Turma} key={key}> {val.Turma} </option>
                                        )
                                    })
                                }

                            </select>
                        </div>
                    </div>


                    <label for="disciplina">Disciplina</label>
                    <select name="disciplina" onChange={onChangeDisciplina} value={valorDisciplina} id="disciplina" ref={register} required={true} >
                        <option value={6666} key='DisciplinaBranco'></option>
                        {
                            disciplinaFiltro?.map((val, key) => {
                                return (
                                    <option value={val.CodDisciplina} key={key}> {val.Disciplina} </option>
                                )
                            })
                        }

                    </select>

                    <label for="avaliacao">AVALIAÇÃO</label>
                    <select name="avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} id="avaliacao" ref={register} required={true} >
                        <option value={6666} key='AvaliacaoEmBranco'></option>
                        {
                            avaliacoesNotasFiltro?.map((val, key) => {
                                return (
                                    <option value={JSON.stringify(val)} key={key}> {val.Abreviatura} </option>
                                )
                            })
                        }
                    </select>

                    <label for="empresa">EMPRESA</label>
                    <select name="Empresa" onChange={onChangeEmpresa} value={valorEmpresa} id="Empresa" ref={register} required={true} >
                        <option value={6666} key='EmpresaBranco'></option>
                        {
                            empresa?.map((val, key) => {
                                return (
                                    <option value={JSON.stringify(val)} key={key}> {val.Nome} </option>
                                )
                            })
                        }
                    </select>

                    <select name="alunos" style={{ display: 'none' }} value={alunosFiltro} id="alunos" ref={register} required={true} >
                        <option value={JSON.stringify(alunosFiltro)} key='EmpresaBranco'></option>
                    </select>

                    <div id="FiltrarCheckAvaliacaoContainer">
                        <input type="checkbox" name='CheckAssFrequencia' id="CheckAssFrequencia" ref={register} />
                        <div>Assinatura Frequência</div>
                    </div>

                    {/* <div id="CheckOptionsContainer">
                        <div id="FiltrarEmBrancoContainer">
                            <input type="checkbox" name='CheckEmBranco' id="CheckEmBranco" ref={register} onChange={onChangeCheckEmBranco} />
                            <div for="checkFiltrarBranco">FILTRAR EM BRANCO</div>
                        </div>
                    </div>                     */}

                </div>
            </form>
        </>
    )
}