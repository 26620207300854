import React, { useEffect, useState } from 'react'
import './CardYellow.css'
import Block from '@material-ui/icons/Block';
import CountUp from 'react-countup'
import GetDesligados from '../../services/GetDesligados'
const { ApiFilter } = require('../../Functions/FilterApiDomain')

export default function CardYellow() {
    const [desligados, setDesligados] = useState([])
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const baseUrl = ApiFilter(sub)

    async function Desligados() {
        setDesligados(await GetDesligados(baseUrl))
    }

    useEffect(() => {
        Desligados()
    }, [])

    return (
        <div className='CardDashConteinerYellow'>
            <div className='CardDashInfo'>
                <div id='NumberCardDash'>
                    <CountUp end={desligados.length} duration={5} />
                </div>
                <Block id="GroupIcon" />
            </div>
            <div id='DescriptionDashCard'>Alunos Inativos</div>
        </div>
    )
}