import './Gerenciar.css'
import React from 'react'
import { Link } from 'react-router-dom'

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'

import BlockIcon from '@material-ui/icons/Block';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MoveDownIcon from '@mui/icons-material/MoveDown';


export default function Gerenciar() {
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'


    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
        
    } else {
        return (
            <>
                <div className="GerenciarGeneralAdminContainer">
                    <SideBar />
                    <div className="GerenciarPageRigthSide">
                        <Header />

                        <div id="GerenciarTitlePage">
                            Gerenciar
                        </div>

                        <div className="GerenciarBodyPainelContainer">
                            <div className="CardGerenciar item1">
                                <BlockIcon className="IconGerenciarPainel" id="BlockIconGerenciarPainel" />
                                <div>
                                    Bloquear Lançamento
                                </div>
                            </div>

                            <Link to="/Admin/Gerenciar/FecharFaltas" className="CardGerenciar item2">
                                    <PendingActionsIcon className="IconGerenciarPainel" />
                                    <div>
                                        Fechar Faltas
                                    </div>
                            </Link>

                            <div className="CardGerenciar item3" >
                                <MoveDownIcon className="IconGerenciarPainel" />
                                <div>
                                    Transferir Faltas
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}