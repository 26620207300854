import './RelatorioNotasAuxiliares.css'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Axios from 'axios'

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import FormRelatorioAdminNotasAux from '../../../components/FormRelatorioNotasAux/FormRelatorioNotasAux'

import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetEmpresa from '../../../services/GetEmpresa'
import GetGrades from '../../../services/GetGrades'
import GetTurmas from '../../../services/GetTurmas'
import GetAlunos from '../../../services/GetAlunos'
import GetNotasAuxLegendaByKey from '../../../services/GetNotasAuxLegendaByKey'
import GetNotasFaltas from '../../../services/GetNotasFaltas'
import OrdemItem from '../../../Functions/OrdenarItens'

import { Backdrop, CircularProgress } from '@mui/material'

const { ApiFilter } = require('../../../Functions/FilterApiDomain')


export default function RelatorioNotasAuxiliares() {
    const [escola, setEscola] = useState(localStorage.getItem('escola').toUpperCase())
    const [idProfessor, setIdProfessor] = useState(sessionStorage.getItem('professores'))
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [open, setOpen] = useState(false);
    const [cursosFiltro, setCursosFiltro] = useState([])
    const [disciplinaFiltro, setDisciplinaFiltro] = useState([])
    const [grades, setGrades] = useState([])
    const [turmas, setTurmas] = useState([])
    const [alunos, setAlunos] = useState([])
    const [DataCompleta, setDataCompleta] = useState(new Date().toLocaleDateString().split('/'))
    const [DataAno, setDataAno] = useState('')
    const [DataMes, setDataMes] = useState('')
    const [DataDia, setDataDia] = useState('')
    const [empresa, setEmpresa] = useState([])
    const [processing, setProcessing] = useState(false)
    const [avaliacoes, setAvaliacoes] = useState([])

    const nomeProfessor = sessionStorage.getItem('nome') !== null ? sessionStorage.getItem('nome') : ''
    const anoPermitido = localStorage.getItem('Year')
    const { register, handleSubmit, errors } = useForm()
    const baseUrl = ApiFilter(sub)
    const meses = [
        { id: 1, Mes: 'JANEIRO' },
        { id: 2, Mes: 'FEVEREIRO' },
        { id: 3, Mes: 'MARÇO' },
        { id: 4, Mes: 'ABRIL' },
        { id: 5, Mes: 'MAIO' },
        { id: 6, Mes: 'JUNHO' },
        { id: 7, Mes: 'JULHO' },
        { id: 8, Mes: 'AGOSTO' },
        { id: 9, Mes: 'SETEMBRO' },
        { id: 10, Mes: 'OUTUBRO' },
        { id: 11, Mes: 'NOVEMBRO' },
        { id: 12, Mes: 'DEZEMBRO' },
    ]

    let paginasTotaisGlobal = ''
    let paginaAtualGlobal = ''

    let checkEmBrancoAtual = false

    const getEmpresa = async () => {
        const empresaResult = await GetEmpresa(baseUrl)
        setEmpresa(empresaResult)
    }

    const getGrades = async () => {
        const gradesResult = await GetGrades(baseUrl)
        setGrades(gradesResult)
    }

    const getTurmas = async () => {
        const turmaResult = await GetTurmas(baseUrl)
        setTurmas(turmaResult)
    }

    const getAvaliacoes = async () => {
        const resultado = await GetAvaliacoes(baseUrl)
        setAvaliacoes(resultado)
    }

    const getAlunos = async () => {
        const alunosResult = await GetAlunos(baseUrl)
        const alunosOrdenados = OrdemItem(alunosResult, 'Nome')
        setAlunos(alunosOrdenados)
    }

    const getNotasFaltas = async (dado) => {
        const chave = dado.curso + dado.turma + dado.disciplina + JSON.parse(dado.avaliacao).Codigo
        const auxiliar = ''
        const tipoAv = 'N'
        const escola = localStorage.getItem('escola')
        const alunosPermitidos = alunos.filter(item => item.Curso === dado.curso && item.Turma === dado.turma)

        const notasResult = await GetNotasFaltas(baseUrl, escola, alunosPermitidos, chave, auxiliar, tipoAv)
        // console.log('NotasResult: ', notasResult)
        return notasResult
    }

    const filterCursos = () => {
        const codCursosFiltro = [...new Set(grades.map(item => item.CodCurso))]
        let cursosPush = []

        for (const codigo of codCursosFiltro) {
            const itemFind = grades.filter(item => item.CodCurso === codigo)[0]
            cursosPush.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        }

        const dadosCursos = []

        cursosPush.filter((item) => {
            const itemFind = item
            dadosCursos.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        })

        setCursosFiltro(dadosCursos)
    }

    useEffect(() => {
        setDataAno(DataCompleta[2])
        setDataMes(DataCompleta[1])
        setDataDia(DataCompleta[0])
    }, [DataCompleta])

    async function consultarDiario(alunos, curso, disciplina, turma, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, alunosNotas, legAuxExistentes, avaliacaoAbreviatura, tipoAv, checkAssFrequencia) {
        const returnDisciplinaFiltro = filtroDisciplina(curso)

        const formulario = { curso: curso, disciplina: disciplina, turma: turma }

        const nomeCurso = await cursosFiltro.filter(item => item.CodCurso === curso)[0]?.Curso
        const nomeDisciplina = await disciplina === '' ? 'POLIVALENTE' : returnDisciplinaFiltro.filter(item => item.CodDisciplina === disciplina)[0]?.Disciplina
        const dataAtualPDF = `${DataAno}${DataMes}${DataDia}`

        await Axios.post(`${baseUrl}/gerarDiarioNotasAuxAdmin`, {
            escola: escola,
            idProfessor: idProfessor,
            nomeProfessor: nomeProfessor,
            data: dataAtualPDF,
            nomeCurso: nomeCurso,
            nomeDisciplina: nomeDisciplina,
            formulario: formulario,
            alunos: alunos,
            alunosNotas: alunosNotas,
            paginaAtual: paginaAtual,
            paginasTotais: paginasTotais,
            empresa: empresa,
            anoLetivo: anoLetivo,
            tipoCurso: tipoCurso,
            legendas: legAuxExistentes,
            avaliacaoAbreviatura: avaliacaoAbreviatura,
            tipoAv: tipoAv,
            checkAssFrequencia: checkAssFrequencia
        })
            .then((response) => {
                const resposta = response.data
                console.log('Diario Response antes: ', resposta)

                if (resposta === 'Feito') {
                    console.log('Diario Response feito: ', resposta)
                    window.open(`${baseUrl}/download/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')
                }
            })
            .catch((error) => {
                try {
                    console.log('OPEN CATCH: ', error)
                    setTimeout(() => {
                        window.open(`${baseUrl}/download/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')
                        // setLoading(false)
                    }, 2500)
                } catch {
                    console.log(`${escola} FALHA AO ATUALIZAR CONTEUDO`, error)
                    console.log(error)
                    // setLoading(false)
                }
            })
    }

    async function GetNotasFaltasQuery(BaseUrl, escola, alunos, chave, auxiliar, tipoAv) {
        const resultadoNotasFaltas = await Axios.post(`${BaseUrl}/notas/getFaltas`, {
            escola: escola,
            alunos: alunos,
            chave: chave,
            notasAux: auxiliar,
            tipoAvaliacao: tipoAv
        })
        return resultadoNotasFaltas.data
    }

    async function GetConceitosFaltasQuery(BaseUrl, escola, alunos, chave, auxiliar, tipoAv) {
        const resultadoConceitosFaltas = await Axios.post(`${BaseUrl}/notas/getConceitos`, {
            escola: escola,
            alunos: alunos,
            chave: chave,
            notasAux: auxiliar
        })
        return resultadoConceitosFaltas.data
    }

    const filtroDisciplina = (valorCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === valorCurso && item.TipoAvaliacao !== 'S')

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        setDisciplinaFiltro(dadosDisciplina)
        return dadosDisciplina
    }


    const onSubmit = async (dados) => {
        console.log('Dados: ', dados)
        //VALIDAR SE DADOS FORAM PREENCHIDOS
        if (
            dados.curso === "9999" ||
            dados.disciplina === "6666" ||
            dados.turma === "4444" ||
            dados.avaliacao === "6666" ||
            dados.Empresa === "6666"
        ) {
            window.alert('FAVOR VERIFICAR SE TODOS OS CAMPOS FORAM PREENCHIDOS')
            return

        } else {
            // console.log('Dados: ', dados)
            setOpen(true)
            setProcessing(true)

            const alunos = JSON.parse(dados.alunos)
            const disciplina = dados.disciplina               // CODIGO DISCIPLINA
            const curso = dados.curso                         // CODIGO CURSO
            const codAvaliacao = JSON.parse(dados.avaliacao).Codigo
            const abreviaturaAv = JSON.parse(dados.avaliacao).Abreviatura
            const turma = dados.turma
            const empresa = dados.Empresa
            const checkAssFrequencia = dados.CheckAssFrequencia
            const nomeCurso = cursosFiltro.filter(item => item.CodCurso === curso)[0].Curso.toUpperCase()
            let tipoCurso = ''

            if (nomeCurso.includes('FUNDAMENTAL')) {
                tipoCurso = 'FUNDAMENTAL'

            } else if (nomeCurso.includes('MÉDIO') || nomeCurso.includes('MEDIO')) {
                tipoCurso = 'MEDIO'

            } else {
                tipoCurso = 'INFANTIL'
            }

            let disciplinasTotais = filtroDisciplina(curso)
            // disciplinasTotais.push({ CodDisciplina: '999', Disciplina: 'POLIVALENTE' }) //ADD POLIVALENTE

            const lengthDisciplinas = disciplinasTotais.length - 1 //Quantidade total de disciplinas (Começando do index 0)
            paginasTotaisGlobal = lengthDisciplinas

            if (window.confirm('Gerar relatório?')) {
                setOpen(true)
                setProcessing(true)
                //OPÇÃO TODOS OS CURSOS
                const chaveNotas = dados.curso + dados.turma + disciplina + JSON.parse(dados.avaliacao).Codigo
                const auxiliar = ''
                const escola = localStorage.getItem('escola')
                const alunosPermitidos = alunos.filter(item => item.Curso === dados.curso && item.Turma === dados.turma)

                const gradeFiltro = grades.filter(item => item.CodCurso === curso && item.CodDisciplina === disciplina)[0]?.TipoAvaliacao
                const tipoAv = gradeFiltro

                console.log('Grades: ', grades)
                console.log('GradesFiltro: ', gradeFiltro)

                let notasReturnResult
                if (gradeFiltro === 'C') {
                    notasReturnResult = await GetConceitosFaltasQuery(baseUrl, escola, alunosPermitidos, chaveNotas, auxiliar)
                } else {
                    notasReturnResult = await GetNotasFaltasQuery(baseUrl, escola, alunosPermitidos, chaveNotas, auxiliar, tipoAv)
                }

                const notasReturn = notasReturnResult

                console.log('NotasReturn: ', notasReturn)

                if (disciplina === '666') {
                    console.log('Entrou se Disciplina: ', disciplina)

                    for (const disciplinaItem of disciplinasTotais) {
                        console.log('Disciplina Item: ', disciplinaItem)
                        console.log('Disciplina Totais: ', disciplinasTotais)
                        
                        const propDisciplina = checkEmBrancoAtual ? 'disciplina_id' : 'CodDisciplina'
                        //CODIGO DISCIPLINA DISCIPLINA
                        const disciplina = disciplinaItem[propDisciplina] === '999' ? '' : disciplinaItem[propDisciplina]
                        console.log('CodDisciplina: ', disciplina)
                        const nomeDisciplina = disciplinaItem[propDisciplina] === '999' ? 'POLIVALENTE' : disciplinaItem.Disciplina
                        //Chave de consulta
                        const chaveNotas = dados.curso + dados.turma + disciplina + JSON.parse(dados.avaliacao).Codigo
                        //Verificar tipo avaliação da disciplina
                        const gradeFiltro = grades.filter(item => item.CodCurso === curso && item.CodDisciplina === disciplina)[0].TipoAvaliacao
                        const tipoAv = gradeFiltro
                        //INDEX DISCIPLINA
                        const indexDisciplina = disciplinasTotais.findIndex(item => item[propDisciplina] === disciplinaItem[propDisciplina])
                        paginaAtualGlobal = indexDisciplina

                        let notasReturnResult
                        if (gradeFiltro === 'C') {
                            notasReturnResult = await GetConceitosFaltasQuery(baseUrl, escola, alunosPermitidos, chaveNotas, auxiliar)
                        } else {
                            notasReturnResult = await GetNotasFaltasQuery(baseUrl, escola, alunosPermitidos, chaveNotas, auxiliar, tipoAv)
                        }

                        const notasReturn = notasReturnResult

                        const chave = curso + turma + disciplina + codAvaliacao
                        const legendasByKey = await GetNotasAuxLegendaByKey(baseUrl, chave)

                        const legAuxExistentes = [{}]
                        for (const item in legendasByKey[0]) {
                            if (item !== 'id' && item !== 'Chave') {
                                if (legendasByKey[0][item]) {
                                    const valor = legendasByKey[0][item]
                                    legAuxExistentes[0][item] = valor
                                }
                            }
                        }

                        let nomesAux = Object.keys(legAuxExistentes[0])
                        const auxStr = nomesAux.map(item => item.replace('Leg', 'auxiliar_'))

                        for (const nota of notasReturn) {
                            for (const item in nota) {
                                if (item.includes('auxiliar')) {
                                    if (auxStr.includes(item)) {
                                        continue
                                    } else {
                                        delete nota[item]
                                    }
                                }
                            }
                        }

                        consultarDiario(
                            alunos,
                            curso,
                            disciplina,
                            turma,
                            indexDisciplina,
                            lengthDisciplinas,
                            empresa,
                            anoPermitido,
                            tipoCurso,
                            notasReturn,
                            legAuxExistentes,
                            abreviaturaAv,
                            tipoAv,
                            checkAssFrequencia)
                    }

                    handleClose()
                    setProcessing(false)

                } else {

                    const chave = curso + turma + disciplina + codAvaliacao
                    const legendasByKey = await GetNotasAuxLegendaByKey(baseUrl, chave)

                    const legAuxExistentes = [{}]
                    for (const item in legendasByKey[0]) {
                        if (item !== 'id' && item !== 'Chave') {
                            if (legendasByKey[0][item]) {
                                const valor = legendasByKey[0][item]
                                legAuxExistentes[0][item] = valor
                            }
                        }
                    }

                    let nomesAux = Object.keys(legAuxExistentes[0])
                    const auxStr = nomesAux.map(item => item.replace('Leg', 'auxiliar_'))

                    for (const nota of notasReturn) {
                        for (const item in nota) {
                            if (item.includes('auxiliar')) {
                                if (auxStr.includes(item)) {
                                    continue
                                } else {
                                    delete nota[item]
                                }
                            }
                        }
                    }

                    consultarDiario(
                        alunos,
                        curso,
                        disciplina,
                        turma,
                        lengthDisciplinas,
                        lengthDisciplinas,
                        empresa,
                        anoPermitido,
                        tipoCurso,
                        notasReturn,
                        legAuxExistentes,
                        abreviaturaAv,
                        tipoAv,
                        checkAssFrequencia)

                    handleClose()
                    setProcessing(false)
                }

            }

            handleClose()
            setProcessing(false)
        }


    }

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getAlunos()
        getEmpresa()
        getGrades()
        getTurmas()
        getAvaliacoes()
    }, [])

    useEffect(() => {
        filterCursos()
    }, [grades])

    return (
        <>
            <div id='RelatorioCompletoGeneralContainer'>
                <SideBar />

                <div className='RelatorioCompletoContainer'>
                    <Header />

                    <div id="RelatorioCompletoTitlePage">
                        RELATÓRIO NOTAS AUXILIARES
                    </div>

                    <div className="RelatorioCompletoPainelContainer">
                        <FormRelatorioAdminNotasAux
                            grades={grades}
                            cursos={cursosFiltro}
                            empresa={empresa}
                            meses={meses}
                            turmas={turmas}
                            alunos={alunos}
                            avaliacoes={avaliacoes}
                            register={register}
                        />

                        {
                            processing === false ?
                                <input type="submit"
                                    name="button"
                                    disabled={processing}
                                    className={`button`}
                                    id="confirmar"
                                    value={'Confirmar'}
                                    onClick={handleSubmit(onSubmit)}
                                    ref={register}
                                />
                                :
                                <input type="submit"
                                    name="button"
                                    disabled={processing}
                                    className={`disabled`}
                                    id="confirmar"
                                    value={'PROCESSANDO'}
                                />
                        }

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </div>

                </div>

            </div>
        </>
    )
}