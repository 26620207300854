//ORDENAR ITENS PELO CAMPO DESEJADO
//INSERIR DADOS EM OBJETO E INFORMAR A REFERENCIA DO CAMPO A SER ORDENADO
const OrdemItem = (dado, nomeCampo) => {
    let resultPush = []
    const FieldName = nomeCampo
    const data = dado
    const orderNames = data.map(item => item[FieldName]).sort( function(a,b) {
        return a.localeCompare(b)
    })
    
    for (let value of orderNames) {
        const findResult = data.find(item => item[FieldName] === value)
        resultPush.push(findResult)
    }


    return resultPush
}

export default OrdemItem