//FILTRAR DADOS EM FORMA DE CASCATA (UM PÓS O OUTRO)
export default function FiltrarDadosNotas(dados, curso, turma, disciplina, avaliacao) {
    //PEGAR DADOS GERAIS
    const dadosDiario = dados
    console.log('dados: ', dadosDiario)
    
    //FILTRAR POR CURSO
    const resultadoCurso = dadosDiario.filter(
        item => curso !== '' ? item.CodCurso === curso : item.CodCurso
    )
    console.log('ResultadoCurso: ', resultadoCurso)
    //FILTRAR POR TURMA
    const resultadoTurma = resultadoCurso.filter(
        item => turma !== '' ? item.Turma === turma : item.Turma
    )
    //FILTRAR POR DISCIPLINA
    const resultadoDisciplina = resultadoTurma.filter(
        item => disciplina !== '' ? item.CodDisciplina === disciplina : item.CodDisciplina
    )
    //FILTRAR AVALIACAO...
    const resultadoAvaliacao = resultadoDisciplina.filter(
        item => avaliacao !== '' ? item.CodAvaliacao === avaliacao : item.CodAvaliacao
    )

    //PEGAR ULTIMO DADO FILTRADO
    const resultadoDados = resultadoAvaliacao
    //RETORNAR 
    return resultadoDados
}