import React, { useState, useEffect } from 'react'
import GetQualitativasPorAluno from '../../services/GetQualitativasPorAluno';
import { Search } from '@mui/icons-material';
import { Backdrop, CircularProgress } from '@mui/material'
import Modal from 'react-modal'
import SelectAPI from '../../Functions/SelectApi';
import './QualitativasTabelaAlunos.css'

const notasquaAlunoType = { id: 0, data_lanc: '', matricula: '', nome: '', disciplina: '', descricao: '', conceito: '' }


export default function QualitativasTabelaAlunos(
    { bodyDataRows, headerTableTitles, bodyTableFields }
) {
    const [dataRow, setDataRow] = useState(bodyDataRows)
    const [headerTitles, setHeaderTitles] = useState(headerTableTitles)
    const [tableFields, setTableFields] = useState(bodyTableFields)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [notasQuaAluno, setNotasQuaAluno] = useState([notasquaAlunoType])
    const [loading, setLoading] = useState(false)
    const [alunoSelect, setAlunoSelect] = useState({ matricula: '', nome: '' })
    const storage = JSON.parse(sessionStorage.getItem('d'))
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const baseUrl = SelectAPI(valorEscola)

    const closeModal = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const onClickVisualizar = async (aluno) => {
        setLoading(true)
        setModalIsOpen(true)
        setAlunoSelect(aluno)

        const notasAluno = await GetQualitativasPorAluno(baseUrl, storage.Chave, aluno.matricula)
        setNotasQuaAluno(notasAluno)
        setLoading(false)
    }

    useEffect(() => {
        setDataRow(bodyDataRows)
    }, [bodyDataRows])

    return (
        <div className='NotasTabelaGeralContainer'>
            <div className='NotasTabelaGeralTable'>
                <div className='NotasTabelaGeralHeaderTable'>
                    {headerTitles?.map(item => (
                        <div id="itemHeaderNotasTabelaGeralDashRow">{item}</div>
                    )
                    )}
                    <div id="srollInvisibleHeadItem"></div>
                </div>
            </div>

            <Modal
                closeTimeoutMS={200}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Notas Auxiliares Painel Admin'
                overlayClassName='modal-overlay'
                className='modal-content-notas-aux'
            >
                <h2>
                    <div id="TitleModalNotasQuaAlunoQuadro">
                        {alunoSelect.matricula} - {alunoSelect.nome}
                    </div>
                </h2>

                <hr />

                <p>
                    Notas Qualitativas
                </p>

                {loading ?
                    <div id="qualitativasTabelaAlunosProgress">
                        <CircularProgress color="inherit" />
                    </div>
                    :
                    <div id='notasQuaAlunoGrade'>
                        <div id="notasQuaAlunoGradeItemHeader">
                            <div id="notasQuaAlunoGradeItemDescricaoHeader"> Descrição </div>
                            <div id="notasQuaAlunoGradeItemConceitoHeader"> Conceito </div>
                        </div>
                        {
                            notasQuaAluno.map((item, index) => {
                                return (
                                    <div id="notasQuaAlunoGradeItem" key={index}>
                                        <div id="notasQuaAlunoGradeItemDescricao"> {item.descricao} </div>
                                        <div id="notasQuaAlunoGradeItemConceito"> {item.conceito} </div>
                                    </div>
                                )
                            })
                        }
                    </div>}

                <button id='ButtonModalStudent' onClick={closeModal}>
                    FECHAR
                </button>
            </Modal>

            <div className="NotastabelaGeralBodyDataContainer">
                {dataRow?.map((val, key) => {
                    return (

                        <div id="NotasTabelaGeralDashRow" >
                            {tableFields?.map((item, index) => (
                                isNaN(val[item]) === false ?
                                    <div id="itemBodyNotasTabelaGeralDashRow" key={`${key}${index}`}> {val[item]} </div>
                                    : <div id="itemBodyNotasTabelaGeralDashRow" key={`${key}${index}`}> {val[item].toUpperCase()} </div>
                            )
                            )}
                            <div id="itemBodyNotasTabelaGeralDashRow" >
                                <button id="visualizarQualitativasTabelaButton" onClick={() => onClickVisualizar(val)} >
                                    <Search color='action' id="iconSearch" size={14} />
                                    VISUALIZAR
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}