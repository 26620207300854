import React from 'react'
import './ButtonDelete.css'

export default function ButtonDelete({Title, Action}){

    const ClickButton = Action

    return(
        <button id="ButtonDeleteDash" onClick={ClickButton}>
            {Title}        
        </button>
    )
}