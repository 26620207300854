import React from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import ProfessorDados from '../../../components/ProfessorDados/ProfessorDados'

import './EditarProfessor.css'

export default function EditarProfessor() {
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'    

    if(logado !== 'true'){
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='EditarProfessorGeneralContainer'>
                    <SideBar />
                    <div className='EditarProfessorContainer'>
                        <Header />
                        <div id="editarProfessorTitlePage">
                            EDITAR PROFESSOR
                        </div>
                        <ProfessorDados title={"DADOS PROFESSOR"} />
                    </div>
                </div>
            </>
        )
    }
}