import Axios from 'axios'

export default async function GetAlunosFaltasTotais(BaseUrl, curso, turma, disciplina, dataInicial, dataFinal, cliente){ 
    
    let alunos = await Axios.post(`${BaseUrl}/getFaltasTotaisGerarDiario?escola=${cliente}`, {
        curso: curso,
        turma: turma,
        disciplina: disciplina,
        dataInicial: dataInicial,
        dataFinal: dataFinal
    })   

    return alunos.data
}