import React, { useEffect, useState } from 'react'
import SearchIconAnimated from '../../images/Search-1s-200px.svg'
import './TabelaPessoas.css'

export default function TabelaPessoas(props) {

    const [searchStatus, setSearchStatus] = useState(true)
    const [bodyDataRows, setBodyDataRows] = useState(props.bodyDataRows)
    const [pesquisa, setPesquisa] = useState('')
    const [pessoasFiltro, setPessoasFiltro] = useState(bodyDataRows)
    const searchBarTitle = props.searchBarTitle
    const searchBarInputPlaceHolder = props.searchBarInputPlaceHolder
    const headerTableTitles = props.headerTableTitles
    const bodyTableFields = props.bodyTableFields

    useEffect(() => {
        if (props.bodyDataRows.length > 0) {
            setSearchStatus(false)
        }

        setBodyDataRows(props.bodyDataRows)
        setPessoasFiltro(props.bodyDataRows)
    }, [props.bodyDataRows])

    const OnSearchName = (event) => {
        setPesquisa(event.target.value)
        let inputPesquisa = event.target.value
        //Sem texto mostra todas as pessoas
        if (event.target.value === '') {
            setPessoasFiltro(bodyDataRows)
            //Com texto filtra as pessoas
        } else {
            let listarFiltro = bodyDataRows.filter((pessoa) =>
                pessoa.Nome.toUpperCase().includes(inputPesquisa.toUpperCase())
            )
            setPessoasFiltro(listarFiltro)
        }
    }

    return (
        <div className='TabelaPessoasDashContainer'>
            <div className='TabelaPessoasDashBarraPesquisa'>
                <div>
                    {searchBarTitle}
                </div>
                <input type="text" onChange={OnSearchName} value={pesquisa} placeholder={searchBarInputPlaceHolder} />
            </div>

            <div className='TabelaPessoasDashTable'>
                {
                    searchStatus === false ?
                        <div id='tablePessoas'>
                            <div id='headerPessoas'>
                                <div>
                                    {headerTableTitles?.map((item, key) => (
                                        <div key={key} id='headerItemPessoas'>{item}</div>
                                    )
                                    )}
                                </div>
                            </div>
                            <div id='bodyPessoas'>
                                {
                                    pessoasFiltro?.map((val, key) => {
                                        return (

                                            <div key={key} value={val.matricula} id="TabelaPessoasDashRow" >
                                                {bodyTableFields?.map((item, k) => (
                                                    <div key={`${k}_${item}`} id='bodyItemPessoas'>{val[item].toUpperCase()}</div>
                                                )
                                                )}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div id='QtdAlunosTabelaPessoas'>
                                Quantidade: {pessoasFiltro?.length}
                            </div>
                        </div >
                        :
                        <div id='ContainerIconSearchLoadingStatus'>
                            <p>
                                Procurando...
                            </p>
                            <img src={SearchIconAnimated} alt='Search Icon' id='IdIconSearchLoadingStatus' />
                        </div>
                }

            </div>
        </div>
    )
}