import React, {useEffect} from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import UsuariosDados from '../../../components/UsuariosDados/UsuariosDados'
import UsuariosNovoDados from '../../../components/UsuarioNovoDados/UsuariosNovoDados'

import './CriarUsuario.css'

export default function CriarUsuario() {
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'    

    useEffect( () => {
        sessionStorage.removeItem('p')
    }, [])

    if(logado !== 'true'){
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='CriarUsuarioGeneralContainer'>
                    <SideBar />
                    <div className='CriarUsuarioContainer'>
                        <Header />
                        <div id="criarUsuarioTitlePage">
                            CRIAR USUÁRIO
                        </div>
                        <UsuariosNovoDados title={"NOVO USUÁRIO"} tableShow={true} />
                    </div>
                </div>
            </>
        )
    }
}