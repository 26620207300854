import React, { useState, useEffect } from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import FiltrarDadosQualitativas from '../../../Functions/FiltrarDadosQualitativas'
import GetAllQualitativas from '../../../services/GetAllQualitativas'
import QualitativasTabela from '../../../components/QualitativasTabela/QualitativasTabela'
import SelectAPI from '../../../Functions/SelectApi'
import './Qualitativas.css'

export default function QualitativasAdmin() {
    const [cursos, setCursos] = useState([])
    const [turmas, setTurmas] = useState([])
    const [valorCurso, setValorCurso] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [avaliacoes, setAvaliacoes] = useState([])
    const [valorAvaliacao, setValorAvaliacao] = useState('')
    const [searchStatusBarIcon, setSearchStatusBarIcon] = useState(true)
    const [userCursos, setUserCursos] = useState(value => value = sessionStorage.getItem('c'))
    const [qualitativas, setQualitativas] = useState([])
    const [filtroQualitativas, setFiltroQualitativas] = useState([])

    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    const baseUrl = SelectAPI(valorEscola)

    const titlesHeader = [
        "CURSO",
        "TURMA",
        "AVALIAÇÃO",
        ""
    ]

    const fieldsBody = [
        "Curso",
        "Turma",
        "Avaliacao"
    ]

    function cursosUserPermissao() {
        if (typeof (userCursos) === 'string') {
            const permitidos = userCursos?.split(';')?.filter(item => item !== '')
            setUserCursos(value => value = permitidos)
            return permitidos
        }
    }

    function FiltrarItemsPermitidos(dados, userCursosPermissao) {
        const resultado = dados?.filter(item => userCursosPermissao?.includes(item.CodCurso))
        setSearchStatusBarIcon(value => value = false)
        return resultado
    }

    const onChangeCurso = async (event) => {
        let valorCursoAtual = event.target.value
        setValorCurso(valorCursoAtual)

        const filtroDados = await FiltrarDadosQualitativas(
            qualitativas,
            valorCursoAtual,
            valorTurma,
            valorAvaliacao
        )
        const filtrarNotasPermitidas = FiltrarItemsPermitidos(filtroDados, userCursos)
        setFiltroQualitativas(filtrarNotasPermitidas)
    }

    const onChangeTurma = async (event) => {
        let valorTurmaAtual = event.target.value
        setValorTurma(valorTurmaAtual)

        const filtroDados = await FiltrarDadosQualitativas(
            qualitativas,
            valorCurso,
            valorTurmaAtual,
            valorAvaliacao
        )
        setFiltroQualitativas(filtroDados)
    }

    const onChangeAvaliacao = async (event) => {
        let valorAvaliacaoAtual = event.target.value
        setValorAvaliacao(event.target.value)

        const filtroDados = await FiltrarDadosQualitativas(
            qualitativas,
            valorCurso,
            valorTurma,
            valorAvaliacaoAtual
        )
        setFiltroQualitativas(filtroDados)
    }

    async function getQualitativas() {
        const qualitativasReturn = await GetAllQualitativas(baseUrl)
        setQualitativas(qualitativasReturn[0].dados)
        setCursos(qualitativasReturn[0].cursosUnicos)
        setTurmas(qualitativasReturn[0].turmasUnicas)
        setAvaliacoes(qualitativasReturn[0].avaliacoesUnicas)
        return qualitativasReturn
    }

    useEffect(() => {
        getQualitativas()
    }, [])

    useEffect(()=> {
        if(cursos.length > 0){
            const permitidos = cursosUserPermissao()
            const filtrarQualitativasPermitidas = FiltrarItemsPermitidos(qualitativas, permitidos)
            setFiltroQualitativas(filtrarQualitativasPermitidas)
        }
    }, [cursos])

    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='NotasGeneralContainerAdmin'>
                    <SideBar />
                    <div className='NotasContainerAdmin'>
                        <Header />

                        <div id="NotasTitlePage">
                            QUALITATIVAS
                        </div>

                        <div className='NotasSearchContainer'>
                            <select name="Curso" id="Curso" onChange={onChangeCurso} value={valorCurso} >
                                <option value='' id="Curso01">Curso</option>
                                {
                                    cursos.map((val, key) => {
                                        return (
                                            userCursos.includes(val.Codigo) === true ?
                                                <option value={val.Codigo} key={key} id="Curso01">{val.Curso}</option>
                                                : <></>
                                        )
                                    })
                                }
                            </select>
                            <select name="Turma" id="Turma" onChange={onChangeTurma} value={valorTurma} >
                                <option value='' id="Turma01">Turma</option>
                                {
                                    turmas.map((val, key) => {
                                        return (
                                            <option value={val} key={key} id="Turma01">{val}</option>
                                        )
                                    })
                                }
                            </select>
                            <select name="Avaliacao" id="Avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} >
                                <option value='' >Avaliação</option>
                                {
                                    avaliacoes.sort().map((val, key) => {
                                        return (
                                            <option value={val.Codigo} key={key} id={val}>{val.Avaliacao}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <QualitativasTabela
                            searchBarShow={false}
                            headerTableTitles={titlesHeader}
                            bodyDataRows={filtroQualitativas}
                            bodyTableFields={fieldsBody}
                            searchStatusIcon={searchStatusBarIcon}
                        />

                        <div className='button-Info-Container'>
                            <div id='registros-Notas-Info'>
                                Registros: {qualitativas.length}
                            </div>
                        </div>

                    </div>

                </div>
            </>
        )
    }
}