import Axios from 'axios'

export default async function CreateUsuario(BaseUrl, Nome, Senha, Cursos){
    let resultado = await Axios.post(`${BaseUrl}/createUsuario`, {
        Nome: Nome,
        Senha: Senha,
        Cursos: Cursos
    })

    return resultado
}