import './LancarNotas.css'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Header from '../../../components/header'
import Axios from 'axios';
import { Usuario } from '../../../context/userForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import ConfigService from '../../../services/config'
import FiltrarCurso from '../../../Functions/FiltrarCurso';
import OrdemItem from '../../../Functions/OrdenarItens';
const fileDownload = require('js-file-download');


toast.configure()

let valorCursoAtual = ''
let valorDisciplinaAtual = ''
let valorTurmaAtual = ''
let valorAvaliacaoAtual = ''
let valorNotaAuxAtual = ''
let notasNovas = []
let notasExistentes = []
let findNotasAuxAtual = []
let findLegenda = ''
let tipoLancamentoAtual = ''
let gradeFiltroAtual = []

export default function LancarNotas() {

    const { form, setForm } = Usuario()
    const { isLoged, setIsLoged } = Usuario()
    const [focus, setFocus] = useState(false)
    //VALORES GERAIS
    const [escola, setEscola] = useState(localStorage.getItem('escola'))
    const [permissaoExcluirDados, setPermissaoExcluirDados] = useState(localStorage.getItem('removerDados'))
    const [decimaisNota, setDecimaisNota] = useState(parseInt(localStorage.getItem('decimais_nota')))
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [configEscola, setConfigEscola] = useState([]);
    const [aluno, setAluno] = useState([]);
    const [curso, setCurso] = useState([]);
    const [digitacao, setDigitacao] = useState([]);
    const [disciplina, setDisciplina] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [notasAux, setNotasAux] = useState([]);
    const [grades, setGrades] = useState([])
    const [gradeFiltro, setGradeFiltro] = useState([])
    const [notasAuxFilter, setNotasAuxFilter] = useState([])
    const [findNotasAux, setFindNotasAux] = useState([]);
    const [nomeNotaAuxRef, setNomeNotaAuxRef] = useState('')
    const [faltasAlunos, setFaltasAlunos] = useState([]);
    const [consultaConteudo, setConsultaConteudo] = useState([])
    const [DataCompleta, setDataCompleta] = useState(new Date().toLocaleDateString().split('/'))
    const [DataAno, setDataAno] = useState('')
    const [DataMes, setDataMes] = useState('')
    const [DataDia, setDataDia] = useState('')
    const [professor, setProfessor] = useState(sessionStorage.getItem('professores'))
    //VALORES FILTRADOS
    const [permissao, setPermissao] = useState([]);
    const [profCursos, setProfCursos] = useState([]);
    const [disciplinaFilter, setDisciplinaFilter] = useState([])
    const [cursoFilter, setCursoFilter] = useState([])
    const [turmaFiltro, setTurmaFiltro] = useState([])
    const [alunosPermitidos, setAlunosPermitidos] = useState([])
    const [alunosFiltroGetFaltas, setAlunosFiltroGetFaltas] = useState(null)
    const [prevAlunosPermitido, setPrevAlunosPermitido] = useState([])

    const [valorGradeFiltro, setValorGradeFiltro] = useState('')
    const [valorData, setValorData] = useState('')
    const [valorAvaliacao, setValorAvaliacao] = useState('')
    const [valorNotaAux, setValorNotaAux] = useState('')
    const [valorConteudo, setValorConteudo] = useState('')
    const [valorNota, setValorNota] = useState('')
    const [valorAtividadeAula, setValorAtividadeAula] = useState('')
    const [valorAtividadeCasa, setValorAtividadeCasa] = useState('')
    const [valorCurso, setValorCurso] = useState('');
    const [valorDisciplina, setValorDisciplina] = useState([]);
    const [valorTurma, setValorTurma] = useState([])
    const [valorAula, setValorAula] = useState('')
    const { dadosForm, setDadosForm } = useState([])
    const [data, setData] = useState('')
    const [lancado, setLancado] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [tipoAvFinal, setTipoAvFinal] = useState('')

    //COMPONENTES
    const [componentNotaAux, setComponentNotaAux] = useState(null)
    const [checkView, setcheckView] = useState([])
    const [buttonDisable, setButtonDisable] = useState(false)
    const [deleteIsInvisible, setDeleteIsInvisible] = useState(true)
    const [detailsIsOpen, setDetailsIsOpen] = useState(false)
    const [lancarButtonText, setLancarButtonText] = useState('CONFIRMAR')
    const [lancarButtonLoadingClass, setLancarButtonLoadingClass] = useState('')
    const [excluirButtonText, setExcluirButtonText] = useState('EXCLUIR NOTAS')
    const [excluirButtonLoadingClass, setExcluirButtonLoadingClass] = useState('')
    const [confirmButtonDisable, setConfirmButtonDisable] = useState(false)
    //FORMULARIO
    const { register, handleSubmit, reset, errors } = useForm()
    const [session, setSession] = useState(sessionStorage)
    const { ApiFilter } = require('../../../Functions/FilterApiDomain')
    //VARIÁVEIS AUXILIARES
    let tipoAvaliacaoGrade = ''
    let logado = sessionStorage.getItem('isLoged') ? sessionStorage.getItem('isLoged') : 'falso'
    let idProf = form.length > 0 ? form.professores : session.professores //Index dos ids retornado inicia no valor 0. Colocar +1 pra nivelar com valor real do id do banco
    let profId = parseInt(form.length > 0 ? form.professores : session.professores)
    let permissaoFiltro = []
    let disciplinaDistinct = []
    let disciplinaPermissao = []
    let disciplinaFiltro = []
    let turmaPermissao = []
    let turmaDistinct = []
    let PrimeiroCurso = []
    let PrimeiraDisciplina = []
    let alunosPermissao = []
    let alunosPermitidosNomeOrdem = []
    let alunosPermitidosPush = []
    let alunoFiltroToGetFaltas = []
    let faltasAlunosPush = []

    const options = {
        header: { 'X-Custom-Header': 'value' }
    }

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState('#19648C');
    const override = css`
      background-color: rgba(0,0,0,0.2);
      position: absolute;
      margin-top: -14em;
      padding: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      cursor: progress;
    `;

    sessionStorage.getItem('isLoged') ? setIsLoged(true) : setIsLoged(false)

    //NOTIFICAÇÕES
    function notifySucessLancando() {
        toast.success('Concluindo lançamento', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }
    function notifySucessAtualizando() {
        toast.success('Concluindo atualização', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }
    function notifySucessLancando() {
        toast.success('Concluindo lançamento', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }
    function notifyInfoDeleteSucess() {
        toast.info('Concluindo exclusão!', { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }

    let baseUrl = ApiFilter(sub)

    useEffect(() => {
        setDataAno(DataCompleta[2])
        setDataMes(DataCompleta[1])
        setDataDia(DataCompleta[0])

        if (DataCompleta.length === 3) {
            setDataCompleta(`${DataAno}-${DataMes}-${DataDia}`)
        }
    }, [DataCompleta])

    async function getAvaliacoes() {
        await Axios.get(`${baseUrl}/notas/avaliacoes`)
            .then((response) => {

                let resultadoAvaliacao = response.data
                let avaliacaoPush = []
                let cursoSistema = cursoFilter.filter(item => item.Codigo === valorCursoAtual)[0]?.Sistema

                for (let value of resultadoAvaliacao) {
                    if (
                        value.Codigo === 'N100' && cursoSistema === value.Sistema ||  // 1° AV
                        value.Codigo === 'N200' && cursoSistema === value.Sistema ||  // 2° AV 
                        value.Codigo === 'N300' && cursoSistema === value.Sistema ||  // 3° AV
                        value.Codigo === 'N400' && cursoSistema === value.Sistema ||  // 4° AV
                        value.Codigo === 'N500' && cursoSistema === value.Sistema ||  // 5° AV
                        value.Codigo === 'N600' && cursoSistema === value.Sistema ||  // 6° AV
                        value.Codigo === 'N700' && cursoSistema === value.Sistema ||  // 7° AV
                        value.Codigo === 'N800' && cursoSistema === value.Sistema ||  // 8° AV
                        value.Codigo === 'S101' && cursoSistema === value.Sistema ||  // Re 1S
                        value.Codigo === 'S201' && cursoSistema === value.Sistema ||  // Re 2S
                        value.Codigo === 'N900' && cursoSistema === value.Sistema ||  // P.F
                        value.Codigo === 'N950' && cursoSistema === value.Sistema     // Re.F
                    ) {
                        avaliacaoPush.push(value)
                    }
                }
                setAvaliacoes(avaliacaoPush)
            }

            )

    }

    function pesquisarDados() {
        if (curso.length === 0) {
            async function getCursos() {
                await Axios.get(`${baseUrl}/cursos`)
                    .then((response) => {
                        //console.log('CURSO CONSULTADO')
                        setCurso(response.data)
                        getDisciplina()
                    })
                    .catch((error) => {
                        console.log('Erro GetCursos: ', error)
                    })
            }
            getCursos()

        } else {
            return
        }

        //DISCIPLINA GET
        async function getDisciplina() {
            if (disciplina.length === 0) {
                await Axios.get(`${baseUrl}/disciplinas`)
                    .then((response) => {
                        //console.log('DISCIPLINA CONSULTADa')
                        setDisciplina(response.data)
                        getAluno()
                    })
                    .catch((error) => {
                        console.log('Erro getDisciplina: ', error)
                    })
            }
        }

        //ALUNO GET
        async function getAluno() {
            if (aluno.length === 0) {
                await Axios.get(`${baseUrl}/alunos`)
                    .then((response) => {
                        //console.log('ALUNO CONSULTADO')
                        setAluno(response.data)
                        getdigitacao()
                    })
                    .catch((error) => {
                        console.log('Erro getAluno: ', error)
                    })
            }
        }

        //DIGITACAO GET
        async function getdigitacao() {
            if (digitacao.length === 0) {
                await Axios.get(`${baseUrl}/digitacao`)
                    .then((response) => {
                        //console.log('DIGITACAO CONSULTADO')
                        setDigitacao(response.data);
                        getNotasAuxLeg()
                    })
                    .catch((error) => {
                        console.log('Erro getdigitacao: ', error)
                    })
            }
        }

        //DIGITACAO GET
        async function getNotasAuxLeg() {
            if (notasAux.length === 0) {
                await Axios.get(`${baseUrl}/Notas/notasAuxLeg`)
                    .then((response) => {
                        setNotasAux(response.data);
                        getGrades()
                    })
                    .catch((error) => {
                        console.log('Erro notasAuxLeg: ', error)
                    })
            }
        }

        async function getGrades() {
            if (grades.length === 0) {
                await Axios.get(`${baseUrl}/Notas/getAllGrades`)
                    .then((response) => {
                        setGrades(response.data)
                    })
                    .catch((error) => {
                        console.log('Erro ao tentar consultar grades: ', error)
                    })
            }
        }
    }

    useEffect(() => {
        if (avaliacoes.length > 0) {
            let result = []
            ConfigService(baseUrl).then(response => {
                result = response
                setConfigEscola(result)
            })

            function compare(a, b) {
                // Use toUpperCase() to ignore character casing
                const ordemA = a.Ordem
                const ordemB = b.Ordem

                let comparison = 0;
                if (ordemA > ordemB) {
                    comparison = 1;
                } else if (ordemA < ordemB) {
                    comparison = -1;
                }
                return comparison;
            }

            const ordemAvaliacoes = avaliacoes.sort(compare)
            setAvaliacoes(ordemAvaliacoes)
        }
    }, [avaliacoes])

    //GETCURSOS && GETDIGITACAO
    useEffect(() => {
        //CURSOS GET
        pesquisarDados()
        FiltrarCursos()
    }, [escola]);

    useEffect(() => {
        setDigitacao(digitacao)
        setCurso(curso)
        setDisciplina(disciplina)
        setAluno(aluno)
        FiltrarCursos()
    }, [permissao])


    //FILTRAR CURSOS PERMITIDOS
    function FiltrarCursos() {
        let filtrarCursosImport = FiltrarCurso(permissao, curso)
        setCursoFilter(filtrarCursosImport)
        FiltrarDisciplina(valorCurso)
    }

    useEffect(() => {
        for (let valor of digitacao) {
            if (valor.CodFuncionario === idProf) {
                permissaoFiltro.push(valor)
            }
        }
        setPermissao(permissaoFiltro)
    }, [digitacao])

    useEffect(() => {
        if (valorCurso !== Number) {
            PrimeiroCurso = cursoFilter.map(item => item.codigo)[0]
        }

        if (valorDisciplina !== Number) {
            PrimeiraDisciplina = disciplinaFilter.map(item => item.CodDisciplina)[0]
        }
    }, [permissao])

    useEffect(() => {
        // FiltrarGrade()
        setDetailsIsOpen(false)
        FiltrarTurma(valorCurso, valorDisciplina)
    }, [valorDisciplina])

    useEffect(() => {
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)
    }, [valorCurso])

    useEffect(() => {
        FiltrarAluno(valorCurso, valorTurma)
    }, [valorTurma])

    useEffect(() => {
        setValorAula(0)
        setDetailsIsOpen(false)
    }, [alunosPermitidos])

    useEffect(() => {
        let count = 1
        while (count < 2 && valorAula !== 0 && faltasAlunos.length > 0) {
            setDetailsIsOpen(false)
            setTimeout(() => {
                //setDetailsIsOpen(!detailsIsOpen)
            }, 3000)
            count = count + 1
        }
        if (detailsIsOpen !== true) {
            setDetailsIsOpen(true)
        }
    }, [faltasAlunos])

    //FILTRAR PERMISSAO DE ACORDO COM O CURSO SELECIONADO
    function PermisssaoSelecionado(curso_selecionado) {
        disciplinaPermissao = []
        //Varrer Permissao
        for (let valor of permissao) {
            if (valor.CodCurso === curso_selecionado) {
                disciplinaPermissao.push(valor)
            }
        }
    }
    PermisssaoSelecionado()

    //FILTRAR DISCIPLINA DE ACORDO COM O CURSO SELECIONADO
    function FiltrarDisciplina(curso_selecionado) {
        disciplinaFiltro = []
        setDisciplinaFilter([])
        PermisssaoSelecionado(curso_selecionado)
        disciplinaDistinct = [...new Set(disciplinaPermissao.map(item => item.CodDisciplina))]

        for (let idDisciplina of disciplinaDistinct) {
            //Varrer Disciplina
            for (let valor of disciplina) {

                if (valor.Codigo === idDisciplina) {
                    disciplinaFiltro.push(valor)
                }
            }
        }
        setDisciplinaFilter(disciplinaFiltro)
    }

    //disciplinaPermissao Where codigo_disciplina === disciplinaSelecionada
    async function FiltrarTurma(curso_selecionado, disciplina_selecionada) {
        turmaPermissao = []
        setTurmaFiltro([])
        //Varrer permissao por curso e turma selecionados
        for (let valor of permissao) {
            if (valor.CodCurso === curso_selecionado && valor.CodDisciplina === disciplina_selecionada) {
                turmaPermissao.push(valor);

            }
        }
        //Salvar Array de Turmas evitando duplicidade
        turmaDistinct = [...new Set(turmaPermissao.map(item => item.Turma))]
        setTurmaFiltro(turmaDistinct)
    }

    function FiltrarAluno(curso_selecionado, turma_selecionada) {
        //Reinicia variavel alunosPermitidosPush
        alunosPermitidosPush = []
        setAlunosPermitidos([])
        let contador = 0

        //Pega alunos permitidos
        alunosPermissao = aluno.filter(item => item.Curso === curso_selecionado && item.Turma === turma_selecionada)

        //Pega apenas os nomes em ordem alfabetica
        alunosPermitidosNomeOrdem = alunosPermissao.map((item) => item.Nome).sort()

        //Compara cada nome da ordem alfabetica com o alunosPermissao. E adiciona em um array
        for (let valor of alunosPermitidosNomeOrdem) {
            alunosPermitidosPush.push(alunosPermissao.find((item) => item.Nome === valor))
        }

        const alunosOrdenados = OrdemItem(alunosPermitidosPush, 'Nome')
        setAlunosPermitidos(alunosOrdenados)
        // setAlunosPermitidos(alunosPermitidosPush)
        setDetailsIsOpen(false)
        setDetailsIsOpen(true)
    }

    function FiltrarGrade() {

        if (valorCursoAtual !== '' && valorDisciplinaAtual.length !== 0) {
            const filtro = grades.filter(item => item.Curso === valorCursoAtual && item.Disciplina === valorDisciplinaAtual)
            const tipoAvaliacao = filtro[0]?.TipoAvaliacao

            if (tipoAvaliacao === 'S') {
                setConfirmButtonDisable(true)
                setButtonDisable(true)
                setAlunosPermitidos([])
                setValorAvaliacao('')
                setValorTurma([])
                window.alert('Essa disciplina é do tipo SEM AVALIAÇÃO para o curso selecionado.')

            } else {
                setConfirmButtonDisable(false)
                setButtonDisable(false)
            }

            tipoAvaliacaoGrade = filtro[0]?.TipoAvaliacao
            console.log('TIPO_AV: ', tipoAvaliacaoGrade)
            gradeFiltroAtual = filtro
            setGradeFiltro(filtro)
            return filtro[0]?.TipoAvaliacao

        } else {
            console.log('ELSE FILTRAR GRADE')
        }
    }

    //FILTRAR DIGITA[cursoFilter]CAO PERMITIDA
    useEffect(() => {
        FiltrarCursos()
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)

        FiltrarTurma(valorCurso, valorDisciplina)
    }, [digitacao])

    useEffect(() => {
        PermisssaoSelecionado(valorCurso)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)
    }, [valorCurso])

    useEffect(() => {
        if (cursoFilter.length === 0) {
            setLoading(true)
        } else {
            setLoading(false)
            if (avaliacoes.length === 0) {
                getAvaliacoes()
            } else {
                return
            }
        }
    }, [cursoFilter])


    const onChangeCurso = async (event) => {
        valorCursoAtual = event.target.value
        const isDiferent = valorCursoAtual !== valorCurso ? true : false

        setValorCurso(valorCursoAtual)
        FiltrarDisciplina(valorCursoAtual)
        setFaltasAlunos([])

        notasAuxiliares()
        getAvaliacoes()

        if (isDiferent === true && alunosPermitidos.length > 0) {
            setValorDisciplina('')
            setValorTurma('')
            setValorAvaliacao('')
            FiltrarTurma(valorCursoAtual)
        }

        if (alunosPermitidos.length !== 0 && valorAvaliacao !== '' && valorCursoAtual !== 9999 && valorTurma !== 4444 && valorDisciplina !== 6666) {
            console.log('Entrou filtro changeCurso')
            setTurmaFiltro([])
            notasAuxiliares()
        }
    }

    const onChangeDisciplina = (event) => {
        valorDisciplinaAtual = event.target.value

        const isDiferent = valorDisciplinaAtual !== valorDisciplina ? true : false
        setValorDisciplina(event.target.value)
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplinaAtual)

        if (alunosPermitidos.length !== 0 && isDiferent === true && valorAvaliacao !== '' && valorCurso !== 9999 && valorTurma !== 4444 && valorDisciplinaAtual !== 6666) {
            const tipoAv = FiltrarGrade()
            const tipoLancamento = filterTipoAvFinal()
            handleGetFaltas(alunosPermitidos, tipoLancamento)
            filtrarAlunosGetFaltas()
            notasAuxiliares()
        }
    }

    const onChangeTurma = async (event) => {
        valorTurmaAtual = event.target.value
        const isDiferent = valorTurmaAtual !== valorTurma ? true : false

        if (valorTurmaAtual !== valorTurma && valorTurmaAtual !== 4444 || valorTurmaAtual !== '') {
            setValorTurma(event.target.value)
        } else {
            setValorTurma(valorTurma)
        }

        FiltrarAluno(valorCurso, valorTurmaAtual)

        if ((alunosPermitidos.length !== 0 && isDiferent === true)
            && (valorCurso !== 9999 && valorCurso !== '')
            && (valorDisciplina !== 6666 && valorDisciplina !== '')
            && (valorTurmaAtual !== 4444 && valorTurmaAtual !== '' && valorTurma !== 4444 && valorTurma !== '')
            && (valorAvaliacao !== 2222 && valorAvaliacao !== '')
        ) {
            const tipoAv = FiltrarGrade()
            const tipoLancamento = filterTipoAvFinal()
            handleGetFaltas(alunosPermitidosPush, tipoLancamento)
            filtrarAlunosGetFaltas()
            notasAuxiliares()
        } else if (valorAvaliacao === '' || valorAvaliacao === 2222) {
            setFaltasAlunos([])
        } else {
            filtrarAlunosGetFaltas()
            notasAuxiliares()
        }
    }

    const notasAuxiliares = (event) => {
        let chave = valorCursoAtual + valorTurmaAtual + valorDisciplinaAtual + valorAvaliacaoAtual
        findNotasAuxAtual = notasAux.filter(item => item.Chave === chave)
        setFindNotasAux(findNotasAuxAtual)
    }

    const onChangeAvaliacao = async (event) => {

        valorAvaliacaoAtual = event.target.value
        if (valorAvaliacaoAtual === valorAvaliacao) {
            setFindNotasAux([])

        }

        if (valorAvaliacaoAtual === '2222') {
            setFaltasAlunos([])
        }

        notasAuxiliares()

        const isDiferent = valorAvaliacaoAtual !== valorAvaliacao ? true : false

        if (alunosPermitidos.length > 0
            && isDiferent === true
            && valorDisciplina !== 6666 && valorDisciplina !== ''
            && valorTurma !== 4444 && valorTurma !== ''
            && valorAvaliacaoAtual !== ''
            && valorAvaliacaoAtual !== '2222'
        ) {
            const tipoAv = FiltrarGrade()
            const tipoLancamento = filterTipoAvFinal()
            handleGetFaltas(alunosPermitidos, tipoLancamento)

        }
        setValorAvaliacao(valorAvaliacaoAtual)
        onChangeNotasAux()
    }

    const onClickAlunos = () => {
        FiltrarDisciplina(valorCurso)
        FiltrarTurma(valorCurso, valorDisciplina)

        filtrarAlunosGetFaltas()

        if (faltasAlunos.length > 0) {
            let maiorNumeroFaltas = faltasAlunos.map((item) => item.faltas).sort().reverse()
            let aulasNumero = maiorNumeroFaltas[0]
        }
    }

    //Ativar Botão DELETE 
    function ativarBotaoDelete() {
        if (valorAvaliacao === 2222 || valorAvaliacao === '' && faltasAlunos.length === 0) {
            setDeleteIsInvisible(true)
        } else {
            setDeleteIsInvisible(false)
        }

        if (faltasAlunos[0]?.length === 0) {
            setDeleteIsInvisible(true)
        }
    }

    useEffect(() => {
        ativarBotaoDelete()
    }, [faltasAlunos])

    useEffect(() => {
        ativarBotaoDelete()
    }, [valorAvaliacao])


    const showObservacao = () => {
        // const tipoLancamento = filterTipoAvFinal()
        if (tipoAvFinal === 'S') {
            return 'Sem avaliação'

        } else if (tipoAvFinal === 'N') {
            return 'TIPO: NOTA. OBS.: Ao digitar notas, utilizar ponto em vez de vírgula. Ex: (5.0)'

        } else if (tipoAvFinal === 'S') {
            return 'TIPO: CONCEITO'
        }
    }

    function handleGetFaltas(alunos, tipoAvaliacao) {
        const chave = valorCursoAtual + valorTurmaAtual + valorDisciplinaAtual + valorAvaliacaoAtual
        const auxiliar = valorNotaAuxAtual !== '' && valorNotaAuxAtual !== undefined ? findLegenda : null
        const tipoAv = tipoAvaliacao

        console.log('GET-FALTAS FIRED')
        let tipoAvCurso = grades.filter(item => item.Curso === valorCursoAtual && item.Disciplina === valorDisciplinaAtual)[0]?.TipoAvaliacao
        if (tipoAvCurso === '') {
            const tipoCurso = cursoFilter[0].TipoDados
            if (tipoCurso === "N") {
                tipoAvCurso = tipoCurso
            }
        }



        async function getFaltasAlunos() {
            setLoading(true)

            await Axios.post(`${baseUrl}/Notas/getFaltas`, {
                escola: escola,
                alunos: alunos,
                chave: chave,
                notasAux: auxiliar,
                tipoAvaliacao: tipoAv
            })
                .then((response) => {
                    faltasAlunosPush = []
                    faltasAlunosPush.push(response.data)
                    setFaltasAlunos(faltasAlunosPush)
                    setLoading(false)

                    if (faltasAlunosPush.length > 0) {
                        filtrarAlunosGetFaltas()
                    }
                })

                .catch((error) => {
                    console.log('Erro: ', error)
                    setFaltasAlunos([])
                    setLoading(false)
                })
        }

        if (alunosPermitidos.length > 0) {

            if (findNotasAuxAtual.length > 0 && valorNotaAux === '') {
                return

            } else {
                console.log('Consulta Nota', tipoAvCurso)
                const tipoLancamento = filterTipoAvFinal()
                console.log('GETtipoAv', tipoLancamento)

                if (tipoLancamento === 'S') {
                    // window.alert('Sem avaliação para a grade selecionada')
                    console.log('GET TIPO S = Sem avaliação')
                } else {
                    if (tipoLancamento === 'N') {
                        getFaltasAlunos()
                    } else {
                        handleGetConceitos(alunos)
                    }
                }

                // //Tipo de nota da disciplina dentro do curso em Grades
                // if (tipoAvCurso === "") {
                //     const tipoCurso = cursoFilter[0].TipoDados

                //     //Tipo nota apenas do curso
                //     if (tipoCurso === "N") {
                //         getFaltasAlunos()
                //     } else {
                //         handleGetConceitos(alunos)
                //     }

                // } else if (tipoAvCurso === "N") {
                //     getFaltasAlunos()
                // } else {
                //     handleGetConceitos(alunos)
                // }
            }
        }
    }


    function handleGetConceitos(alunos, dataAtual) {
        const chave = valorCursoAtual + valorTurmaAtual + valorDisciplinaAtual + valorAvaliacaoAtual
        const auxiliar = valorNotaAuxAtual !== '' && valorNotaAuxAtual !== undefined ? findLegenda : null

        async function getFaltasAlunos() {
            setLoading(true)

            await Axios.post(`${baseUrl}/Notas/getConceitos`, {
                escola: escola,
                alunos: alunos,
                chave: chave,
                notasAux: auxiliar
            })
                .then((response) => {
                    faltasAlunosPush = []
                    //console.log(response.data)
                    faltasAlunosPush.push(response.data)
                    setFaltasAlunos(faltasAlunosPush)
                    console.log('faltasAlunosPush return:', faltasAlunosPush)

                    setLoading(false)
                    if (faltasAlunosPush.length > 0) {
                        filtrarAlunosGetFaltas()
                    }
                })
                .catch((error) => {
                    console.log('Erro: ', error)
                    setFaltasAlunos([])
                    setLoading(false)
                })
        }

        if (alunosPermitidos.length > 0) {
            console.log('ENTROU AQUI 1')
            console.log('valorNotaAux: ', valorNotaAux)
            console.log('findNotasAux: ', findNotasAux)
            if (findNotasAuxAtual.length > 0 && valorNotaAux === '') {
                console.log('ENTROU AQUI 2')
                return
            } else {
                console.log('ENTROU AQUI 3')
                getFaltasAlunos()
            }
        }
    }

    const mostrarNota = (val, key) => {
        let valor = 'S/R'
        let result = []
        if (findNotasAux.length === 0) {
            result = faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)
        } else {
            result = faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)
        }

        if (faltasAlunos[0] === 'ETIMEDOUT') {
            let confirmar = window.confirm('Conexão de rede está lenta, verifique sua rede e recarregue a página')
            valor = 'S/R'
            if (confirmar === true) {
                window.location.reload()
            }
        } else {
            if (result !== undefined) {

                if (result.length !== 0) {

                    if (valorNotaAuxAtual?.length > 0) {
                        let auxNumber = findLegenda.slice(3, 5)
                        valor = result[0][`auxiliar_${auxNumber}`]

                    } else {
                        valor = result !== undefined ? result[0]?.nota : 'S/R'
                    }

                } else {
                    valor = 'S/R'
                }
            } else {
                valor = 'S/R'
            }
        }

        valor = valor !== null ? valor : 'S/R'

        if (valor !== 'S/R' || valor !== '') {
            valor = valor.toString()
            if (valor.length === 1) {
                valor = `${valor}.0`
            }
        } else {
            valor = 'S/R'
        }

        return valor
    }

    const mostrarConceito = (val, key) => {
        let valor = 'S/R'
        let result = []
        if (findNotasAux.length === 0) {
            result = faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)
        } else {
            result = faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)
        }

        if (faltasAlunos[0] === 'ETIMEDOUT') {
            let confirmar = window.confirm('Conexão de rede está lenta, verifique sua rede e recarregue a página')
            valor = 'S/R'
            if (confirmar === true) {
                window.location.reload()
            }
        } else {
            if (result !== undefined) {

                if (result.length !== 0) {
                    valor = result !== undefined ? result[0]?.conceito : 'S/R'
                    if (valor === null) {
                        if (valorNotaAuxAtual !== '' && findLegenda !== undefined) {
                            let auxNumber = findLegenda.slice(3, 5)
                            valor = result[0][`auxiliar_${auxNumber}`]
                        }
                        valor = valor !== null ? valor : 'S/R'
                    }
                } else {
                    valor = 'S/R'
                }
            } else {
                valor = 'S/R'
            }
        }

        if (valor !== 'S/R' || valor !== '') {
            valor = valor.toString()
            if (valor.length === 1) {
                valor = `${valor}.0`
            }
        } else {
            valor = 'S/R'
        }

        return valor
    }

    const filterNotasAux = () => {
        let filterLegendas = []
        let newObject = {}
        for (const value in findNotasAuxAtual[0]) {
            let auxLength = findNotasAuxAtual[0][value]?.length
            if (auxLength !== 0 && value !== 'Chave' && value !== 'id') {
                newObject[value] = findNotasAuxAtual[0][value]
            }
        }

        filterLegendas.push(newObject)
        return filterLegendas
    }

    const onChangeNotasAux = (event) => {
        notasAuxiliares()

        valorNotaAuxAtual = event?.target?.value
        setValorNotaAux(valorNotaAuxAtual)

        if (findNotasAuxAtual.length > 0) {
            const filtroLegenda = filterNotasAux()

            const legendasArray = Object.values(filtroLegenda[0])
            setNotasAuxFilter(legendasArray)
        }

        if (findNotasAuxAtual.length > 0) {
            findLegenda = Object.keys(findNotasAuxAtual[0]).find(key => findNotasAuxAtual[0][key] === valorNotaAuxAtual)
        }

        if (findNotasAuxAtual.length > 0) {
            const tipoLancamento = filterTipoAvFinal()
            handleGetFaltas(alunosPermitidos, tipoLancamento)
        }
    }

    const showNotasAuxField = (type, val) => {
        let name = ''

        if (findNotasAuxAtual.length > 0) {
            findLegenda = Object.keys(findNotasAuxAtual[0]).find(key => findNotasAuxAtual[0][key] === valorNotaAuxAtual)
        } else {
            name = null
        }

        if (type === 'title') {
            return <th>{valorNotaAux}</th>
        }
    }

    async function LancarNotasFaltas(dado) {
        let countNotification = 0
        const dia = new Date().getDate()
        const mes = new Date().getMonth() + 1
        const ano = new Date().getFullYear()
        const dataCompleta = `${ano}-${mes}-${dia}`
        const auxiliar = dado.notasAux !== '' && dado.notasAux !== undefined ? dado.notasAux : null
        let tipoAvCurso = grades.filter(item => item.Curso === valorCursoAtual && item.Disciplina === valorDisciplinaAtual)[0]?.TipoAvaliacao
        if (tipoAvCurso === '') {
            const tipoCurso = cursoFilter[0].TipoDados
            if (tipoCurso === "N") {
                tipoAvCurso = tipoCurso
            }
        }

        await Axios.post(`${baseUrl}/Notas/lancarNotas`,
            {
                escola: escola,
                data: dataCompleta,
                formulario: dado,
                curso: dado.curso,
                turma: dado.turma,
                disciplina: dado.disciplina,
                avaliacao: dado.avaliacao,
                tipoAvaliacao: tipoLancamentoAtual,
                notasAux: auxiliar
            }, options)
            .then(() => {
                if (countNotification === 0) {
                    setButtonDisable(true)
                    setLancarButtonLoadingClass('loadingLançar')
                    setLancarButtonText('    ENVIANDO...')
                    if (notasExistentes.length > 0) {
                        AtualizarNotasFaltas(notasExistentes)
                    } else {
                        notifySucessLancando()
                        setTimeout(function () {
                            window.location.replace(window.location.origin + '/Notas/LancarNotas')
                        }, 4000)
                    }
                    countNotification = 1
                }
            })
            .catch(() => {
                window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                setButtonDisable(false)
                setLancarButtonLoadingClass('')
                setLancarButtonText('CONFIRMAR')
            })
    }

    async function AtualizarNotasFaltas(dado) {
        let countNotification = 0
        const dia = new Date().getDate()
        const mes = new Date().getMonth() + 1
        const ano = new Date().getFullYear()
        const dataCompleta = `${ano}-${mes}-${dia}`
        const auxiliar = dado.notasAux !== '' && dado.notasAux !== undefined ? dado.notasAux : null
        let tipoAvCurso = grades.filter(item => item.Curso === valorCursoAtual && item.Disciplina === valorDisciplinaAtual)[0]?.TipoAvaliacao
        if (tipoAvCurso === '') {
            const tipoCurso = cursoFilter[0].TipoDados
            if (tipoCurso === "N") {
                tipoAvCurso = tipoCurso
            }
        }


        await Axios.post(`${baseUrl}/Notas/updateNotas`,
            {
                escola: escola,
                data: dataCompleta,
                formulario: dado,
                curso: dado.curso,
                turma: dado.turma,
                disciplina: dado.disciplina,
                avaliacao: dado.avaliacao,
                tipoAvaliacao: tipoLancamentoAtual,
                notasAux: auxiliar
            }, options)
            .then(() => {
                if (countNotification === 0) {
                    notifySucessAtualizando()
                    setButtonDisable(true)
                    setLancarButtonLoadingClass('loadingLançar')
                    setLancarButtonText('    ENVIANDO...')
                    setTimeout(function () {
                        window.location.replace(window.location.origin + '/Notas/LancarNotas')
                        window.location.reload()
                    }, 4000)
                    countNotification = 1
                }
            })
            .catch(() => {
                window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                setButtonDisable(false)
                setLancarButtonLoadingClass('')
                setLancarButtonText('CONFIRMAR')
            })
    }

    //EXPORTAR NOTAS DE ACORDO COM OS ITENS SELECIONADOS
    async function ExportarNotas() {
        const filename = valorAvaliacao + professor + valorCurso + valorTurma + valorDisciplina
        setButtonDisable(true)

        if (faltasAlunos.length === 0 || faltasAlunos[0] === undefined || faltasAlunos[0]?.length === 0) {
            window.alert('Sem notas lançadas')
        } else {
            await Axios.get(`${baseUrl}/Notas/getNotasExport?Curso=${valorCurso}&Turma=${valorTurma}&Disciplina=${valorDisciplina}&Avaliacao=${valorAvaliacao}&Professor=${professor}`,
                {
                    responseType: 'blob', //FORMATO PARA DOWNLOAD NO CABEÇALHO
                }
            )
                .then(res => {
                    fileDownload(res.data, `${filename}`);
                    setButtonDisable(false)
                })
                .catch((err) => {
                    console.log('Erro exportar: ', err)
                    window.alert('Notas não encontradas na consulta')
                })
        }
    }

    async function ExportAllNotas() {
        const digitacaoFilter = digitacao.filter(item => item.CodFuncionario === professor)
        const cursoFiltro = [...new Set(digitacaoFilter.map(item => item.CodCurso))]
        const disciplinaFiltro = [...new Set(digitacaoFilter.map(item => item.CodDisciplina))]
        const turmaFiltro = [...new Set(digitacaoFilter.map(item => item.Turma))]
        const filename = valorAvaliacao + professor
        setButtonDisable(true)

        await Axios.get(`${baseUrl}/Notas/getAllNotasExport?Cursos=${cursoFiltro}&Turmas=${turmaFiltro}&Disciplinas=${disciplinaFiltro}&Avaliacao=${valorAvaliacao}&Professor=${professor}`,
            {
                responseType: 'blob' //FORMATO PARA DOWNLOAD NO CABEÇALHO
            }
        )
            .then(res => {
                fileDownload(res.data, `${filename}.rar`)
                setButtonDisable(false)
            })
            .catch(err => {
                console.log('Erro AllNotas: ', err)
            })

    }

    async function deletarDados(dado) {
        let countNotification = 0
        let excluirAlunos = []
        const dia = new Date().getDate()
        const mes = new Date().getMonth() + 1
        const ano = new Date().getFullYear()
        const dataCompleta = `${ano}-${mes}-${dia}`
        const auxiliar = dado.notasAux !== '' && dado.notasAux !== undefined ? findLegenda : null
        let tipoAvCurso = grades.filter(item => item.Curso === valorCursoAtual && item.Disciplina === valorDisciplinaAtual)[0]?.TipoAvaliacao
        if (tipoAvCurso === '') {
            const tipoCurso = cursoFilter[0].TipoDados
            if (tipoCurso === "N") {
                tipoAvCurso = tipoCurso
            }
        }

        for (let aluno of alunosPermitidos) {
            let find = faltasAlunos[0]?.filter(item => item.matricula === aluno.Matricula)
            if (find.length > 0) {
                excluirAlunos.push(find[0])
            }
        }

        if (excluirAlunos.length > 0) {
            setExcluirButtonLoadingClass('loadingLançar')
            setExcluirButtonText('    EXCLUINDO...')

            await Axios.post(`${baseUrl}/Notas/deletar-notas`,
                {
                    escola: escola,
                    formulario: excluirAlunos,
                    data: dataCompleta,
                    notasAux: auxiliar,
                    tipoAvaliacao: tipoAvCurso
                }, options)
                .then((result) => {
                    const resultado = result.data
                    const resultadoEsperado = 'Notas e Faltas deletados'
                    if (resultado === resultadoEsperado) {
                        if (countNotification === 0) {
                            setDeleteIsInvisible(true)
                            notifyInfoDeleteSucess()
                            setButtonDisable(true)

                            setTimeout(function () {
                                console.log('Resultado dados: ', result.data)
                                window.location.replace(window.location.origin + '/Notas/LancarNotas')
                            }, 4000)
                            countNotification = 1
                        }
                    }
                })
                .catch((error) => {
                    window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                    console.log(error)
                    setButtonDisable(false)
                    setDeleteIsInvisible(false)
                    setExcluirButtonLoadingClass('')
                    setExcluirButtonText('EXCLUIR AULA')
                })
        } else {
            window.alert('Não foram encontrados registros para excluir')
        }
    }

    useEffect(() => {
        filterTipoAvFinal()
    }, [gradeFiltro])

    const filterTipoAvFinal = () => {
        tipoLancamentoAtual = ''

        if (gradeFiltroAtual.length !== 0 && cursoFilter.length !== 0) {
            const tipoAvDisciplina = gradeFiltroAtual[0]?.TipoAvaliacao
            const tipoAvCurso = cursoFilter?.filter(item => item.Codigo === valorCursoAtual)[0]?.TipoDados
            console.log('TipoDisciplina', tipoAvDisciplina)
            console.log('TipoCurso', tipoAvCurso)

            if (tipoAvDisciplina === 'S') {         //Disciplina sem avaliacao
                tipoLancamentoAtual = 'S'

            } else {                              // Disciplina vazio (''), Nota (N) ou Conceito (C)

                if (tipoAvDisciplina !== '') {      //Existe tipo disciplina

                    if (tipoAvDisciplina === 'N' || tipoAvDisciplina === 'R') { //Tipo N explicito
                        tipoLancamentoAtual = 'N'
                    } else {                      //Qualquer tipo diferente de N considera conceito
                        tipoLancamentoAtual = 'C'
                    }

                } else {                          //Não existe tipo disciplina
                    //Procura tipo curso
                    if (tipoAvCurso === '') {       //Sem tipo de avaliacao
                        tipoLancamentoAtual = 'S'

                    } else {
                        if (tipoAvCurso === 'N' || tipoAvCurso === 'R') {  //Tipo Nota
                            tipoLancamentoAtual = 'N'
                        } else {
                            tipoLancamentoAtual = 'C' //Tipo Conceito
                        }
                    }
                }
            }

            // setTipoAvFinal(tipoLancamentoAtual)
        } else {
            tipoLancamentoAtual = 'S'
        }

        if (tipoLancamentoAtual !== tipoAvFinal) {
            setTipoAvFinal(tipoLancamentoAtual)
        }
        console.log('tipoLancamentoAtual', tipoLancamentoAtual)
        return tipoLancamentoAtual
    }

    const onSubmit = async (dado) => {
        let notas = []
        notasNovas = []
        notasExistentes = []

        const tipoLancamento = filterTipoAvFinal()

        console.log('Dados: ', dado)

        function onSubmitConfirm() {
            //FORMATAR DADOS
            setConfirmButtonDisable(true)
            setButtonDisable(true)
            for (let value in dado) {
                let numeroMatricula = value.toString()
                let identify = numeroMatricula[numeroMatricula.length - 1]
                let validMatricula = parseInt(numeroMatricula)
                let isValidMatricula = isNaN(numeroMatricula) !== true ? numeroMatricula + 'Leg' : ''

                if (identify !== "F" && identify !== "g") {
                    //FORMATAR NOTAS COM CASAS DECIMAIS
                    if (isNaN(validMatricula) !== true && dado[value] !== '' || isValidMatricula.length === 11 && numeroMatricula !== undefined) {
                        let nota = dado[numeroMatricula] !== undefined ? dado[numeroMatricula]?.replace(',', '.') : ''
                        let notaLeg = dado[isValidMatricula] !== undefined ? dado[isValidMatricula]?.replace(',', '.') : ''

                        if (nota.length === 1) {
                            nota = `${nota}.0`
                        }
                        if (nota > 10) {
                            nota = '10'
                        } else if (nota < 0) {
                            nota = '0'
                        } else if (nota.split('.')[0] === 10) {
                            nota = '10'
                        }

                        if (isValidMatricula !== '') {
                            if (notaLeg.length === 1) {
                                notaLeg = `${notaLeg}.0`
                            }
                            if (notaLeg > 10) {
                                notaLeg = '10'
                            } else if (notaLeg < 0) {
                                notaLeg = '0'
                            } else if (notaLeg.split('.')[0] === 10) {
                                notaLeg = '10'
                            }
                        }

                        let falta = dado[numeroMatricula + "F"]
                        if (isValidMatricula !== '' && notaLeg !== '') {
                            notas.push({ Matricula: numeroMatricula, NotaLeg: notaLeg, Nota: nota, Falta: falta })
                        } else if (nota !== '') {
                            notas.push({ Matricula: numeroMatricula, Nota: nota, Falta: falta })
                        }
                    }
                } else if (identify !== "g") {

                }
            }

            //SEPARAR NOTAS NOVAS DAS NOTAS EXISTENTES
            for (let value of notas) {
                let nota = faltasAlunos[0]?.filter(item => item?.matricula === value?.Matricula)[0]
                if (nota === undefined) {
                    notasNovas.push(value)
                } else {
                    notasExistentes.push(value)
                }
            }

            //COMPLEMENTAR DADOS
            notasNovas["curso"] = dado.curso.toString()
            notasNovas["turma"] = dado.turma.toString()
            notasNovas["disciplina"] = dado.disciplina.toString()
            notasNovas["avaliacao"] = dado.avaliacao.toString()
            if (valorNotaAux !== '' && findNotasAuxAtual.length > 0) {
                notasNovas["notasAux"] = Object.keys(findNotasAuxAtual[0]).find(key => findNotasAuxAtual[0][key] === valorNotaAux)
            }

            notasExistentes["curso"] = dado.curso.toString()
            notasExistentes["turma"] = dado.turma.toString()
            notasExistentes["disciplina"] = dado.disciplina.toString()
            notasExistentes["avaliacao"] = dado.avaliacao.toString()
            if (valorNotaAux !== '' && findNotasAuxAtual.length > 0) {
                notasExistentes["notasAux"] = Object.keys(findNotasAuxAtual[0]).find(key => findNotasAuxAtual[0][key] === valorNotaAux)
            }

            if (notasNovas.length > 0) {
                LancarNotasFaltas(notasNovas)
            } else {
                AtualizarNotasFaltas(notasExistentes)
            }


        }

        function onSubmitConfirmConceito() {
            //FORMATAR DADOS
            setConfirmButtonDisable(true)
            setButtonDisable(true)
            for (let value in dado) {
                let numeroMatricula = value.toString()
                let identify = numeroMatricula[numeroMatricula.length - 1]
                let validMatricula = parseInt(numeroMatricula)
                let isValidMatricula = isNaN(numeroMatricula) !== true ? numeroMatricula + 'Leg' : ''

                if (identify !== "F" && identify !== "g") {
                    //FORMATAR NOTAS COM CASAS DECIMAIS
                    if (isNaN(validMatricula) !== true && dado[value] !== '' || isValidMatricula.length === 11 && numeroMatricula !== undefined) {
                        let nota = dado[numeroMatricula] !== undefined ? dado[numeroMatricula]?.replace(',', '.') : ''
                        let notaLeg = dado[isValidMatricula] !== undefined ? dado[isValidMatricula]?.replace(',', '.') : ''

                        // if (nota.length === 1) {
                        //     nota = `${nota}.0`
                        // }

                        // if (nota > 10) {
                        //     nota = '10'
                        // } else if (nota < 0) {
                        //     nota = '0'
                        // } else if (nota.split('.')[0] === 10) {
                        //     nota = '10'
                        // }

                        // if (isValidMatricula !== '') {
                        //     if (notaLeg.length === 1) {
                        //         notaLeg = `${notaLeg}.0`
                        //     }
                        //     if (notaLeg > 10) {
                        //         notaLeg = '10'
                        //     } else if (notaLeg < 0) {
                        //         notaLeg = '0'
                        //     } else if (notaLeg.split('.')[0] === 10) {
                        //         notaLeg = '10'
                        //     }
                        // }

                        let falta = dado[numeroMatricula + "F"]
                        if (isValidMatricula !== '' && notaLeg !== '') {
                            notas.push({ Matricula: numeroMatricula, NotaLeg: notaLeg, Nota: nota, Falta: falta })
                        } else if (nota !== '') {
                            notas.push({ Matricula: numeroMatricula, Nota: nota, Falta: falta })
                        }
                    }
                } else if (identify !== "g") {

                }
            }

            //SEPARAR NOTAS NOVAS DAS NOTAS EXISTENTES
            for (let value of notas) {
                let nota = faltasAlunos[0]?.filter(item => item?.matricula === value?.Matricula)[0]
                if (nota === undefined) {
                    notasNovas.push(value)
                } else {
                    notasExistentes.push(value)
                }
            }

            //COMPLEMENTAR DADOS
            notasNovas["curso"] = dado.curso.toString()
            notasNovas["turma"] = dado.turma.toString()
            notasNovas["disciplina"] = dado.disciplina.toString()
            notasNovas["avaliacao"] = dado.avaliacao.toString()
            if (valorNotaAux !== '' && findNotasAuxAtual.length > 0) {
                notasNovas["notasAux"] = Object.keys(findNotasAuxAtual[0]).find(key => findNotasAuxAtual[0][key] === valorNotaAux)
            }

            notasExistentes["curso"] = dado.curso.toString()
            notasExistentes["turma"] = dado.turma.toString()
            notasExistentes["disciplina"] = dado.disciplina.toString()
            notasExistentes["avaliacao"] = dado.avaliacao.toString()
            if (valorNotaAux !== '' && findNotasAuxAtual.length > 0) {
                notasExistentes["notasAux"] = Object.keys(findNotasAuxAtual[0]).find(key => findNotasAuxAtual[0][key] === valorNotaAux)
            }

            if (notasNovas.length > 0) {
                LancarNotasFaltas(notasNovas)
            } else {
                AtualizarNotasFaltas(notasExistentes)
            }
        }

        setConfirmButtonDisable(true)
        setButtonDisable(true)


        if (window.confirm('Confirmar o lançamento?')) {

            if (tipoLancamento === 'S') {         //Sem avaliacao
                console.log('TIPO DE AVALIAÇÃO É SEM AVALIAÇÃO')
                window.alert('Tipo de avaliação é SEM AVALIAÇÃO. Não é possível fazer registro de avaliação para essa disciplina')

            } else {
                if (tipoLancamento === 'N') {   //Tipo Nota
                    if (valorTurma === '' || valorTurma === undefined || valorAvaliacao === '2222' || valorAvaliacao === '') {
                        window.alert('Por favor confirme se todos os dados foram selecionados')

                    } else {
                        onSubmitConfirm()
                        console.log('Tipo Nota')
                    }

                } else {                     //Tipo Conceito
                    if (valorTurma === '' || valorTurma === undefined || valorAvaliacao === '2222' || valorAvaliacao === '') {
                        window.alert('Por favor confirme se todos os dados foram selecionados')

                    } else {
                        onSubmitConfirmConceito()
                        console.log('Tipo Conceito')
                    }
                }
            }

        } else {                           //Clicou em cancelar lançamento
            setConfirmButtonDisable(false)
            setButtonDisable(false)
        }
    }

    const onDelete = async (dado) => {
        if (valorAvaliacao === 2222 || valorAvaliacao === '') {
            if (faltasAlunos[0]?.length === 0) {
                window.alert('Não existe notas dessa turma para excluir')
            } else {
                window.alert('Para excluir, selecione uma avaliação')
            }
        } else {
            if (valorTurma === '' || valorTurma === undefined) {
                alert('Por favor confirme se todos os dados estão preenchido')
            } else {
                if (alunosPermitidos.length === 0) {
                    window.alert('Não existe alunos para a turma selecionada')
                } else {
                    if (window.confirm('DESEJA REALMENTE EXCLUIR ESSA AULA? ') === true) {
                        console.log('ENTROU PARA DELETAR')
                        setLancado(true)
                        deletarDados(dado)
                        setExcluirButtonLoadingClass('loadingLançar')
                        setExcluirButtonText('      EXCLUINDO...')
                        setDeleteIsInvisible(true)
                    }
                }
            }
        }
    }

    function validarBaixadosEditarCampo(val, key) {
        const baixado = faltasAlunos[0]?.filter(item => item.matricula === val.Matricula)[0]?.baixado === 1
        const bloqueioNotasConfig = configEscola[0]?.bloqueio_notas === 1

        //VERIFICA NA TABELA CONFIG CAMPO BLOQUEIO NOTAS
        if (bloqueioNotasConfig === true) {
            //VERIFICA NOTA POR NOTA SE ESTA BAIXADO PARA BLOQUEAR EDIÇÃO
            if (baixado === false) {
                return (
                    <td className="Checkbox">
                        <input type="text" id="boxNotas" maxLength={2 + decimaisNota} name={val.Matricula} ref={register} required={true} />
                    </td>
                )
                //SE NÃO TIVER BAIXADA A NOTA LANÇADA PODE EDITAR
            } else {
                return (
                    <td className="Checkbox">
                        <input type="text" disabled id="boxNotas" ref={register} />
                    </td>
                )
            }
            //SENÃO TABELA CONFIG CAMPO BLOQUEIO NOTAS 
        } else {
            //PODE EDITAR SEM VALIDAÇÃO DE BLOQUEIO
            return (
                <td className="Checkbox">
                    <input type="text" id="boxNotas" maxLength={2 + decimaisNota} name={val.Matricula} ref={register} required={true} />
                </td>
            )
        }

    }

    function validarBaixadosFaltasEditarCampo(val, key) {
        const baixado = faltasAlunos[0]?.filter(item => item.matricula === val.Matricula)[0]?.baixado === 1
        const bloqueioNotasConfig = configEscola[0]?.bloqueio_notas === 1

        if (bloqueioNotasConfig === true) {
            if (baixado === false) {
                return (

                    <td className="Checkbox">
                        <input type="text" id="boxNotas" maxLength='4' name={val.Matricula + 'F'} ref={register} required={true} />
                    </td>
                )

            } else {
                return (
                    <td className="Checkbox">
                        <input type="text" id="boxNotas" disabled />
                    </td>
                )
            }

        } else {
            return (
                <td className="Checkbox">
                    <input type="text" id="boxNotas" maxLength='4' name={val.Matricula + 'F'} ref={register} required={true} />
                </td>
            )
        }
    }

    function filtrarAlunosGetFaltas() {
        alunoFiltroToGetFaltas = []
        setAlunosFiltroGetFaltas([])
        let filtro = {}

        for (let individuo of alunosPermitidos) {
            if (individuo !== undefined) {
                let count = 0
                filtro = {}

                for (let dado in individuo) {
                    if (count < 5 && dado !== undefined) {
                        filtro[dado] = individuo[dado]
                        count = count + 1
                    } else {
                        alunoFiltroToGetFaltas.push(filtro)
                        count = count + 1
                        break
                    }

                }
            }
        }
        setAlunosFiltroGetFaltas(alunoFiltroToGetFaltas)
    }

    // console.log('findNotasAux: ', findNotasAux)

    if (logado === 'true') {
        return (
            <div >
                <Header />
                <div className="container">
                    <section>
                        <div className="info-bar">
                            <div className="title">
                                <p className="title-page">Lançar Notas</p>
                                <p className="subtitle-page">Colégio {localStorage.getItem('escola').toUpperCase()}</p>
                            </div>
                            <div className="container-informativo">
                                <p className="informativo-title">Observações</p>
                                <p className="informativo-desc">Os campos Atividades de Aula e Atividades de Casa são opcionais, porém se preenchidos, serão disponibilizados para Pais e Alunos.</p>
                            </div>
                        </div>

                        <form action="" method="post">
                            <div className="container-form">

                                <label for="curso">Curso</label>
                                <select onChange={onChangeCurso} value={valorCurso} name="curso" id="curso" ref={register} required={true}>
                                    <option value={9999} key='CursoBranco'></option>
                                    {cursoFilter.map((val, key) => {
                                        return <option value={val.Codigo} key={key}>{val.Curso}</option>
                                    })}
                                </select>

                                <label for="disciplina">Disciplina</label>
                                <select onChange={onChangeDisciplina} onClick={onChangeDisciplina} value={valorDisciplina}
                                    name="disciplina" id="disciplina" ref={register} required={true}>
                                    <option value={6666} key='DisciplinaBranco'></option>
                                    {disciplinaFilter.map((val, key) => {
                                        return <option value={val.Codigo} key={key}>{val.Apelido}</option>
                                    })}
                                </select>

                                <label for="turma">Turma</label>
                                {
                                    gradeFiltro[0]?.TipoAvaliacao !== 'S'
                                        ?
                                        <select name="turma" onChange={onChangeTurma} value={valorTurma} id="turma" ref={register} required={true}>
                                            <option value={4444} key='TurmaBranco'> </option>
                                            {turmaFiltro.map((val, key) => {
                                                return <option value={val} key={key}>{val}</option>
                                            })
                                            }
                                        </select>
                                        :
                                        <select name="turma" id="turma" ref={register} disabled required={true}>
                                            <option value={4444} key='TurmaBranco'> </option>
                                        </select>
                                }

                                <label for="avaliacao">Avaliação</label>
                                <select name="avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} id="turma" ref={register} required={true}>
                                    <option value='' key='TurmaBranco'></option>
                                    {avaliacoes.map((val, key) => {
                                        return <option value={val.Codigo} key={key}>{val.Abreviatura}</option>
                                    })}
                                </select>
                                {findNotasAux.length > 0 ?
                                    <>
                                        <label for="notasAuxliares">Notas Auxiliares</label>
                                        <select name="notasAux" onChange={onChangeNotasAux} value={valorNotaAux} id="turma" ref={register} required={true}>
                                            <option value={''} key='NotaAuxBranco'></option>
                                            {notasAuxFilter.map((val, key) => {
                                                return <option value={val} key={`Leg0${key + 1}`}>{val}</option>
                                            })}
                                        </select>
                                    </>
                                    : null}

                                <label for="Observacao" id="observacao">
                                    {
                                        valorDisciplina?.length === 0 || valorDisciplina === '6666' ?
                                            <></>
                                            :
                                            // showObservacao()

                                            tipoAvFinal !== '' ?
                                                tipoAvFinal !== 'S' ?
                                                    tipoAvFinal === 'N' ?
                                                        'TIPO: NOTA. OBS.: Ao digitar notas, utilizar ponto em vez de vírgula. Ex: (5.0)'
                                                        : 'TIPO: CONCEITO'
                                                    :
                                                    'SEM AVALIAÇÃO'
                                                :
                                                'Nada'
                                    }

                                </label>

                                <details open={detailsIsOpen}>
                                    <summary onClick={onClickAlunos} >Alunos</summary>
                                    {
                                        loading ?
                                            <></> :
                                            <table>
                                                <tr>
                                                    <th>Matrícula</th>
                                                    <th>Nome</th>
                                                    {findNotasAux.length > 0 && valorNotaAux !== undefined ?
                                                        showNotasAuxField('title') :
                                                        <></>
                                                    }
                                                    {findNotasAux.length > 0 && valorNotaAux !== undefined ?
                                                        <></> :
                                                        <th>Nota</th>
                                                    }
                                                    <th title="Notas Registradas">Registro</th>
                                                    <th>Faltas</th>
                                                    <th title="Faltas Registradas">Registro</th>
                                                </tr>
                                                {
                                                    (notasAuxFilter.length > 0 && valorNotaAux === undefined) ?
                                                        <></>
                                                        :
                                                        alunosPermitidos?.map((val, key) => {
                                                            return (
                                                                <tr>
                                                                    <td key={key} ref={register} id="matriculaFieldGridNotas">{val.Matricula}</td>
                                                                    <td key={val.Nome} id="nomeAluno">{val.Nome}</td>

                                                                    {/* Campo nota */}
                                                                    {validarBaixadosEditarCampo(val, key)}

                                                                    <td className="RegistroFaltas">
                                                                        {
                                                                            tipoAvFinal === 'N' ?
                                                                                mostrarNota(val, key)
                                                                                :
                                                                                mostrarConceito(val, key)
                                                                        }
                                                                    </td>

                                                                    {/* Campo falta */}
                                                                    {validarBaixadosFaltasEditarCampo(val, key)}

                                                                    <td className="RegistroFaltas">
                                                                        {
                                                                            faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)[0] !== undefined ?
                                                                                faltasAlunos[0]?.filter(item => item?.matricula === val?.Matricula)[0]?.falta :
                                                                                'S/R'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        )

                                                }

                                            </table>
                                    }
                                </details>
                                {
                                    gradeFiltro[0]?.TipoAvaliacao !== 'S' ?
                                        <div>
                                            {
                                                confirmButtonDisable === false ?
                                                    <input type="submit"
                                                        name="button"
                                                        className={`button ${lancarButtonLoadingClass}`}
                                                        id="confirmar"
                                                        value={`${lancarButtonText}`}
                                                        onClick={handleSubmit(onSubmit)}
                                                        ref={register}
                                                        disabled={buttonDisable}
                                                    />
                                                    :
                                                    <input type="submit"
                                                        name="button"
                                                        className={`button ${lancarButtonLoadingClass}`}
                                                        id="confirmar"
                                                        value={`${lancarButtonText}`}
                                                        ref={register}
                                                        disabled={true}
                                                    />
                                            }

                                            {   //Permissão para excluir Dados
                                                permissaoExcluirDados === '0' ? <></> :
                                                    <input type="submit"
                                                        name="button"
                                                        className={`button ${excluirButtonLoadingClass}`}
                                                        id="deletar"
                                                        value={`${excluirButtonText}`}
                                                        onClick={handleSubmit(onDelete)}
                                                        ref={register}
                                                        disabled={deleteIsInvisible}
                                                    />
                                            }

                                        </div> :
                                        <></>
                                }
                            </div>
                        </form>
                    </section>
                    {cursoFilter.length === 0 || disciplinaFilter.length === 0 ? <ScaleLoader color={color} loading={loading} css={override} height={50} width={8} /> :
                        null}
                    <ScaleLoader color={color} loading={loading} css={override} height={50} width={8} />
                </div>
            </div>
        );

    } else {
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace(window.location.origin)
    }
}