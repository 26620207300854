import React, { useState, useEffect } from 'react'
import Edit from '@material-ui/icons/Edit'
import TabelaGeral from '../TabelaGeral/TabelaGeral'
import GetProfPermissao from '../../services/GetProfPermissao'
import UpdateSenhaProf from '../../services/PostUpdateSenhaProf'
import ButtonConfirm from '../ButtonConfirm/ButtonConfirm'
import SelectAPI from '../../Functions/SelectApi'
import ButtonBack from '../ButtonBack/ButtonBack'
import './ProfessorDados.css'
import { Link } from 'react-router-dom'

export default function ProfessorDados({ title }) {
    const [senhaValue, setSenhaValue] = useState('')
    const [digitacao, setDigitacao] = useState()
    const [dadosProfessor, setDadosProfessor] = useState(JSON.parse(sessionStorage.getItem('p')))
    const [btnEditIsDisabled, setBtnEditIsDisabled] = useState('')
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const isAdmin = localStorage.getItem('isAdmin') !== null ? localStorage.getItem('isAdmin') : ''
    const baseUrl = SelectAPI(valorEscola)

    const columnsTitle = [
        "CURSO",
        "DISCIPLINA",
        "TURMA",
    ]

    const bodyFields = [
        "Curso",
        "Disciplina",
        "Turma"
    ]

    async function getPermissao() {
        let permissao = await GetProfPermissao(baseUrl, dadosProfessor.Codigo)
        setDigitacao(permissao)
    }

    useEffect(() => {
        if (dadosProfessor?.Codigo) {
            console.log('Entrou SE')
            getPermissao()
            isAdmin === '1' ? setBtnEditIsDisabled('disabledBtnEdit') : setBtnEditIsDisabled('')
        }
    }, [])

    const onChangeEditSenha = (event) => {
        let inputValue = event.target.value
        setSenhaValue(inputValue)
    }

    async function onSave() {
        let resultadoSenha = await UpdateSenhaProf(baseUrl, senhaValue, dadosProfessor.Codigo)
        console.log('RESULTADO SENHA: ', resultadoSenha)
        window.alert('Senha atualizada')
        window.location.reload()
    }

    console.log('Digitacao: ', digitacao)
    console.log('BodyFields: ', bodyFields)

    return (
        <div className='ProfessorDadosContainer'>
            <div className='ProfessorDadosDashBarraPesquisa'>
                {title}
            </div>

            <div className="ProfessorDadosInfoContainerGeral">
                <div className="ProfessorDadosBasicInfoContainer">
                    <div className='ProfessorDadosIDContainer TextDescricao'>
                        ID
                        <input disabled={true} value={dadosProfessor?.Codigo} id="InputId" />
                    </div>
                    <div className='ProfessorDadosNomeContainer TextDescricao'>
                        NOME
                        <input disabled={true} value={dadosProfessor?.Funcionario} id="InputNome" />
                    </div>
                    <div className='ProfessorDadosStatusContainer TextDescricao' >
                        STATUS
                        <input disabled={true} value="ATIVO" id='InputStatus' />
                    </div>
                </div>

                <div>
                    <div className='ProfessorDadosSenhaContainer TextDescricao'>
                        SENHA
                        <div className={`SenhaInfoContainer ${btnEditIsDisabled}`} >

                            {
                                isAdmin === '1' ?
                                    <>
                                        <input onChange={onChangeEditSenha} type="password" value={senhaValue} className={`InputSenhaEditarSenha `} placeholder='******' />
                                        <Edit id="EditIconSenha" />
                                    </>
                                    :
                                    <>
                                        <input onChange={onChangeEditSenha} type="password" disabled value={senhaValue} className={`InputSenhaEditarSenha `} placeholder='******' />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div id="TextPermissoesProfessorDados">
                PERMISSÕES
            </div>

            <div className='ProfessorDadosBodyContainer'>
                <TabelaGeral bodyDataRows={digitacao} headerTableTitles={columnsTitle} bodyTableFields={bodyFields} />

                <div className='ProfessorDadosButtonsContainer'>
                    {
                        isAdmin === '1' ?
                            <ButtonConfirm Action={onSave} Title="SALVAR" /> :
                            <>&nbsp; &nbsp; &nbsp; &nbsp;</>
                    }
                    <Link to='/admin/Professores' id="linkButtonBack">
                        <ButtonBack Title="VOLTAR" />
                    </Link>
                </div>
            </div>

        </div>
    )
}