import HomeIcon from '@material-ui/icons/Home'
import PeopleAlt from '@material-ui/icons/PeopleAlt'
import AccountBox from '@material-ui/icons/AccountBox'
import CalendarToday from '@material-ui/icons/CalendarToday'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import Description from '@material-ui/icons/Description'
import SettingsApplications from '@material-ui/icons/Settings'
import CloudDownload from '@material-ui/icons/CloudDownload'
import Apps from '@material-ui/icons/Apps'
import React from 'react'

export const SideBarData = [
    {
        Codigo: "100",
        title: "Home",
        icon: <HomeIcon />,
        link: "/Admin/Home",
        active: "Active"
    },
    {
        Codigo: "200",
        title: "Professores",
        icon: <PeopleAlt />,
        link: "/Admin/Professores",
        active: "Active"
    },
    {
        Codigo: "300",
        title: "Usuários",
        icon: <AccountBox />,
        link: "/Admin/Usuarios",
        active: "Active"
    },
    {
        Codigo: "400",
        title: "Diário",
        icon: <CalendarToday />,
        link: "/Admin/Diario",
        active: "Active"
    },
    {
        Codigo: "500",
        title: "Notas",
        icon: <FormatListBulleted />,
        link: "/Admin/Notas",
        active: "Active"
    },
    {
        Codigo: "700",
        title: "Conceitos",
        icon: <FormatListBulleted />,
        link: "/Admin/Conceitos",
        active: "Active"
    },
    {
        Codigo: "1100",
        title: "Qualitativas",
        icon: <FormatListBulleted />,
        link: "/Admin/Qualitativas",
        active: "Active"
    },
    {
        Codigo: "800",
        title: "Gerenciar",
        icon: <SettingsApplications />,
        link: "/Admin/Gerenciar",
        active: "Inative"
    },
    {
        Codigo: "900",
        title: "Relatórios",
        icon: <Description />,
        link: "/Admin/Relatorios",
        active: "Active"
    },
    {
        Codigo: "800",
        title: "Exportar Notas",
        icon: <CloudDownload />,
        link: "/Admin/Exportar",
        active: "Inactive"
    },
    {
        Codigo: "1000",
        title: "Extras",
        icon: <Apps />,
        link: "/Admin/Extras",
        active: "Active"
    }
]