import './TransferirFaltas.css'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Backdrop, CircularProgress } from '@mui/material';
import Modal from 'react-modal';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import FormFaltasBasic from '../../../components/FormFaltasBasic/FormFaltasBasic';

import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetGrades from '../../../services/GetGrades'
import GetTurmas from '../../../services/GetTurmas'
import PostTransferirFaltas from '../../../services/PostTransferirFaltas';

import FilterCursosByGrades from '../../../Functions/FilterCursosByGrades'
const { ApiFilter } = require('../../../Functions/FilterApiDomain')

Modal.setAppElement('#root')


export default function TransferirFaltas() {
    const [checkTodasNotas, setCheckTodasNotas] = useState(false)

    const [cursos, setCursos] = useState([])
    const [turmas, setTurmas] = useState([])
    const [grades, setGrades] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [msgResult, setMsgResult] = useState([])

    const [sub, setSub] = useState(localStorage.getItem('target'))


    const { register, handleSubmit, errors } = useForm()
    const baseUrl = ApiFilter(sub)

    const closeModal = () => {
        setModalIsOpen(!modalIsOpen)
        window.location.reload()
    }

    //APIs
    const getGrades = async () => {
        const gradesResult = await GetGrades(baseUrl)
        setGrades(gradesResult)
        return gradesResult
    }

    const getTurmas = async () => {
        const turmaResult = await GetTurmas(baseUrl)
        setTurmas(turmaResult)
        return turmaResult
    }

    const getAvaliacoes = async () => {
        const avaliacoesResult = await GetAvaliacoes(baseUrl)
        setAvaliacoes(avaliacoesResult)
        return avaliacoesResult
    }

    useEffect(async () => {
        const promiseAll = await Promise.all(
            [
                getGrades(baseUrl),
                getTurmas(baseUrl),
                getAvaliacoes(baseUrl)
            ])

        const gradesReturn = await promiseAll[0]
        const filterCursos = FilterCursosByGrades(gradesReturn)
        setCursos(filterCursos)
    }, [])

    //Handle Data
    const onSubmit = async (dados) => {
        setOpen(true)
        console.log('Dados: ', dados)

        const avaliacao = JSON.parse(dados.avaliacao).Codigo
        const curso = dados.curso === undefined ? '' : dados.curso
        const turma = dados.turma === undefined ? '' : dados.turma
        const dataInicial = dados.dataInicial
        const dataFinal = dados.dataFinal
        const disciplina = dados.disciplina === undefined ? '' : dados.disciplina
        const isPolivalente = dados.isPolivalente

        const chave = curso + turma
        const resultado = await PostTransferirFaltas(baseUrl, chave, dataInicial, dataFinal, avaliacao, disciplina, isPolivalente)
        
        if(resultado.status === 200){
            window.alert('Transferencia realizada com sucesso')
            window.location.reload()
        } else {
            window.alert('Não foi possivel concluir operação')
        }

        setModalIsOpen(!modalIsOpen)
        setOpen(false)
    }

    return (
        <>
            <div id='TransferirFaltasGeneralContainer'>
                <SideBar />
                <div id='TransferirFaltasContainer'>
                    <Header />

                    <div id="TransferirFaltasTitlePage">
                        Transferir Faltas
                    </div>

                    <div className="TransferirFaltasPainelContainer">
                        {/* <h2>TransferirFaltas NOTAS</h2> */}

                        <div id="TransferirFaltasOptionsContainer">

                            <FormFaltasBasic
                                grades={grades}
                                cursos={cursos}
                                turmas={turmas}
                                avaliacoes={avaliacoes}
                                disabled={checkTodasNotas}
                                cursoTodos={false}
                                register={register}
                            />

                            <Backdrop
                                sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                                <p>Processando...</p>
                            </Backdrop>
                            {
                                msgResult.Msg !== undefined ?
                                    <Modal
                                        closeTimeoutMS={200}
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        contentLabel='TransferirFaltas Notas Decimais'
                                        overlayClassName='modal-overlay'
                                        className='modal-content-notas-aux'
                                    >
                                        <h2>
                                            <div id="TitleModalDiarioDados">
                                                Processamento finalizado
                                            </div>
                                        </h2>

                                        <hr />
                                        <p>
                                            <div style={
                                                {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: msgResult.Status === 200 ? 'green' : 'orangered',
                                                    marginBottom: '-5px',
                                                }
                                            }>
                                                {
                                                    msgResult.Status === 200 ?
                                                        <CheckCircleIcon style={{ color: 'green', marginRight: '5px' }} />
                                                        :
                                                        <ErrorIcon style={{ color: 'orange', marginRight: '5px' }} />
                                                }
                                                {msgResult.Msg}
                                            </div>
                                        </p>

                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                                            <button id='ButtonModalStudent' onClick={closeModal}>
                                                FECHAR
                                            </button>
                                        </div>
                                    </Modal>
                                    : <></>
                            }

                            <div id="TransferirFaltasButtonContainer">
                                <input type='submit' id="ProcessarTransferirFaltasNotasBtn" value={"PROCESSAR"} onClick={handleSubmit(onSubmit)} />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}