import './SistemaAvaliacaoCard.css'
import React, { useState, useEffect } from 'react'
import GetAvaliacoes from '../../services/GetAvaliacoes'
// const { ApiFilter } = require('../../../Functions/FilterApiDomain')
const { ApiFilter } = require('../../Functions/FilterApiDomain')


export default function SistemaAvaliacaoCard({ dadosFinais, ref, cursosUnicos }) {
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [dataInicial, setDataInicial] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [avaliacoes, setAvaliacoes] = useState([])
    const grades = JSON.parse(sessionStorage.getItem('grades'))

    const baseUrl = ApiFilter(sub)

    async function getAvaliacoes() {
        const avaliacoesResult = await GetAvaliacoes(baseUrl)
        setAvaliacoes(avaliacoesResult)
    }

    const onChangeDataInicial = (event) => {
        const valor = event.target.value
        setDataInicial(valor)
    }

    const onChangeDataFinal = (event) => {
        const valor = event.target.value
        setDataFinal(valor)
    }

    useEffect(() => {
        getAvaliacoes()
    }, [])

    return (
        <div className='SistemaAvaliacaoCardContainer'>

            {/* SISTEMA 01 */}
            {
                grades.map((sistem, index) => {
                    return (
                        <div className='SistemaAvaliacaoCardContainerCard'>
                            <div ref={ref} name="Sistema" className='SistemaAvaliacaoCardContainerCardTipoAv'>
                                SISTEMA {sistem.Avaliacoes.Sistema} - {sistem.Avaliacoes.Avaliacao}
                            </div>
                            <div className='SistemaAvaliacaoCardDados'>
                                CURSOS: 004;005;006;007;008;009;010;011;012;013
                            </div>

                            {
                                sistem.Avaliacoes.map((av, idxAv) => {
                                    return (
                                        <div className='SistemaAvaliacaoCardBody'>
                                            <div className='SistemaAvaliacaoCardBodyAv'>
                                                <div className="SistemaAvaliacaoCardBodyAvTitleAvaliacao">
                                                    {av.Abreviatura}
                                                </div>
                                                <div className="SistemaAvaliacaoCardBodyAvDateContainer">
                                                    Inicial
                                                    <input type="date" id="data" onChange={onChangeDataInicial} name="dataInicioAv1" ref={ref} required={true} />
                                                    Final
                                                    <input type="date" id="data" onChange={onChangeDataFinal} name="dataFinalAv1" ref={ref} required={true} />
                                                </div>
                                            </div>

                                            <div className='SistemaAvaliacaoCardBodyAv'>
                                                <div className="SistemaAvaliacaoCardBodyAvTitleAvaliacao">
                                                    {av.Abreviatura}
                                                </div>
                                                <div className="SistemaAvaliacaoCardBodyAvDateContainer">
                                                    Inicial
                                                    <input type="date" id="data" onChange={onChangeDataInicial} name="dataInicioAv2" ref={ref} required={true} />
                                                    Final
                                                    <input type="date" id="data" onChange={onChangeDataFinal} name="dataFinalAv2" ref={ref} required={true} />
                                                </div>
                                            </div>

                                            <div className='SistemaAvaliacaoCardBodyAv'>
                                                <div className="SistemaAvaliacaoCardBodyAvTitleAvaliacao">
                                                    {av.Abreviatura}
                                                </div>
                                                <div className="SistemaAvaliacaoCardBodyAvDateContainer">
                                                    Inicial
                                                    <input type="date" id="data" onChange={onChangeDataInicial} name="dataInicioAv3" ref={ref} required={true} />
                                                    Final
                                                    <input type="date" id="data" onChange={onChangeDataFinal} name="dataFinalAv3" ref={ref} required={true} />
                                                </div>
                                            </div>

                                            <div className='SistemaAvaliacaoCardBodyAv'>
                                                <div className="SistemaAvaliacaoCardBodyAvTitleAvaliacao">
                                                    {av.Abreviatura}
                                                </div>
                                                <div className="SistemaAvaliacaoCardBodyAvDateContainer">
                                                    Inicial
                                                    <input type="date" id="data" onChange={onChangeDataInicial} name="dataInicioAv4" ref={ref} required={true} />
                                                    Final
                                                    <input type="date" id="data" onChange={onChangeDataFinal} name="dataFinalAv4" ref={ref} required={true} />
                                                </div>
                                            </div>

                                            <div className='SistemaAvaliacaoCardBodyAv'>
                                                <div className="SistemaAvaliacaoCardBodyAvTitleAvaliacao">
                                                    {av.Abreviatura}
                                                </div>
                                                <div className="SistemaAvaliacaoCardBodyAvDateContainer">
                                                    Inicial
                                                    <input type="date" id="data" onChange={onChangeDataInicial} name="dataInicioAv5" ref={ref} required={true} />
                                                    Final
                                                    <input type="date" id="data" onChange={onChangeDataFinal} name="dataFinalAv5" ref={ref} required={true} />
                                                </div>
                                            </div>

                                            <div className='SistemaAvaliacaoCardBodyAv'>
                                                <div className="SistemaAvaliacaoCardBodyAvTitleAvaliacao">
                                                    {av.Abreviatura}
                                                </div>
                                                <div className="SistemaAvaliacaoCardBodyAvDateContainer">
                                                    Inicial
                                                    <input type="date" id="data" onChange={onChangeDataInicial} name="dataInicioAv6" ref={ref} required={true} />
                                                    Final
                                                    <input type="date" id="data" onChange={onChangeDataFinal} name="dataFinalAv6" ref={ref} required={true} />
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}