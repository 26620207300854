import React, { useState, useEffect } from 'react'
import Edit from '@material-ui/icons/Edit'
import GetCursos from '../../services/GetCursos'
import GetUsersById from '../../services/GetUserById'
import DeleteUserById from '../../services/DeleteUserById'
import UpdateDadosUsuario from '../../services/UpdateDadosUsuario'
import UpdateCursosUsuario from '../../services/UpdateCursosUsuario'
import ButtonConfirm from '../ButtonConfirm/ButtonConfirm'
import ButtonDelete from '../ButtonDelete/ButtonDelete'
import ButtonDisabled from '../ButtonDisabled/ButtonDisabled'
import SelectAPI from '../../Functions/SelectApi'
import ButtonBack from '../ButtonBack/ButtonBack'
import TabelaPermissaoUsuario from '../TabelaPermissaoUsuario/TabelaPermissaoUsuario'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './UsuariosDados.css'

export default function UsuariosDados({ title, tableShow = false }) {
    const [senhaValue, setSenhaValue] = useState('')
    const [digitacao, setDigitacao] = useState()
    const [user, setUser] = useState([])
    const [cursos, setCursos] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [dadosUsuario, setDadosUsuario] = useState(JSON.parse(sessionStorage.getItem('p')))
    const [btnEditIsDisabled, setBtnEditIsDisabled] = useState('')
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const isAdmin = localStorage.getItem('isAdmin') !== null ? localStorage.getItem('isAdmin') : ''
    const [showTable, setShowTable] = useState(tableShow)
    const baseUrl = SelectAPI(valorEscola)
    const { register, handleSubmit, errors } = useForm()

    //NOTIFICAÇÕES
    function notifyErro(text) {
        toast.info(text, { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }

    //NOTIFICAÇÕES
    function notifyDeleteUser(text) {
        toast.info(text, { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }

    //NOTIFICAÇÕES
    function notifySucess(text) {
        toast.success(text, { autoClose: 4000, position: toast.POSITION.TOP_CENTER });
    }

    const columnsTitle = [
        "CURSOS"
    ]

    const bodyFields = [
        "Curso"
    ]

    async function getCursos() {
        const cursos = await GetCursos(baseUrl, dadosUsuario.Id)
        setCursos(value => value = cursos)
    }

    async function getUsers() {
        const usersResult = await GetUsersById(baseUrl, dadosUsuario.Id)
        const usersCursos = usersResult[0].Cursos.split(';')
        const usersCursosFiltro = usersCursos.filter(item => item !== '')
        setDigitacao(value => value = usersCursosFiltro)
        setUser(value => value = usersResult)

        return usersCursosFiltro
    }

    useEffect(() => {
        if (dadosUsuario?.Id !== undefined) {
            Promise.allSettled([
                getCursos(),
                getUsers()
            ]).then((result) => {
                setShowTable(true)
                setDigitacao((value) => value = result[1].value)
            }).catch((err) => {
                console.log('Erro: ', err)
            })
            isAdmin === '1' ? setBtnEditIsDisabled('disabledBtnEdit') : setBtnEditIsDisabled('')
        }
    }, [])

    const onChangeEditSenha = (event) => {
        let inputValue = event.target.value
        setSenhaValue(value => value = inputValue)
    }

    async function onSave(dado) {
        let codigoCursos = ''

        if (window.confirm('Confirma salvar dados?')) {
            //Converter dados para codigo de permissao de curso Ex: "001;002;003"
            setIsLoading(value => value = true)
            for (const value in dado) {
                if (dado[value]) {
                    codigoCursos += value + ';'
                }
            }

            if (senhaValue !== '') {
                let resultadoDados = await UpdateDadosUsuario(baseUrl, senhaValue, dadosUsuario.Id, codigoCursos)
                if (resultadoDados.Status === 200) {
                    notifySucess('Dados atualizados com sucesso')

                } else {
                    notifyErro('Não foi possível concluir a atualização. Favor tentar novamente mais tarde')
                }

                setTimeout(() => {
                    window.location.replace('/Admin/Usuarios')
                }, 4000)

            } else {
                let resultadoUpdateCursos = await UpdateCursosUsuario(baseUrl, dadosUsuario.Id, codigoCursos)
                if (resultadoUpdateCursos.Status === 200) {
                    notifySucess('Dados atualizados com sucesso')
                } else {
                    notifyErro('Não foi possível concluir a atualização. Favor tentar novamente mais tarde')
                }

                setTimeout(() => {
                    window.location.replace('/Admin/Usuarios')
                }, 4000)
            }

        }
    }

    async function onDelete(dado) {

        if (window.confirm('Deseja realmente excluir usuário?')) {
            //Converter dados para codigo de permissao de curso Ex: "001;002;003"
            const deleteResult = await DeleteUserById(baseUrl, dadosUsuario.Id)

            if (deleteResult.Deletados > 0){
                notifyDeleteUser('Usuário excluído com sucesso')
            } else {
                notifyErro('Não foi possível excluir o usuário, favor tentar novamente mais tarde')
            }

            setIsLoading(value => value = true)

            setTimeout(() => {
                window.location.replace('/Admin/Usuarios')
            }, 4000)
        }
    }

    const btnShow = () => {
        if (isLoading === false) {
            return <ButtonConfirm Action={handleSubmit(onSave)} Title="SALVAR" className="linkBtn" />
        } else {
            const isLoading = false
            return <ButtonDisabled Title="AGUARDE" isLoading={isLoading} cursorLoadind={true} className="linkBtn" />
        }
    }

    const btnDeleteShow = () => {
        if (isLoading === false) {
            return <ButtonDelete Action={handleSubmit(onDelete)} Title="EXCLUIR" className="linkBtn" />
        } else {
            const isLoading = false
            return <ButtonDisabled Title="AGUARDE" isLoading={isLoading} cursorLoadind={true} className="linkBtn" />
        }
    }

    return (
        <div className='UsuarioDadosContainer'>
            <div className='UsuariosDadosDashContainer'>
                <div className='UsuarioDadosDashBarraPesquisa'>
                    {title}
                </div>

                <div className="UsuarioDadosBasicInfoContainer">
                    <div className='UsuarioDadosIDContainer TextDescricao'>
                        ID
                        <input disabled={true} value={dadosUsuario?.Id} id="InputId" />
                    </div>
                    <div className='UsuarioDadosNomeContainer TextDescricao'>
                        NOME
                        <div id='UsuarioDadosNomeContainerEdit'>
                            <input value={dadosUsuario?.Nome?.toUpperCase()} id="InputNome" />
                            <Edit />
                        </div>
                    </div>
                    <div className='UsuarioDadosStatusContainer TextDescricao' >
                        STATUS
                        <input disabled={true} value="ATIVO" id='InputStatus' />
                    </div>
                </div>

                <div>
                    <div className='UsuarioDadosSenhaContainer TextDescricao'>
                        SENHA
                        <div className={`SenhaInfoContainer ${btnEditIsDisabled}`} >

                            {
                                isAdmin === '1' ?
                                    <>
                                        <input onChange={onChangeEditSenha} type="password" value={senhaValue} class={`InputSenhaEditarSenha `} placeholder='' />
                                        <Edit id="EditIconSenha" />
                                    </>
                                    :
                                    <>
                                        <input onChange={onChangeEditSenha} type="password" disabled value={senhaValue} class={`InputSenhaEditarSenha `} placeholder='******' />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='PermissaoDivDescription'>
                PERMISSÕES
            </div>

            {
                showTable === true ?
                    <TabelaPermissaoUsuario
                        bodyDataRows={cursos}
                        headerTableTitles={columnsTitle}
                        bodyTableFields={bodyFields}
                        bodyDataRowsChecked={digitacao}
                        registerForm={register}
                    /> :
                    <></>
            }

            <div className='UsuarioDadosButtonsContainer'>
                {
                    isAdmin === '1' ?
                        btnShow() :
                        <>&nbsp; &nbsp; &nbsp; &nbsp;</>
                }
                <Link to='/admin/Usuarios' className="linkBtn">
                    <ButtonBack Title="VOLTAR" />
                </Link>
                {
                    (isAdmin === '1' && dadosUsuario.Nome !== 'Administrador') ?
                    btnDeleteShow() :
                        <>&nbsp; &nbsp; &nbsp; &nbsp;</>
                }
            </div>
        </div>
    )
}