import React, {useState} from 'react'
import './Header.css'
import AccountCircleSharp from '@material-ui/icons/AccountCircleSharp'
import WhatsappIcon from '../../images/logo-whatsapp.png'


export default function Header() {

    const path = window.location.pathname
    const rota = path.split('/')[2] === undefined ? path.split('/')[1] : path.split('/')[2]
    const [userName, setUserName] = useState(localStorage.getItem('user'))

    return (
        <div id="HeaderDashContainer">
            <div>Dashboard / {rota}</div>

            <div className='HeaderDashContato'>
                <a href='https://wa.me/5579998312572?text=Ol%C3%A1+boa+tarde%21' target="blank" rel='whatsapp link'>
                    <div id='HeaderDashContatoText'>Precisa de Ajuda?</div>
                </a>
                <img src={WhatsappIcon} width="18" id="HeaderDashContatoIcon" alt='Whatsapp Link'/>
            </div>

            <div className='HeaderDashUser'>
                <AccountCircleSharp id="HeaderDashUserIcon" />
                <div>{userName}</div>
            </div>
        </div>
    )
}