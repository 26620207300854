import React, { useState, useEffect } from 'react'
import ButtonBack from '../ButtonBack/ButtonBack'
import NotasTabelaGeral from '../NotasTabelaGeral/NotasTabelaGeral'
import GetNotas from '../../services/GetNotas'
import GetConceitos from '../../services/GetConceitos'
import SelectAPI from '../../Functions/SelectApi'
import GetNotasAuxLegenda from '../../services/GetNotasAuxLegenda'
import Modal from 'react-modal'
import './ConceitosDados.css'
import { Link } from 'react-router-dom'

export default function ConceitosDados({ title }) {
    const [dadosDiario, setDadosDiario] = useState(JSON.parse(sessionStorage.getItem('d')))
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [notasAuxLeg, setNotasAuxLeg] = useState([])
    const [notas, setNotas] = useState([])
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const baseUrl = SelectAPI(valorEscola)

    const onBack = () => {
        sessionStorage.removeItem('d')
        sessionStorage.removeItem('p')
    }

    const notasTableTitles = [
        "MATRICULA",
        "NOME",
        "NOTA",
        "FALTAS"
    ]

    const notasTableFields = [
        "matricula",
        "Nome",
        "conceito",
        "falta"
    ]

    async function getConceitos() {
        const resultadoNotas = await GetConceitos(baseUrl, dadosDiario.Codigo)
        setNotas(resultadoNotas)
    }

    const closeModal = () => {
        setModalIsOpen(!modalIsOpen)
    }

    async function GetAuxLegenda() {
        const resultAux = await GetNotasAuxLegenda(baseUrl)
        console.log('LegendaGet', resultAux)
        const resultAuxFilter = resultAux.filter(item => item.Chave === dadosDiario?.Codigo)
        const exists = filterExistAuxLeg(resultAuxFilter)
        setNotasAuxLeg(exists)

        console.log('ExistLeg', exists)
    }

    function filterExistAuxLeg(notasAuxLeg) {
        const notasAuxFilterExist = []
        for (const valueItem in notasAuxLeg[0]) {

            if (valueItem.includes('Leg')) {
                const addItem = { [valueItem]: notasAuxLeg[0][valueItem] }
                if (notasAuxLeg[0][valueItem] !== "") {
                    notasAuxFilterExist.push(addItem)
                }
            }
        }

        return notasAuxFilterExist
    }

    const showTableAuxLeg = () => {
        const renderAlunos = (aluno, idx) => {
            const alunoRenderItem = []

            for (const alunoItem in aluno) {
                if (alunoItem !== 'Nome' && alunoItem !== 'matricula' && alunoItem !== 'nota' && alunoItem !== 'falta') {
                    const numberLeg = alunoItem.split('_')[1]

                    const resultFindLeg = notasAuxLeg.filter(item => {
                        const itemAux = item
                        const leg = `Leg${numberLeg}`
                        return itemAux[leg]
                    })
                    if (resultFindLeg[0] !== undefined) {
                        console.log('ResultFindLeg', resultFindLeg[0])
                        alunoRenderItem.push(<td>{aluno[alunoItem]}</td>)
                    }
                }
            }
            return (
                alunoRenderItem.map((item, idx) => {
                    return item
                })
            )
        }

        return (
            //RENDER FINAL
            <div id="notasDadosTableContainer">
                <table>
                    <thead>
                        <tr>
                            <td>ALUNOS</td>
                            {
                                notasAuxLeg.map((notas, index) => {
                                    const numberLeg = index !== 9 ? `Leg0${index + 1}` : 'Leg10'
                                    return (
                                        <td >{notas[numberLeg]}</td>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody >
                        {
                            notas.map((aluno, index) => {
                                return (
                                    <tr>
                                        <td>{aluno.Nome}</td>
                                        {renderAlunos(aluno)}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    useEffect(() => {
        if (dadosDiario.Codigo) {
            getConceitos()
            GetAuxLegenda()
        }
    }, [dadosDiario])


    return (
        <div className='NotasDadosContainer'>
            <div className='NotassDadosDashContainer'>
                <div className='NotasDadosDashBarraPesquisa'>
                    {title}
                </div>

                <div className="NotasDadosBasicInfoContainer">
                    <div className='NotasDadosIDContainer TextDescricao'>
                        CÓDIGO
                        <input disabled={true} value={dadosDiario?.Codigo} id="InputCodigo" />
                    </div>
                    <div className='NotasDadosCursoContainer TextDescricao'>
                        CURSO
                        <div id='NotasDadosCursoContainerEdit'>
                            <input disabled={true} value={dadosDiario?.Curso?.toUpperCase()} id="InputCurso" />
                        </div>
                    </div>
                    <div className='NotasDadosTurmaContainer TextDescricao' >
                        TURMA
                        <input value={dadosDiario?.Turma} disabled={true} id='InputTurma' />
                    </div>
                </div>

                <div className='NotasDadosBasicInfoContainerRow2'>
                    <div className='NotasDadosDisciplinaContainer TextDescricao'>
                        DISCIPLINA
                        <input disabled value={dadosDiario?.Disciplina} id="InputDisciplina" />
                    </div>
                    <div className='NotasDadosAvaliacaoContainer TextDescricao'>
                        AVALIAÇÃO
                        <input disabled value={dadosDiario?.Avaliacao} id="InputAvaliacao" />
                    </div>
                </div>
            </div>

            <div className='PermissaoDivDescription'>
                PERMISSÕES
            </div>

            <NotasTabelaGeral
                bodyDataRows={notas}
                headerTableTitles={notasTableTitles}
                bodyTableFields={notasTableFields}
            />



            <Modal
                closeTimeoutMS={200}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Notas Auxiliares Painel Admin'
                overlayClassName='modal-overlay'
                className='modal-content-notas-aux'
            >
                <h2>
                    <div id="TitleModalDiarioDados">
                        NOTAS AUXILIARES
                    </div>
                </h2>

                <hr />

                <p>
                    Grade completa
                </p>

                {showTableAuxLeg()}

                <button id='ButtonModalStudent' onClick={closeModal}>
                    FECHAR
                </button>
            </Modal>

            <div className='NotasDadosButtonsContainer'>
                <Link to='/admin/Conceitos' className="linkBtn">
                    <ButtonBack Title="VOLTAR" />
                </Link>

                <button id="buttonAuxNotasAdmin" onClick={() => { setModalIsOpen(true) }}>AUXILIARES</button>
            </div>
        </div>
    )
}