import './FecharFaltas.css'

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom';

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Checkbox from '@mui/material/Checkbox';
import ButtonBack from './../../../components/ButtonBack/ButtonBack';
import ButtonModalFecharFaltas from '../../../components/ButtonModalFecharFaltas/ButtonModalFecharFaltas';

import GetTurmas from '../../../services/GetTurmas';
import GetGrades from '../../../services/GetGrades';
import GetAvaliacoes from '../../../services/GetAvaliacoes';
import GetCursos from '../../../services/GetCursos';

const { ApiFilter } = require('../../../Functions/FilterApiDomain')

const sxMainTreeView = { display: 'flex', flexDirection: 'column', flex: 1, maxHeight: "20em", overflowY: 'scroll', flexGrow: 1, width: "100%" }
const sxTitleStyleTurmas = { display: "flex", marginLeft: '0px', width: 'auto', flexDirection: "column", alignItems: "flex-start" }
const sxTitleStyleDisciplinas = { display: "flex", overflow: 'hidden', marginLeft: '-10px', width: 'auto', flexDirection: "column", alignItems: "center", justifyContent: "center" }


const cursos = [
    { Codigo: '004', label: '1° FUNDAMENTAL' },
    { Codigo: '005', label: '2° FUNDAMENTAL' },
    { Codigo: '006', label: '3° FUNDAMENTAL' },
    { Codigo: '007', label: '4° FUNDAMENTAL' },
    { Codigo: '008', label: '5° FUNDAMENTAL' },
    { Codigo: '009', label: '6° FUNDAMENTAL' },
    { Codigo: '010', label: '7° FUNDAMENTAL' },
    { Codigo: '011', label: '8° FUNDAMENTAL' },
    { Codigo: '012', label: '9° FUNDAMENTAL' },
]

const disciplina = [
    { Codigo: '004', label: 'PORTUGUÊS' },
    { Codigo: '005', label: 'MATEMÁTICA' },
    { Codigo: '006', label: 'CIÊNCIAS' },
    { Codigo: '007', label: 'HISTÓRIA' },
    { Codigo: '008', label: 'GEOGRAFIA' },
]

export default function FecharFaltas() {

    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [cursos, setCursos] = useState([])
    const [turmas, setTurmas] = useState([])
    const [grades, setGrades] = useState([])
    const [dadosList, setDadosList] = useState([])
    const { register, handleSubmit, errors } = useForm()
    const [checkedItems, setCheckedItems] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])
    const [dadosFinaisRender, setDadosFinaisRender] = useState([])


    let checkedTurmas = []
    let cursosUnicos = []
    let dadosFinaisCard = []
    let finalReturn = []


    const baseUrl = ApiFilter(sub)
    let dadosCompletos = []

    const data = [
        {
            id: 'c-001',
            name: '1° FUNDAMENTAL',
            type: 'curso',
            children: [
                {
                    id: '1',
                    name: 'Turma A',
                },
                {
                    id: '3',
                    name: 'TURMA B',
                    children: [
                        {
                            id: '4',
                            name: 'PORTUGUÊS',
                        },
                    ],
                },
            ],
        }
    ];

    async function getCursos() {
        const resultCursos = await GetCursos(baseUrl)
        setCursos(resultCursos)
    }

    //CodCurso;NomeCurso;SistemaAv;Avaliacoes
    const filtroCursosAv = () => {
        if (checkedTurmas[0] !== undefined) {
            const cursosFiltro = checkedTurmas.map(item => item.codCurso)
            const cursosUnicosFiltro = [...new Set(cursosFiltro)]
            const dadosFinais = []

            cursosUnicosFiltro.map(async (codCurso, index) => {
                const cursoFind = cursos.filter(item => item.Codigo === codCurso)
                dadosFinais.push({ "Curso": cursoFind })

                const tipoAvCursoFind = cursoFind[0].Sistema
                const avaliacoesCursoFind = avaliacoes.filter(item => item.Sistema === tipoAvCursoFind)

                const avaliacoesNotasFind = [...avaliacoesCursoFind.filter(item => {
                    const itemNota = item.Codigo
                    if (itemNota.includes('N')) {
                        if (parseInt(itemNota.slice(1, 2)) <= 6) {
                            return item
                        }
                    }
                })]

                dadosFinais[index]['Avaliacoes'] = avaliacoesNotasFind
                dadosFinais[index]['Dados'] = checkedTurmas

                console.log('DadosFinais', dadosFinais)
            })

            // finalReturn = dadosFinais.map(item => item)
            finalReturn = dadosFinais.slice(0)
            
        }

        return finalReturn
    }

    console.log('CheckedTurmas', checkedTurmas)
    console.log('DadosFinaisRender', dadosFinaisRender)

    async function getAvaliacoes() {
        const resultAvaliacoes = await GetAvaliacoes(baseUrl)
        setAvaliacoes(resultAvaliacoes)
    }

    const onChangeDisciplinaCheck = async (e, t) => {
        // console.log('Event', e.target, JSON.parse(e.target.value))
        const value = await JSON.parse(e.target.value)
        const codCurso = value.id.slice(2, 5)
        const codDisciplina = value.id.slice(8, 11)
        const turma = value.turma.slice(6, 7)
        const checked = t
        const dadosPush = { codCurso: codCurso, codDisciplina: codDisciplina, turma: turma }

        //ADD ITEM
        if (checked) {
            // Verifica se a variavel tem registros
            if (checkedTurmas.length > 0) {
                // Verifica se ja existe registro com os codigos informados
                const itemExist = checkedTurmas.filter(
                    item => item.codCurso === codCurso &&
                        item.codDisciplina === codDisciplina &&
                        item.turma === turma)

                // Não existe item selecionado
                if (itemExist.length === 0) {
                    checkedTurmas.push(dadosPush)

                } else {
                    return
                }

            } else {
                //ADD 1° ITEM
                checkedTurmas.push(dadosPush)
            }
            //REMOVER ITEM
        } else {
            const itemExist = checkedTurmas.filter(
                item => item.codCurso === codCurso &&
                    item.codDisciplina === codDisciplina &&
                    item.turma === turma)

            // Não existe item selecionado
            if (itemExist.length > 0) {
                checkedTurmas = checkedTurmas.filter(
                    item => !(item.codCurso === codCurso &&
                        item.codDisciplina === codDisciplina &&
                        item.turma === turma)
                )

            } else {
                return
            }

        }

        const checkedTurmasNewArray = checkedTurmas.slice(0)
        const checkedCursosFiltro = checkedTurmasNewArray.map(item => item.codCurso)
        const checkedCursosUnicos = [...new Set(checkedCursosFiltro)]
        cursosUnicos = checkedCursosUnicos
        
        dadosFinaisCard = filtroCursosAv()
        const addItem = JSON.stringify(dadosFinaisCard.slice(0))
        sessionStorage.removeItem('grades')
        sessionStorage.setItem('grades', addItem)
        // console.log('dadosFinaisCard', dadosFinaisCard)
        // setDadosFinaisRender(dadosFinaisCard)
    }
    
    useEffect( () => {
        console.log('CheckedItems', checkedItems)
    }, [checkedItems])

    const renderTreeDisciplinas = (nodes, turma, checkedOption) => (

        <div id="renderTreeDisciplinas">
            <Checkbox
                className="renderTreeDisciplinasCheckbox"
                onChange={onChangeDisciplinaCheck}
                value={JSON.stringify({ id: nodes.id, value: nodes.name, turma: turma })} />
            <TreeItem sx={sxTitleStyleDisciplinas} defaultChecked={checkedOption ? checkedOption : false} key={nodes.id} nodeId={nodes.id} label={nodes.name} />
        </div>
    );

    const onChangeTurmasRenderTree = (e, t, nodes) => {
        const checked = t

        if (checked) {
            console.log('Checked')
            return (
                <div id="renderTreeTurmas">
                    <TreeItem sx={sxTitleStyleTurmas} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
                        {Array.isArray(nodes.children)
                            ? nodes.children.map((node) => renderTreeDisciplinas(node, nodes.name, false))
                            : null}
                    </TreeItem>
                </div>
            )
        } else {
            console.log('No')
        }
    }

    const renderTreeTurmas = (nodes) => (
        <div id="renderTreeTurmas" >

            {/* <Checkbox className="renderTreeTurmasCheckbox"
                value={nodes.id}
                onChange={(e, t) => onChangeTurmasRenderTree(e, t, nodes)} /> */}

            <TreeItem sx={sxTitleStyleTurmas} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTreeDisciplinas(node, nodes.name, false))
                    : null}
            </TreeItem>

        </div>
    );

    const onSubmit = async (dados) => {

    }

    async function getGradesInfo() {
        const gradesResult = await GetGrades(baseUrl)
        setGrades(gradesResult)
        return gradesResult
    }

    async function gerarDados(turmas) {
        let dadosObj = {}
        let disciplinasObj = []
        dadosCompletos = []

        // Pegar CodCursos apenas com MAP
        const TurmasMapCurso = turmas.map(item => item.Curso)
        // Pegar CursosUnicos com new Set.Retorna objeto de string
        const cursosUnicos = new Set(TurmasMapCurso)

        for (const codCurso of cursosUnicos) {
            const grades = await getGradesInfo()
            const gradeFiltro = grades.filter(item => item.CodCurso === codCurso)

            //Se o curso tiver tipo de avaliação informada ( 1° Fund ou + )
            if (gradeFiltro[0]?.TipoAvaliacao) {
                dadosObj = {}
                disciplinasObj = []
                const items = turmas.filter(item => item.Curso === codCurso)

                dadosObj['id'] = `c-${codCurso}`
                dadosObj['type'] = 'curso'
                dadosObj['name'] = items[0].Nome
                dadosObj['tipoAvaliacao'] = gradeFiltro[0]?.TipoAvaliacao

                // console.log('GradeFiltro', gradeFiltro)


                if (gradeFiltro.length > 0) {
                    for (const item of gradeFiltro) {
                        disciplinasObj.push({ id: `c-${codCurso}_d-${item.CodDisciplina}`, name: `${item.Disciplina}` })
                    }
                }

                const turmasUnicas = items.map(item => item.Turma)

                if (turmas.length > 0) {
                    let turmasObj = []

                    for (const turma of turmasUnicas) {
                        const turmaAdd = { id: `c-${codCurso}_t-${turma}`, name: `TURMA ${turma}` }
                        //SE EXISTIR DISCIPLINAS
                        if (disciplinasObj.length > 0) {
                            turmaAdd['children'] = disciplinasObj
                        }
                        turmasObj.push(turmaAdd)
                    }

                    dadosObj['children'] = turmasObj
                }

                dadosCompletos.push(dadosObj)
                setDadosList(dadosCompletos)
            }

        }
    }

    async function getTurmasInfo() {
        const turmasResult = await GetTurmas(baseUrl)
        gerarDados(turmasResult)
        setTurmas(turmasResult)
    }

    useEffect(() => {
        getTurmasInfo()
        getAvaliacoes()
        getCursos()
    }, [])

    const onClickAvancar = () => {
        // console.log('DadosFinaisCardClick', dadosFinaisCard)
        // setDadosFinaisRender(dadosFinaisCard)
    }

    return (
        <>
            <div className="FecharFaltasGerenciarAdminContainer">
                <SideBar />
                <div className="FecharFaltasPageRigthSide">
                    <Header />

                    <div id="FecharFaltasTitlePage">
                        Fechar Faltas
                    </div>

                    <div className="FecharFaltasBodyPainelContainer">
                        <h3>
                            FECHAR FALTAS
                        </h3>
                        <p>
                            Selecione as opções abaixo para serem confirmadas no fechamento
                        </p>

                        <div className='TreeViewFecharFaltasContainer'>
                            <TreeView
                                aria-label="multi-select"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                multiSelect
                                value="TreeView"
                                onChange={(e, t) => { }}
                                sx={sxMainTreeView}
                            >
                                {dadosList?.map((item, index) => {
                                    return (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                                            {/* <Checkbox style={{ marginTop: '-8px', marginRight: '-8px' }} /> */}
                                            <TreeItem key={item.id} nodeId={item.id} label={item.name}>
                                                {Array.isArray(item.children)
                                                    ? item.children.map((node) => renderTreeTurmas(node))
                                                    : null}
                                            </TreeItem>
                                        </div>
                                    )
                                })}

                            </TreeView>
                        </div>


                        <div className='FecharFaltasButtonsContainer'>
                            <ButtonModalFecharFaltas
                                onClick={onClickAvancar}
                                cursosUnicos={cursosUnicos}
                                dadosFinais={checkedItems}
                                ref={register}
                            />

                            <Link to="/Admin/Gerenciar" id="LinkButtonVoltarFecharNotas" >
                                <ButtonBack Title="Voltar" />
                            </Link>
                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}