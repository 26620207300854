import './PorAluno.css'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
//Components
import Header from '../../../components/header'
import { ScaleLoader } from 'react-spinners'
//Services/api
import GetDigitacaoProfessorQua from '../../../services/GetDigitacaoProfQua'
import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetAlunos from '../../../services/GetAlunos'
import GetGradesQua from '../../../services/GetGradesQua'
import PostInserirQualitativas from '../../../services/PostInserirQualitativas'
import PostUpdateQualitativas from '../../../services/PostUpdateQualitativas'
import GetObservacoesQualitativas from '../../../services/GetObservacoesQualitativas'
import GetNotasQualitativasPorChave from '../../../services/GetQualitativasPorChave'
import PostInserirObservacoes from '../../../services/PostInserirObservacoes'
import PostUpdateObservacoes from '../../../services/PostUpdateObservacoes'
//Utils
import { ApiFilter } from '../../../Functions/FilterApiDomain'
import { alunosType, avaliacaoType, digitacaoProfType, gradesQuaType, gradesQuaCursoType, cursosType, notasQuaConsultadasType } from './TypesAluno'
import { FiltrarCursosPermitidosProfQua } from '../../../Functions/FiltrarCursosPermitidosProfQua'
import { FiltrarTurmaProfQua } from '../../../Functions/FiltrarTurmaProfQua'
import { FiltrarAvaliacoes } from '../../../Functions/FiltrarAvaliacoes'
import { FiltrarAlunosTurma } from '../../../Functions/FiltrarAlunosTurma'
import { FiltroGradesQuaPorCurso } from '../../../Functions/FiltroGradesQuaPorCurso'



export default function QualitativasPorAluno() {

    const [digitacaoProfQua, setDigitacaoProfQua] = useState([digitacaoProfType])
    const [turmas, setTurmas] = useState([''])
    const [avaliacoes, setAvaliacoes] = useState([avaliacaoType])
    const [alunos, setAlunos] = useState([alunosType])
    const [gradesQua, setGradesQua] = useState([gradesQuaType])
    const [gradesQuaCurso, setGradesQuaCurso] = useState([gradesQuaCursoType])
    const [gradesQuaFiltroRender, setGradesQuaFiltroRender] = useState([gradesQuaCursoType])

    const [cursosPermitidos, setCursosPermitidos] = useState([cursosType])
    const [avaliacoesFiltro, setAvaliacoesFiltro] = useState([avaliacaoType])
    const [alunosPermitidos, setAlunosPermitidos] = useState([alunosType])

    const sessionForm = JSON.parse(sessionStorage.getItem('formQua'))
    const [valorCurso, setValorCurso] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorAvaliacao, setValorAvaliacao] = useState('')
    const [valorAluno, setValorAluno] = useState('')
    const [valorObservacoes, setValorObservacoes] = useState('')
    const [renderNotas, setRenderNotas] = useState(false)

    const [notasQuaConsultadas, setNotasQuaConsultadas] = useState([notasQuaConsultadasType])
    const [observacoesQua, setObservacoesQua] = useState([])

    const { register, handleSubmit } = useForm()
    const codProfessor = sessionStorage.getItem('professores')
    const sub = localStorage.getItem('target')
    const baseUrl = ApiFilter(sub)

    let count = 0

    const FiltroGradesQuaPorNivel = (curso_selecionado, criterio_avaliativo) => {
        const nivelFiltro = gradesQua.filter(item =>
            item.Curso === curso_selecionado &&
            item.Nivel === criterio_avaliativo &&
            !(item.Grupo === 0 && item.SubGrupo === 0))
        return nivelFiltro
    }

    const FilterRenderGradesQuaNivel = (criterio_nivel) => {
        const filtroNivel = FiltroGradesQuaPorNivel(valorCurso, criterio_nivel)
        return filtroNivel
    }

    const onShowQualitativasLevel = (val, key) => {
        const condicao = notasQuaConsultadas.filter(item => item.matricula === valorAluno && item.disciplina === val.Codigo).length !== 0

        if (condicao) {
            return (
                <input
                    type='text'
                    placeholder={notasQuaConsultadas?.filter(item => item.matricula === valorAluno && item.disciplina === val.Codigo)[0].conceito}
                    name={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    id={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    maxLength={4}
                    key={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    ref={register}
                    required />
            )

        } else {
            return (
                <input
                    type='text'
                    name={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    key={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    id={`N:${val.Nivel}|G:${val.Grupo}|SG:${val.SubGrupo}|d_${val.Codigo}`}
                    maxLength={4}
                    ref={register}
                    required />
            )
        }

    }



    const RetornarSessionSelect = () => {
        if(count === 0) {
            count++
            setValorCurso(sessionForm ? sessionForm.curso : '')
            setValorTurma(sessionForm ? sessionForm.turma : '')
            setValorAluno(sessionForm ? sessionForm.aluno : '')
            setValorAvaliacao(sessionForm ? sessionForm.avaliacao : '')
        }
    }

    const GetNotasQualitativas = async () => {
        const chave = valorAvaliacao + '1111' + valorCurso + valorTurma
        const notas = await GetNotasQualitativasPorChave(baseUrl, chave)
        return notas
    }

    const GetObservacoesQua = async () => {
        const chave = valorAvaliacao + '1111' + valorCurso + valorTurma
        const obsResult = await GetObservacoesQualitativas(baseUrl, chave, valorAluno)
        return obsResult
    }

    const onChangeCurso = ({ target }) => {
        setTurmas('')
        setTurmas(FiltrarTurmaProfQua(target.value, digitacaoProfQua))
        setAvaliacoesFiltro(FiltrarAvaliacoes(target.value, cursosPermitidos, avaliacoes))
        setValorCurso(target.value)
    }

    const onChangeTurma = ({ target }) => {
        const turmaAtual = target.value
        setValorAvaliacao('')
        setValorAluno('')
        setValorTurma(turmaAtual)
        if (turmaAtual !== '' && turmaAtual !== '4444') {
            setAlunosPermitidos([])
            setAlunosPermitidos(FiltrarAlunosTurma(alunos, valorCurso, turmaAtual))
            setGradesQuaCurso(FiltroGradesQuaPorCurso(valorCurso, gradesQua))
        }
    }

    const onChangeAvaliacao = ({ target }) => {
        setValorAluno('')
        setValorAvaliacao(target.value)
    }

    const onChangeAluno = async ({ target }) => {
        setValorAluno(target.value)
    }

    const RenderGradesQuaCurso = (gradesQuaFiltroRender) => {
        return (
            gradesQuaFiltroRender.map((val, key) => {
                return (
                    <div className='nivelGroupContainer' key={val + '_' + key}>
                        {
                            val.Dados == 1 || val.Dados == '1' ?
                                <>
                                    <div id="nivelDescricao" name={val.Descricao} ref={register}>
                                        <p> {`${val.Descricao}`} </p>
                                    </div>
                                    {onShowQualitativasLevel(val)}
                                </>
                                :
                                <div id="nivelTituloGrupo"> {`${val.Descricao}`}</div>
                        }
                        {
                            RenderGradesQuaNivel(val.Nivel)
                        }
                    </div>
                )
            })
        )
    }

    const RenderGradesQuaNivel = (criterio_nivel) => {
        const gradesQuaNivel = FilterRenderGradesQuaNivel(criterio_nivel)
        return (
            <div className='nivelContainer'>
                {
                    gradesQuaNivel.map((val, key) => {
                        return (
                            <div className='nivelRowContainer' key={val + '_' + key}>
                                {
                                    val.Dados == 1 || val.Dados == '1' ?
                                        <>
                                            <div id="nivelDescricao" name={val.Codigo} ref={register}>
                                                <p> {`${val.Descricao}`} </p>
                                            </div>
                                            {onShowQualitativasLevel(val)}
                                        </>
                                        :
                                        <div id="nivelTitulo"> {`${val.Descricao}`} </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const onChangeObservacoes = ({ target }) => {
        setValorObservacoes(target.value)
    }

    const onSubmit = async (dados) => {
        const sessionDados = { curso: valorCurso, turma: valorTurma, aluno: valorAluno, avaliacao: valorAvaliacao }

        if (window.confirm('Deseja salvar os dados informados?')) {
            if (valorCurso !== '' && valorTurma !== '' && valorAvaliacao !== '' && valorAluno !== '') {
                let resultadoQualitativasNovas = { Status: 200 }
                let resultadoQualitativasExistentes = { Status: 200 }
                const arrayDadosInsert = []

                for (const dado in dados) {
                    if (dado.includes('d_') && dados[dado].replaceAll(' ', '') !== '') {
                        const chave = dados.avaliacao + '1111' + dados.curso + dados.turma
                        const disciplina = dado.split('_')[1]
                        arrayDadosInsert.push([dados.aluno, chave, disciplina, dados[dado]])
                    }
                }

                let resultadoObservacoesNovas = 200   // Retorno para notificação
                let resultadoObservacoesExistentes = 200 // Retorno para notificação

                const chave = valorAvaliacao + '1111' + valorCurso + valorTurma
                const matricula = valorAluno
                //Inserir observacoes
                if (valorObservacoes !== '' && observacoesQua.length === 0) {
                    resultadoObservacoesNovas = await PostInserirObservacoes(baseUrl, chave, matricula, dados.observacoes)
                    //Atualizar observacoes
                } else if (valorObservacoes !== '' && observacoesQua.length > 0) {
                    resultadoObservacoesExistentes = await PostUpdateObservacoes(baseUrl, chave, matricula, dados.observacoes)
                }

                //Se existe notas a serem registradas
                if (arrayDadosInsert.length > 0) {
                    let notasNovas = []
                    let notasExistentes = []

                    for (const dado of arrayDadosInsert) {
                        const disciplinaDado = dado[2]
                        const matriculaDado = dado[0]
                        const find = notasQuaConsultadas.filter(item => item.disciplina === disciplinaDado && item.matricula === matriculaDado)

                        if (find.length > 0) {
                            notasExistentes.push(dado)
                        } else {
                            notasNovas.push(dado)
                        }
                    }

                    if (notasNovas.length > 0) {
                        const returnQualitativas = await PostInserirQualitativas(baseUrl, notasNovas)
                        resultadoQualitativasNovas = returnQualitativas
                    }

                    if (notasExistentes.length > 0) {
                        resultadoQualitativasExistentes = await PostUpdateQualitativas(baseUrl, notasExistentes)
                    }
                }

                if (resultadoQualitativasExistentes?.Status === 200 && resultadoQualitativasNovas?.Status === 200) {
                    window.alert('Dados registrados com sucesso')
                    sessionStorage.setItem('formQua', JSON.stringify(sessionDados))
                    window.location.reload()
                } else {
                    window.alert('Erro no registro de um ou mais dados. Favor consultar as informações selecionadas e checar a conexão de rede')
                    window.location.reload()
                }


            } else {
                window.alert('Preencha os campos obrigatorios')
            }
        }
    }

    useEffect(() => {
        Promise.all([
            GetDigitacaoProfessorQua(baseUrl, codProfessor),
            GetAvaliacoes(baseUrl),
            GetAlunos(baseUrl),
            GetGradesQua(baseUrl, codProfessor),
        ])
            .then((result) => {
                const [digitacaoProfessor, avaliacoes, alunos, gradesqua] = result
                setDigitacaoProfQua(digitacaoProfessor)
                setAvaliacoes(avaliacoes)
                setAlunos(alunos)
                setGradesQua(gradesqua)
            })

        
    }, [])

    const FiltrarDisciplinasPermitidas = () => {
        if (gradesQuaCurso[0].Codigo !== 0 && valorTurma !== "") {
            const permissaoDisciplinas = []

            for (const itemDigita of digitacaoProfQua) {
                const result = gradesQuaCurso.filter(item => item.Codigo === itemDigita.CodDisciplina)

                if (result.length > 0) {
                    permissaoDisciplinas.push(result[0])
                }
            }

            console.log('PermissaoDisciplinas: ', permissaoDisciplinas)
            return permissaoDisciplinas
        }
    }

    const PesquisarRegistrosExistentes = async () => {
        const notaQuaReturn = await GetNotasQualitativas()
        setNotasQuaConsultadas(notaQuaReturn)
        const obsQuaReturn = await GetObservacoesQua()
        setObservacoesQua(obsQuaReturn)
    }

    useEffect(() => {
        if (digitacaoProfQua[0]?.Codigo !== 0) {
            setCursosPermitidos(FiltrarCursosPermitidosProfQua(digitacaoProfQua, codProfessor))
        }
    }, [digitacaoProfQua])

    useEffect(() => {
        const observacoes = observacoesQua.length > 0 ? observacoesQua[0]?.observacao : ''
        setValorObservacoes(observacoes)
    }, [observacoesQua])

    useEffect(() => {
        setGradesQuaFiltroRender(FiltrarDisciplinasPermitidas())
    }, [gradesQuaCurso, valorTurma])

    useEffect(() => {
        setRenderNotas(false)
        if(valorAluno !== ''){
            setTimeout(() => {
                setRenderNotas(true)
                PesquisarRegistrosExistentes()
            }, 300)
        }
    }, [valorAluno])


    return (
        <div>
            <Header />
            <div id="qualitativasContainer">
                <section>
                    <div className="info-bar">
                        <div className="title">
                            <p className="title-page">Lançar Qualitativas por Aluno </p>
                            <p className="subtitle-page">Colégio {localStorage.getItem('escola').toUpperCase()}</p>
                        </div>
                        <div className="container-informativo">
                            <p className="informativo-title">Observações</p>
                            <p className="informativo-desc">Os campos Atividades de Aula e Atividades de Casa são opcionais, porém se preenchidos, serão disponibilizados para Pais e Alunos.</p>
                        </div>
                    </div>

                    <form action="" method="post">
                        <div className="container-form">

                            <label>Curso</label>
                            <select onChange={onChangeCurso} value={valorCurso} name="curso" id="curso" ref={register} required={true}>
                                <option value={9999} key='CursoBranco'></option>
                                {
                                    cursosPermitidos.map((val, key) => {
                                        return <option value={val.Codigo} key={key}>{val.Curso}</option>
                                    })
                                }
                            </select>

                            <label>Turma</label>
                            <select name="turma" id="turma" ref={register} value={valorTurma} required={true} onChange={onChangeTurma}>
                                <option value={4444} key='TurmaBranco'></option>
                                {
                                    turmas.map((val, key) => {
                                        return <option value={val} key={key}>{val}</option>
                                    })
                                }
                            </select>

                            <label>Avaliação</label>
                            <select name="avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} id="turma" ref={register} required={true}>
                                <option value='' key='TurmaBranco'></option>
                                {avaliacoesFiltro.map((val, key) => {
                                    return <option value={val.Codigo} key={key}>{val.Abreviatura}</option>
                                })}
                            </select>

                            <label>Aluno</label>
                            <select name="aluno" onChange={onChangeAluno} value={valorAluno} id="alunos" ref={register} required={true}>
                                <option value='' key='alunoEmBranco'></option>
                                {
                                    alunosPermitidos.map((val, key) => {
                                        return <option value={val.Matricula} key={key}>{val.Nome}</option>
                                    })
                                }
                            </select>

                            {
                                alunosPermitidos[0].Matricula !== "" && valorAluno !== "" && renderNotas !== false ?
                                    <div id='GradeConceitosContainer'>
                                        {RenderGradesQuaCurso(gradesQuaFiltroRender, valorTurma)}
                                    </div>
                                    : <></>
                            }

                            <label>Observações:</label>
                            <div id="conteudoContainer">
                                <textarea name="observacoes" id="observacoes"
                                    cols="30" rows="5" onChange={onChangeObservacoes} value={valorObservacoes} ref={register} />
                            </div>

                            <input type="submit"
                                name="button"
                                className={`button`}
                                id="confirmar"
                                value={`Lancar`}
                                onClick={handleSubmit(onSubmit)}
                                ref={register}
                                disabled={false}
                            />

                        </div>
                    </form>

                </section>
                <ScaleLoader color={"color"} loading={false} css={"override"} height={50} width={8} />
            </div>
        </div>
    )
}
