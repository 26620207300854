import Axios from 'axios'

export default async function PostConsultarDiario(
    baseUrl, 
    escola, 
    idProfessor, 
    dataAtualPDF, 
    nomeProfessor, 
    nomeDisciplina, 
    nomeCurso, 
    alunos, 
    curso, 
    disciplina, 
    turma, 
    dataInicial, 
    dataFinal, 
    paginaAtual, 
    paginasTotais, 
    empresa, 
    anoLetivo, 
    tipoCurso, 
    tipoDiario, 
    faltasAlunosPush){ 
        
    const formulario = { curso: curso, disciplina: disciplina, turma: turma, dataInicial: dataInicial, dataFinal: dataFinal }
    
    const diarioGerado = await Axios.post(`${baseUrl}/gerarDiarioFaltasCompletas`, {
        escola: escola,
        idProfessor: idProfessor,
        nomeProfessor: nomeProfessor,
        data: dataAtualPDF,
        nomeCurso: nomeCurso,
        nomeDisciplina: nomeDisciplina,
        formulario: formulario,
        alunos: alunos,
        alunosFaltas: faltasAlunosPush,
        paginaAtual: paginaAtual,
        paginasTotais: paginasTotais,
        empresa: empresa,
        anoLetivo: anoLetivo,
        tipoCurso: tipoCurso,
        tipoDiario: tipoDiario            
    })  

    return diarioGerado.data
}