import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import ProfessoresTabela from '../../../components/ProfessoresTabela/ProfessoresTabela'
import GetProfessores from '../../../services/GetProfessores'
import SelectAPI from '../../../Functions/SelectApi'
import './Professores.css'

export default function Professores() {
    const [professores, setProfessores] = useState([])
    const valorEscola = localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola'
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    const baseUrl = SelectAPI(valorEscola)

    const professoresTableFields = [
        "Codigo",
        "Funcionario",
        "Polivalente",
    ]

    const professoresTableTitles = [
        "Codigo",
        "Funcionario",
        "Polivalente",
        ""
    ]

    async function getProfessores() {
        setProfessores(await GetProfessores(baseUrl))
    }

    useEffect( ()=> {
        if(valorEscola !== 'escola'){
            getProfessores() 
        }
    }, [valorEscola])

    if(logado !== 'true'){
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='ProfessoresGeneralDashContainer'>
                    <SideBar />
                    <div className='ProfessoresDashContainer'>
                        <Header />
                        <div id="professoresTitlePage">
                            PROFESSORES
                        </div>
    
    
                        <ProfessoresTabela
                            bodyDataRows={professores}
                            bodyTableFields={professoresTableFields}
                            headerTableTitles={professoresTableTitles}
                            searchBarInputPlaceHolder="Professor"
                            filterBy='Funcionario'
                            searchBarShow={true}
                        />
                    </div>
                </div>
            </>
        )
    }
}