//RETURN BASICS INFO LIKE IF THE COMPANY IS ACTIVE AT THE CURRENTY TIME
import Axios from 'axios'

export default async function GetEmpresa(baseUrl){
    let result = []
    
    if(baseUrl !== ''){
        await Axios.get(`${baseUrl}/empresa`)
        .then( (response) => {
            result = response.data
        })
        .catch( (response) => {
            result = response.data
        })
    }
    
    return result
}