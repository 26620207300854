import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import GetAlunosFaltas from '../../services/GetAlunosFaltas'
import { ApiFilter } from '../../Functions/FilterApiDomain'
import Close from '@material-ui/icons/Close'
import './ButtonModalStudent.css'

export default function ButtonModalStudent({ Curso, Turma, Disciplina, Data }) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [alunosFaltas, setAlunosFaltas] = useState([])
    const curso = Curso
    const turma = Turma
    const disciplina = Disciplina
    const data = Data
    const baseUrl = ApiFilter(localStorage.getItem('target'))
    const escola = localStorage.getItem('escola')
    const tableFields = ["matricula", "Nome", "faltas"]

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    async function getAlunosFaltas() {
        const alunos = await GetAlunosFaltas(baseUrl, curso, turma, disciplina, data, escola)
        setAlunosFaltas(alunos)
    }

    useEffect(() => {
        getAlunosFaltas()
    }, [])

    return (
        <div id='ContainerModalStudent'>
            <button id='ButtonModalStudent' onClick={openModal}> ALUNOS </button>
            <Modal
                closeTimeoutMS={200}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Lista de estudantes'
                overlayClassName='modal-overlay'
                className='modal-content-student-falta'
                
            >
                <h2>Lista de Alunos</h2>
                <hr />
                <p>
                    Tabela detalhada sobre faltas registradas
                </p>
                <div id='tabelaAlunosModal'>
                    <div id='tabelaAlunosModalHeaderTitles'>
                        <div id='bodyItemAlunosModal'>MATRICULA</div>
                        <div id='bodyItemAlunosModal'>NOME</div>
                        <div id='bodyItemAlunosModal'>FALTAS</div>
                    </div >
                    <div id='tabelaAlunosModalBodyItems'>
                        {
                            alunosFaltas?.map((val, key) => {
                                return (
                                    <div key={key} val={val} id="tabelaAlunosModalRow" >
                                        {
                                            tableFields.map(item => (
                                                <div id='bodyItemAlunosModal'>{val[item]}</div>
                                            ))
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>

                <div id='ButtonModalStudentContainer'>
                    <button id='ButtonModalStudent' onClick={closeModal}>
                        <Close id="CloseIconModalStudent" />
                        FECHAR
                    </button>
                    <div id='AlunosQtdInfoStudentModal'>
                        ALUNOS: {alunosFaltas?.length}
                    </div>
                </div>
            </Modal>
        </div>
    )
}