import React, {useState, useEffect} from 'react'
import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import DiarioDados from '../../../components/DiarioDados/DiarioDados'
import SelectAPI from '../../../Functions/SelectApi'
import './DetalhesDiario.css'

export default function EditarDiario() {
    const [valorEscola, setValorEscola] = useState(localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola')
    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    
    const [baseUrl, setBaseUrl] = useState('')
    
    useEffect( ()=> {
        setBaseUrl(SelectAPI(valorEscola))
    }, [valorEscola])

    if(logado !== 'true'){
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='DetalhesDiarioGeneralContainer'>
                    <SideBar />
                    <div className='EditarDiarioContainer'>
                        <Header />
                        <div id="editarDiarioTitlePage">
                            DADOS DIÁRIO
                        </div>
                        <DiarioDados title={"DADOS DIÁRIO"} baseUrl={baseUrl}/>
                    </div>
                </div>
            </>
        )
    }
}