import './DiarioDados.css'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import Axios from 'axios'

import formatarData from '../../Functions/FormataData'
import DeletarConteudoDiario from '../../Functions/DeletarConteudoDiario'
import DeletarDadosDiario from '../../Functions/DeletarDadosDiario'

import GetAlunosFaltas from '../../services/GetAlunosFaltas'

import ButtonBack from '../ButtonBack/ButtonBack'
import ButtonModalStudent from '../ButtonModalStudent/ButtonModalStudent'
import ButtonDelete from './../ButtonDelete/ButtonDelete';
import ButtonConfirm from './../ButtonConfirm/ButtonConfirm';
import Close from '@material-ui/icons/Close'
import Warning from '@material-ui/icons/Warning'



export default function DiarioDados({ title, baseUrl }) {
    const [dadosDiario, setDadosDiario] = useState(JSON.parse(sessionStorage.getItem('d')))
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const isAdmin = localStorage.getItem('isAdmin')
    const escola = localStorage.getItem('escola')
    const isBlocked = dadosDiario.bloqueado ? 'SIM' : 'NÃO'

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    async function getAlunosFaltas() {
        const alunos = await GetAlunosFaltas(
            baseUrl,
            dadosDiario.curso_id,
            dadosDiario.turma_id,
            dadosDiario.disciplina_id,
            dadosDiario.data.slice(0, 10),
            escola)

        return alunos
    }

    const formatarDeletarDadosDiario = async () => {
        const dadosAlunos = await getAlunosFaltas()
        const dadosFormatados = {}

        for (const dado of dadosAlunos) {
            dadosFormatados[`${dado.matricula}`] = dado.faltas === 0 ? false : dado.faltas
        }

        dadosFormatados['curso'] = dadosDiario.curso_id
        dadosFormatados['disciplina'] = dadosDiario.disciplina_id === null ? '' : dadosDiario.disciplina_id
        dadosFormatados['turma'] = dadosDiario.turma_id
        dadosFormatados['data'] = dadosDiario.data.slice(0, 10)

        return dadosFormatados
    }

    const formatarDeletarConteudosDiario = () => {
        const dadosFormatados = {}

        dadosFormatados['curso'] = dadosDiario.curso_id
        dadosFormatados['disciplina'] = dadosDiario.disciplina_id
        dadosFormatados['turma'] = dadosDiario.turma_id
        dadosFormatados['data'] = dadosDiario.data.slice(0, 10)

        return dadosFormatados
    }

    const onDelete = async () => {
        if (window.confirm('Deseja realmente excluir essa aula?') === true) {
            //Deletar Dados Diario
            const dadosFormatadosDeletar = await formatarDeletarDadosDiario()
            const deleteDiarioResult = await DeletarDadosDiario(dadosFormatadosDeletar)

            //Se dados foram deletados com sucesso
            if (deleteDiarioResult === 200) {
                //Deletar Conteudos Diario
                const dadosFormatadosDeletarConteudo = formatarDeletarConteudosDiario()
                const deleteConteudoResult = await DeletarConteudoDiario(dadosFormatadosDeletarConteudo)

                if (deleteConteudoResult === 200) {
                    console.log('Deletado com sucesso')
                    window.location.replace(window.location.origin + '/Admin/Diario')
                } else {
                    console.log('Erro ao tentar deletar conteudos: ', deleteConteudoResult)
                }

            } else {
                console.log('Erro ao tentar deletar notas: ', deleteDiarioResult)
            }
        }
    }

    const onConfirmBlockStatus = async () => {
        const data = dadosDiario.data.slice(0, 10)

        await Axios.post(`${baseUrl}/updateStatusConteudoAppBlock`, {
            curso_id: dadosDiario.curso_id,
            disciplina_id: dadosDiario.disciplina_id,
            turma_id: dadosDiario.turma_id,
            data: data,
            status: !dadosDiario.bloqueado
        })
            .then((response) => {
                console.log('Atualização status feita com sucesso', response.data)
                window.location.replace('/Admin/Diario')
            })
            .catch((error) => {
                window.alert('Não possível atualizar status dessa aula')
                console.log('Erro ao tentar atualizar status', error)
            })
    }

    const onOpenBlockModal = async () => {
        openModal()
    }

    return (
        <div className="DiarioDadosAdmin">
            <div className='DiarioDadosContainer'>
                <div className='DiarioDadosDashBarraPesquisa'>
                    {title}
                </div>

                <div className="DiarioDadosBasicInfoContainer">
                    <div className='DiarioDadosDataContainer TextDescricao'>
                        DATA
                        <input disabled={true} value={formatarData(dadosDiario.data)} id="InputId" />
                    </div>
                    <div className='DiarioDadosCursoContainer TextDescricao'>
                        CURSO
                        <input disabled={true} value={dadosDiario.Curso} id="InputNome" />
                    </div>
                    <div className='DiarioDadosTurmaContainer TextDescricao' >
                        TURMA
                        <input disabled={true} value={dadosDiario.turma_id} id='InputStatus' />
                    </div>
                </div>

                <div className='DiarioDadosBasicInfoContainerRow2'>
                    <div className='DiarioDadosDisciplinaContainer TextDescricao'>
                        DISCIPLINA
                        <div className='DisciplinaInfoContainer' >
                            <input disabled={true} value={dadosDiario.Disciplina} id="InputDisciplina" />
                        </div>
                    </div>

                    <div className='DiarioDadosAulasContainer TextAulas'>
                        N° AULAS
                        <div className='AulasInfoContainer'>
                            <input disabled={true} value={dadosDiario.aulas} id="InputAulas" />
                        </div>
                    </div>

                    <div className='DiarioDadosBloquadoContainer TextAulas'>
                        BLOQUEADO
                        <div className='BloqueadoContainer'>
                            <input disabled={true} value={isBlocked} id="InputAulas" />
                        </div>
                    </div>
                </div>

                <div id="TextPermissoesDiarioDados">
                    <div id='DescricaoDiarioTitle'>
                        DETALHES
                    </div>
                </div>

                <div className='DescricaoDiarioContainer'>
                    <div className='DiarioDadosDisciplinaContainer TextDescricao'>
                        <div className='descricaoDiario'>
                            CONTEÚDOS
                        </div>
                        <textarea disabled={true} value={dadosDiario.descricao} id="ConteudosDiario" />
                    </div>
                    <div className='DiarioDadosDisciplinaContainer TextDescricao'>
                        <div className='descricaoDiario'>
                            ATIVIDADE AULA
                        </div>
                        <textarea disabled={true} value={dadosDiario.atividade_aula} id="AtividadeAulaDiario" />
                    </div>
                    <div className='DiarioDadosDisciplinaContainer TextDescricao'>
                        <div className='descricaoDiario'>
                            ATIVIDADE CASA
                        </div>
                        <textarea disabled={true} value={dadosDiario.atividade_casa} id="AtividadeCasaDiario" />
                    </div>
                </div>

                <div className='DiarioDadosButtonsContainer'>
                    <Link to='/admin/Diario' id="ButtonBack">
                        <ButtonBack Title="VOLTAR" />
                    </Link>

                    <ButtonModalStudent
                        id="ButtonModalStudent"
                        Curso={dadosDiario.curso_id}
                        Turma={dadosDiario.turma_id}
                        Disciplina={dadosDiario.disciplina_id === null ? '' : dadosDiario.disciplina_id}
                        Data={new Date(dadosDiario.data).toJSON().slice(0, 10)}
                    />

                    <div id="buttonBackDiarioDados" onClick={onOpenBlockModal} >
                        {
                            dadosDiario.bloqueado === 0 ?
                                <ButtonBack Title="BLOQUEAR" />
                                :
                                <ButtonConfirm Title="LIBERAR" />
                        }
                    </div>

                    <Modal
                        closeTimeoutMS={200}
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        contentLabel='Lista de estudantes'
                        overlayClassName='modal-overlay'
                        className='modal-content-block-button'
                    >
                        <h2>
                            <div id="TitleModalDiarioDados">
                                <Warning id="WarningIconDiarioDados" /> Confirmar opção
                            </div>
                        </h2>

                        <hr />

                        <p>
                            Deseja realmente alterar o status de lançamento dessa aula?
                        </p>

                        <div id='ButtonModalStudentContainer'>
                            <button id='ButtonModalStudent' onClick={closeModal}>
                                <Close id="CloseIconModalStudent" />
                                FECHAR
                            </button>
                            <div onClick={onConfirmBlockStatus}>
                                <ButtonConfirm Title="CONFIRMAR" />
                            </div>
                        </div>
                    </Modal>


                    <div id='ButtonDeleteContainerDiarioDados'>
                        {
                            isAdmin === '1' ?
                                <ButtonDelete Title={'EXCLUIR'} Action={onDelete} />
                                : <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}