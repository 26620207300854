import React, {createContext, useState, useContext} from 'react'

const UserForm = createContext();

export default function FormProvider( {children} ){
    const [form, setForm] = useState({})
    const [isLoged, setIsLoged] = useState(false)
    let baseUrl = window.location.origin

    function handleLogin(){

        setIsLoged(!isLoged)
        
        if(isLoged !== 'true'){
            //sessionStorage.clear()
            window.location.push(baseUrl)
        }

        return isLoged
    }

    return(
        <UserForm.Provider value={
            {
                form,
                setForm,
                isLoged,
                setIsLoged,
                handleLogin
            }
        }>
            {children}
        </UserForm.Provider>
    );
}

export function Usuario() {
    const context = useContext(UserForm);
    const {form, setForm} = context;
    const {isLoged, setIsLoged} = context;
    const {handleLogin} = context;
    
    return {form, setForm, isLoged, setIsLoged, handleLogin};
}