export default function FiltrarDesligados(alunosGeral, alunosDesligados, data){
    const alunos = [...alunosGeral]
    const desligados = [...alunosDesligados]
    const dataAtual = data !== '' ? new Date(data) : ''    
    let alunosFiltro = []

    for(let desligado of desligados){
        //Encontrar aluno
        let findAluno = alunos.filter(item => item.Matricula === desligado.Matricula)
        
        if(findAluno.length > 0){
            //Pegar data desligamento Aluno
            const dataDesligamentoAluno = new Date(desligado.Data)
                   
            //Comparar se data de desligamento for maior que data atual selecionada
            if(dataAtual !== '' && dataDesligamentoAluno < dataAtual){
                //Deletar Desligados
                const filtrados = [...alunos.filter(item => item.Matricula !== findAluno[0].Matricula)]
                alunosFiltro = [...filtrados]
            } 
        }
    }
    return alunosFiltro.length > 0 ? alunosFiltro : alunos
}