const FilterApiDomain = (sub) => {
    let baseUrl = ''

    if (sub === 'api') { //INFODAT API
        // baseUrl = `http://localhost:3001`
        baseUrl = `https://nathansantos.com.br/Infodat`

    } else if (sub === 'profsamuel23') { //PROF SAMUEL
        baseUrl = 'https://nathansantos.com.br/ProfSamuel'

    } else if (sub === 'paraisoCultural') { //PARAISO CULTURAL
        baseUrl = 'https://app.nathansantos.com.br/paraisoCultural'

    } else if (sub === 'pequenoAprendiz') { //PEQUENO APRENDIZ
        baseUrl = 'https://app.nathansantos.com.br/pequenoAprendiz'

    } else if (sub === 'sanRafael') { //SAN RAFAEL
        baseUrl = 'https://app.nathansantos.com.br/sanRaphael'

    } else if (sub === 'barbaraLima') { //BARBARA LIMA
        baseUrl = 'https://app.nathansantos.com.br/barbaraLima'

    } else if (sub === 'aplicacao') { //APLICAÇÃO
        baseUrl = 'https://app.nathansantos.com.br/aplicacao'

    } else if (sub === 'Expressivo') { //EXPRESSIVO
        baseUrl = `https://nathansantos.com.br/expressivo`

    } else if (sub === 'Elite') { //ELITE
        baseUrl = `https://nathansantos.com.br/Elite`

    } else if (sub === 'Babylandia') {
        baseUrl = `https://app.nathansantos.com.br/babylandia`

    } else if (sub === 'Espirito Santo') {
        baseUrl = `https://nathansantos.com.br/Espiritosanto`

    } else if (sub === 'El Shadday') {
        baseUrl = `https://nathansantos.com.br/ElShadday`

    } else if (sub === 'Magnus') {
        baseUrl = `https://nathansantos.com.br/Magnus`

    } else if (sub === 'SaoLucas') {
        baseUrl = `https://nathansantos.com.br/SaoLucas`

    } else if (sub === 'Elic') {
        baseUrl = `https://app.nathansantos.com.br/elic`

    } else if (sub === 'Santa Chiara') {
        baseUrl = `https://app.nathansantos.com.br/santa_Chiara`

    } else if (sub === 'Magica do Saber') {
        baseUrl = `https://nathansantos.com.br/MagicaDoSaber`

    } else if (sub === 'Atlantico') {
        baseUrl = `https://app.nathansantos.com.br/atlantico`

    } else if (sub === 'Inca') {
        baseUrl = `https://app.nathansantos.com.br/Inca`

    } else if (sub === 'EscolaMoroni') {
        baseUrl = 'https://diarioweb-infodat.com.br/EscolaMoroni'

    } else if (sub === 'MagicaDoSaber23') {
        baseUrl = 'https://diarioweb-infodat.com.br/magicadosaber23'

    } else if (sub === 'SaoJose') {
        baseUrl = 'https://diarioweb-infodat.com.br/SaoJose'

    } else {
        baseUrl = `https://${sub}.nathansantos.com.br`
    }

    return baseUrl
}

module.exports = {
    ApiFilter: FilterApiDomain
}