import '../Types/TypesServices'
import Axios from 'axios'


/** @returns {[GetQualitativasByChaveReturn]} */

export default async function GetQualitativasByChave(BaseUrl, chave){
    const qualitativas = await Axios.post(`${BaseUrl}/notas/getQualitativasByChave`, {
        chave: chave
    })
    return qualitativas.data
}